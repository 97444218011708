import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Grommet
// import { Download, FormEdit } from 'grommet-icons'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
// import DataTable from '../../components/simple/dataTable/DataTable.js'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider.js'
import Grid from '../../components/simple/grid/Grid'
import List from '../../components/simple/list/List.js'
import Text from '../../components/simple/text/Text'

// App widgets
import SimpleCounter from '../../components/compound/simpleCounter/SimpleCounter'

// Services
// import FormService from '../../services/FormService'
// import LocalStorageService from '../../services/LocalStorageService'

import { Bar, Doughnut, Line, Polar } from 'react-chartjs-2'

const InvolvementReport = (props) => {
  const history = useHistory()

  const [numberOfStaffInvolvedPerIncident, setNumberOfStaffInvolvedPerIncident] = useState(0)

  const [totalNumberOfIncidentsByDate, setTotalNumberOfIncidentsByDate] = useState([])

  const [averageNumberOfStaffPerIncidentByHour, setAverageNumberOfStaffPerIncidentByHour] = useState([])

  const [averageNumberOfStaffPerIncidentByLocation, setAverageNumberOfStaffPerIncidentByLocation] = useState([])

  // Location
  const data = {
    labels: averageNumberOfStaffPerIncidentByLocation[1],
    datasets: [{
      // label: '# of Votes',
      data: averageNumberOfStaffPerIncidentByLocation[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Location
  const staffByLocation = {
    labels: averageNumberOfStaffPerIncidentByLocation[1],
    datasets: [{
      // label: '# of Votes',
      data: averageNumberOfStaffPerIncidentByLocation[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by Date
  const incidentsByDate = {
    labels: totalNumberOfIncidentsByDate[1], // ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
    datasets: [{
      // label: '# of Incidents',
      data: totalNumberOfIncidentsByDate[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by hour
  const incidentsByHour = {
    labels: averageNumberOfStaffPerIncidentByHour[1], // ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16'],
    datasets: [{
      // label: '# of Incidents',
      data: averageNumberOfStaffPerIncidentByHour[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }]
  }

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets

    // Staff per incident
    if (props?.location?.state?.numberOfStaffInvolvedPerIncident !== null) {
      const numberOfStaffInvolvedPerIncident = props?.location?.state?.numberOfStaffInvolvedPerIncident

      setNumberOfStaffInvolvedPerIncident(numberOfStaffInvolvedPerIncident.numStaff || 0)
    }

    // Staff

    // Other Children

    // Date
    if (props?.location?.state?.totalNumberOfIncidentsByDate !== null) {
      const totalNumberOfIncidentsByDate = props?.location?.state?.totalNumberOfIncidentsByDate

      const totalNumberOfIncidentsByDateLabels = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.month
      })

      const totalNumberOfIncidentsByDateData = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByDate([totalNumberOfIncidentsByDateData, totalNumberOfIncidentsByDateLabels])
    }

    // Time
    if (props?.location?.state?.totalNumberOfIncidentsByHour !== null) {
      const averageNumberOfStaffPerIncidentByHour = props?.location?.state?.averageNumberOfStaffPerIncidentByHour

      const averageNumberOfStaffPerIncidentByHourLabels = []
      const averageNumberOfStaffPerIncidentByHourData = []

      for (const x in averageNumberOfStaffPerIncidentByHour) {
        averageNumberOfStaffPerIncidentByHourLabels.push(x)
        averageNumberOfStaffPerIncidentByHourData.push(averageNumberOfStaffPerIncidentByHour[x].totalStaff)
        console.log(averageNumberOfStaffPerIncidentByHour[x].totalStaff)
      }
      // const averageNumberOfStaffPerIncidentByHourLabels = averageNumberOfStaffPerIncidentByHour.map((data, index) => {
      //   return data.hour
      // })

      // const averageNumberOfStaffPerIncidentByHourData = averageNumberOfStaffPerIncidentByHour.map((data, index) => {
      //   return data.count
      // })

      setAverageNumberOfStaffPerIncidentByHour([averageNumberOfStaffPerIncidentByHourData, averageNumberOfStaffPerIncidentByHourLabels])
    }

    // Location
    if (props?.location?.state?.averageNumberOfStaffPerIncidentByLocation !== null) {
      const averageNumberOfStaffPerIncidentByLocation = props?.location?.state?.averageNumberOfStaffPerIncidentByLocation

      const averageNumberOfStaffPerIncidentByLocationLabels = []
      const averageNumberOfStaffPerIncidentByLocationData = []

      for (const x in averageNumberOfStaffPerIncidentByLocation) {
        averageNumberOfStaffPerIncidentByLocationLabels.push(x)
        averageNumberOfStaffPerIncidentByLocationData.push(averageNumberOfStaffPerIncidentByLocation[x].averageStaff)
      }

      setAverageNumberOfStaffPerIncidentByLocation([averageNumberOfStaffPerIncidentByLocationData, averageNumberOfStaffPerIncidentByLocationLabels])
    }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Involvement Report</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>
            <Text size='xxlarge' weight={400} margin={{ top: 'none' }}>Search Criteria</Text>
            <Text>-Coming Soon-</Text>
          </Box>
        </Box>
      </Box>

      <Box direction='column' gap='small' round='small'>

        <Grid
          rows={['auto', 'auto', 'auto']}
          columns={['auto', 'auto', 'auto']}
          gap='medium'
          areas={[
            { name: 'main', start: [0, 0], end: [1, 1] }
          ]}
        >
          <SimpleCounter count={String(numberOfStaffInvolvedPerIncident)} label='Average number of staff involved in incident' />
          {/* <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text alignSelf='center' color='brand' size='128px' weight='bold'>5</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Average number of staff involved in incident</Text>
          </Box> */}

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Staff/Witnesses</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {/* <Doughnut
              data={data}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            /> */}
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Other Children</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {/* <Doughnut
              data={data}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            /> */}
          </Box>
        </Grid>

        <Box
          background='white'
          direction='column'
          pad='small'
          round='small'
        >
          <Text size='medium' weight='bold'>Date</Text>
          <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

          <Bar
            data={incidentsByDate}
            height={25}
            legend={{ display: false }}
            // options={{ maintainAspectRatio: false }}
            width={50}
          />
        </Box>

        <Box
          background='white'
          direction='column'
          pad='small'
          round='small'
        >
          <Text size='medium' weight='bold'>Time</Text>
          <Text margin={{ bottom: 'small' }} size='small'>Average number of Incidents</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

          <Line
            data={incidentsByHour}
            height={25}
            legend={{ display: false }}
            // options={{ maintainAspectRatio: false }}
            width={50}
          />
        </Box>

        <Grid
          rows={['auto']}
          columns={['auto']}
          gap='medium'
          // areas={[
          //   { name: 'main', start: [0, 0], end: [1, 1] }
          // ]}
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Location</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Average # of staff</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Polar
              data={staffByLocation}
              height={25}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

        </Grid>
      </Box>
    </Box>
  )
}

export default InvolvementReport
