import React, { useEffect, useState } from 'react'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Select from '../../../components/simple/input/Select'
import Text from '../../../components/simple/text/Text'

// Services
import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'

const FieldBox = (props) => {
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [submissions, setSubmissions] = useState([])
  const [responses, setResponses] = useState([])

  const searchFields = [
    {
      key: 'location',
      label: 'Location',
      type: 'autoComplete'
    },
    {
      key: 'studentName',
      label: 'Student Name',
      type: 'autoComplete'
    },
    {
      key: 'reference',
      label: 'Learner Number',
      type: 'autoComplete'
    },
    {
      key: 'age',
      label: 'Age',
      type: 'integer'
    },
    {
      key: 'gender',
      label: 'Gender',
      type: 'lookup',
      lookup: 'gender'
    },
    {
      key: 'ethnicity',
      label: 'Ethnicity',
      type: 'lookup',
      lookup: 'ethnicity'
    },
    {
      key: 'yearGroup',
      label: 'Year Group',
      type: 'list'
    },
    {
      key: 'studentGroup',
      label: 'Student Group',
      type: 'list'
    }
  ]

  // useEffect(() => {
  //   console.log(props)
  // }, [])

  return (
    <>
      <Select
        {...props}
        labelKey='label'
        onChange={({ value: nextValue }) => {
          props.handleFieldChanged(props.name, nextValue)
        }}
        options={searchFields}
        valueKey={{ key: 'key' }}
      />
    </>
  )
}

export default FieldBox
