// React
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

// Appt
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box.js'
import Button from '../../components/simple/button/Button.js'
import CheckBox from '../../components/simple/input/CheckBox.js'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Text from '../../components/simple/text/Text.js'
import TextInput from '../../components/simple/input/TextInput.js'
import Image from '../../components/simple/image/Image.js'

// Services
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

import queryStringHandler from '../../utils/queryStringHandler'

/**
 * `UserActivate` renders a User activation screen
 */
function UserActivate (props) {
  const history = useHistory()
  const location = useLocation()
  const queryStringVal = queryStringHandler(location.search)

  const inviteToken = queryStringVal.token

  const [activateValues, setActivateValues] = useState({
    firstname: '',
    lastname: '',
    password: '',
    type: 'new',
    tandc: false
  })

  const onChange = (nextValue) => {
    setActivateValues(nextValue)
  }

  const onSubmit = async () => {
    if (!activateValues.tandc) {
      NotificationService.error('You must accept the terms and conditions to continue.')
    } else {
      var activateStatus = await UserService.acceptInvite(inviteToken, activateValues)

      // stop if the activation failed
      if (activateStatus?.error) {
        NotificationService.error(activateStatus.error)
      } else {
        NotificationService.success('Your account has been activated. Please login to continue.')
        history.push('/login')
      }
    }
  }

  const goTandC = () => {
    alert('Go to T&Cs')
  }

  const onTandCChange = () => {
    return null
  }

  const termsUrl = 'https://www.behavioursmart.co.uk/terms-conditions'
  const privacyUrl = 'https://www.behavioursmart.co.uk/privacy-policy'


  return (
    <Box align='center' height='100%' justify='center'>
      <Box width='small' alignSelf='center' height='xsmall' margin={{ bottom: 'none' }}>
        <Image fit='contain' src={'/besmart-logo-white.png?r=001'} />
      </Box>
      <Box align='center' animation='fadeIn' pad='large' alignSelf='center' width='medium'>
        <Box>
          <Text color='white' margin={{ top: 'none', bottom: 'xsmall' }} textAlign='start' weight='bold'>You have been invited to join the BehaviourSmart app.</Text>
        </Box>

        <Box>
          <Text color='white' margin={{ top: 'none', bottom: 'small' }} size='small' textAlign='start'>To accept the invitation and create your account, enter your name and a chosen password below.</Text>
        </Box>

        <Box width='medium' border={{ color: 'brand', side: 'top' }} margin={{ top: 'small' }} pad={{ top: 'small' }}>
          <Form
            value={activateValues}
            onChange={(nextValue) => onChange(nextValue)}
            onSubmit={() => onSubmit()}
          >
            <FormField color='white' label={<Text size='medium' color='white'>First Name</Text>} name='firstname' required>
              <TextInput name='firstname' type='text' />
            </FormField>

            <FormField color='white' label={<Text size='medium' color='white'>Last Name</Text>} name='lastname' required>
              <TextInput name='lastname' type='text' />
            </FormField>

            <FormField color='white' label={<Text size='medium' color='white'>Password</Text>} name='password' required>
              <TextInput name='password' type='password' />
            </FormField>

            <FormField name='tandc'>
              <CheckBox color='white' label={<Text color='white' size='small'>I accept the <Anchor color='white' onClick={() => window.open(termsUrl, '_blank')} size='small' label='Terms & Conditions' /> and <Anchor onClick={() => window.open(privacyUrl, '_blank')} size='small' color='white' label='Privacy Policy' /></Text>} name='tandc' onChange={onTandCChange} />
            </FormField>

            <Button fill='horizontal' label='Accept the invitation' primary type='submit' />
          </Form>
        </Box>
      </Box>
    </Box>
  )
}

UserActivate.propTypes = {
  /**
   * The function called when the back button has been selected
   */
  // back: PropTypes.func,
  /**
   * The function called when submitting the form
   */
  submit: PropTypes.func
}

export default UserActivate
