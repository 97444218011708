
// const hideValues = false

import ApiService from './ApiService'

class ReportService {
//   getSubmissions = async (apiToken, params, where) => {
//     var url = '/api/formsubmission'

  //     // var params = {
  //     //   fields: 'id,user,organisation,reference,type',
  //     //   limit: 1000
  //     // }

  //     if (where) {
  //       params.where = where
  //     }

  //     try {
  //       var submissions = await ApiService.get(apiToken, url, params)
  //     } catch (err) {
  //       console.log(err)
  //     }
  //     console.log('FormService:getSubmissions ', submissions)
  //     return submissions
  //   }

  getReport = async (apiToken, params, data) => {
    var url = '/api/report/generate'

    // var params = {}

    var body = {
      data: data
    }

    try {
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('ReportService:getReport ', response)

    return response
  }
}
export default new ReportService()
