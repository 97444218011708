
// const hideValues = false

import ApiService from './ApiService'

class RoleService {
  getRole = async (apiToken, roleId) => {
    var url = `/api/role/${roleId}`

    var params = {
      fields: 'id,title,permissions,createdAt',
      limit: 1
    }

    try {
      var role = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:getRole ', role)
    return role
  }

  getRoles = async (apiToken, where) => {
    var url = '/api/role'

    var params = {
      fields: 'id,title,permissions,createdAt',
      limit: 1000
    }

    if (where) {
      params.where = where
    }

    try {
      var roles = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:getRoles ', roles)
    return roles
  }

  addRole = async (apiToken, data) => {
    var url = '/api/role'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:addRole ', result)
    return result
  }

  updateRole = async (apiToken, data) => {
    const roleId = data.id
    var url = `/api/role/${roleId}`

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:updateRole ', result)
    return result
  }

  getRoleForUser = async (apiToken, userId) => {
    var url = `/api/user/${userId}/role`

    var params = {
      fields: 'id,title,organisation,permissions,createdAt'
    }

    try {
      var role = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:getRoleForUser ', role)
    return role
  }

}
export default new RoleService()
