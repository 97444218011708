import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationService from '../../services/OrganisationService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import OptionButtons from '../../components/compound/optionButtons/OptionButtons'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function OrganisationEdit () {
  const history = useHistory()

  const [organisationDetails, setOrganisationDetails] = useState({
    name: '',
    addressLineOne: '',
    postcode: '',
    active: '',
    city: '',
    notes: '',
    admin: '',
    label: '',
    existingOrgAdmin: false,
    hasSites: false,
    numAllowedUsers: undefined,
    numUsers: 0
  })

  const [userCount, setUserCount] = useState(0)

  // const { error, showError } = useErrorHandler(null)

  const params = useParams()

  const organisationId = params.organisationid

  const apiToken = LocalStorageService.get('apiToken')

  // Submit form values
  const submitForm = async (values) => {

    // if there wasn't an orgadmin when we started this, submit the new org admin
    const orgData = {
      name: values.name,
      addressLineOne: values.addressLineOne,
      postcode: values.postcode,
      active: values.active === 'Yes',
      city: values.city,
      numAllowedUsers: values.numAllowedUsers,
      notes: values.notes,
      label: values.label,
      hasSites: !!values.hasSites
      // admin: userAdded.id
    }

    if (organisationId === 'new') {
      const organisationAdded = await OrganisationService.addOrganisation(apiToken, orgData)
      if (organisationAdded.error) {
        NotificationService.error('Error adding the organisation, please try again - ' + organisationAdded.error)
      } else {
        NotificationService.info('Organisation added')
        console.log('Organisation created ', organisationAdded)

        if (organisationAdded?.data?.[0]?.id) {
          history.push(`/service/${organisationAdded?.data?.[0]?.id}/edit`)
        }
      }
    } else {
      const organisationUpdated = await OrganisationService.updateOrganisation(apiToken, orgData, organisationId)
      if (organisationUpdated.error) {
        NotificationService.error('Error updating the organisation, please try again - ' + organisationUpdated.error)
      } else {
        NotificationService.info('Organisation updated')
        console.log('Organisation updated ', organisationUpdated)
      }

      if (values.orgAdmin) {
        const userAdded = await OrganisationService.setOrganisationAdmin(apiToken, organisationId, {
          email: values.orgAdmin
        })
        if (userAdded.error) {
          NotificationService.error('Error adding the Organisation Admin, please try again')
        }
        // reload the screen after setting an orgadmin
        window.location.reload()
      }
    }
  }

  const goBack = () => {
    history.push(`/services`)
  }

  useEffect(() => {
    let unmounted = false

    // Get organisation details
    const getOrganisationDetails = async (organisationId) => {


      const params = {
        fields: 'id,name,addressLineOne,admin,postcode,active,notes,city,numAllowedUsers,label'
      }
      const organisationDetails = await OrganisationService.getOrganisation(apiToken, params, organisationId)

      if (organisationDetails?.error) {
        NotificationService.error(organisationDetails.error)
      } else {
        if (!unmounted) {
          const data = organisationDetails.data[0]
          data.hasSites = !!data.hasSites

          data.active = data.active ? 'Yes' : 'No'

          if (data.admin) {
            data.existingOrgAdmin = true
          }

          setOrganisationDetails(data)
          console.log(data)
        }
      }
    }

    if (organisationId !== 'new') {
      getOrganisationDetails(organisationId)
    }

    return () => { unmounted = true }
  }, [])

  const getUserCount = () => {
    (async () => {
      const params = {
        fields: 'id',
        orgId: organisationId
      }

      const userCount = await OrganisationService.getUserCount(apiToken, params, organisationId)
      setUserCount(userCount?.systemUsers?.length || 0)
    })()
  }

  useEffect(() => {
    let unmounted = true

    if (unmounted) {
      getUserCount()
    }

    return () => { unmounted = false }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>System Organisations: {organisationDetails.id ? organisationDetails.name : 'New Organisation'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Behaviour Smart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              //   validate='blur'
              onChange={nextValue => {
                // console.log(nextValue)
                setOrganisationDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={organisationDetails}
            >
              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['auto', 'auto'],
                  large: ['auto', 'auto'],
                  xlarge: ['auto', 'auto']
                }}
                gap='small'
              >
                <Box direction='column'>
                  <Text>Name</Text>

                  <FormField
                    direction='column'
                    label='Organisation Name'
                    name='name'
                    required
                  >
                    <TextInput
                      name='name'
                      type='text'
                    />
                  </FormField>

                  <Box direction='column'>
                    <Text>Address</Text>

                    <Box direction='row-responsive' gap='medium'>
                      <FormField
                        direction='column'
                        label='Address'
                        name='addressLineOne'
                        width='100%'
                      >
                        <TextInput
                          name='addressLineOne'
                          type='text'
                        />
                      </FormField>

                      <FormField
                        direction='column'
                        label='Postcode'
                        name='postcode'
                      >
                        <TextInput
                          name='postcode'
                          type='text'
                        />
                      </FormField>

                      <FormField
                        direction='column'
                        label='City'
                        name='city'
                      >
                        <TextInput
                          name='city'
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <FormField
                    direction='column'
                    label='Other Notes'
                    name='notes'
                  >
                    <TextArea
                      name='notes'
                      type='text'
                    />
                  </FormField>

                </Box>

                <Box direction='column'>
                  <Text>Organisation Settings</Text>
                  {organisationId !== 'new' && !organisationDetails.admin &&
                    <FormField
                      direction='column'
                      label='Primary User'
                      name='orgAdmin'
                    >
                      <TextInput
                        name='orgAdmin'
                        type='text'
                        placeholder='Email Address'
                      />
                    </FormField>}

                  <Box direction='row-responsive' gap='medium'>
                    {/* <FormField
                      direction='column'
                      label='# Users'
                    >
                      <Text size='large'>{userCount}</Text>
                    </FormField> */}

                    <Box direction='column'>
                      <FormField
                        direction='column'
                        label='User Limit'
                        name='numAllowedUsers'
                      >
                        <TextInput
                          name='numAllowedUsers'
                          type='text'
                        />
                      </FormField>

                      <Text size='xsmall'>Leave blank for no limit</Text>
                    </Box>
                  </Box>
                  <Box direction='row-responsive' gap='medium'>
                    <FormField
                      direction='column'
                      label='Active'
                      name='active'
                    >
                      <OptionButtons
                        id='active'
                        name='active'
                        // value='Yes'
                      />
                    </FormField>

                  </Box>

                  <Box direction='row-responsive' gap='medium'>
                    <FormField
                      direction='column'
                      label='Label'
                      name='label'
                    >
                      <OptionButtons
                        id='label'
                        name='label'
                        options={['Green', 'Yellow', 'Red']}
                      />
                    </FormField>

                  </Box>

                </Box>
              </ResponsiveGrid>

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OrganisationEdit
