import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Error handler
import useErrorHandler from '../../utils/useErrorHandler'
import FlashMessage from '../../utils/FlashMessage'
import { capitalize } from '../../utils/text'

// Services
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import OrganisationService from '../../services/OrganisationService'

// Grommet
import { DocumentPdf, DocumentText, Folder, Image, Video } from 'grommet-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import Text from '../../components/simple/text/Text'
import Grid from '../../components/simple/grid/Grid'
import H1 from '../../components/simple/heading/H1'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function Reports (props) {
  const history = useHistory()
  const { error, showError } = useErrorHandler(null)

  const [apiToken, setApiToken, deleteApiToken] = useStore()
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  // Basically componentDidMount
  // useEffect(() => {
  // }, [])

  // useEffect(() => {
  // }, [props.activeOrg])

  return (
    <>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Reports
      </BreadcrumbBar>

      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Reports </H1>
          {error &&
            <FlashMessage message={error} />}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box> */}

      <Box background='white' direction='column' gap='small' margin={{ bottom: 'small' }} round='small'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Text margin={{ top: 'xsmall' }} size='xlarge'>Available Reports</Text>
        </Box>
      </Box>

      <Grid columns={{ count: 3, size: 'auto' }}>
        <Box background='white' margin='xsmall' pad='small' round='small'>
          <Button onClick={() => { history.push('/incident/report/search') }} icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />} fill='horizontal' label='Global Incident Report' plain />
        </Box>
        <Box background='white' margin='xsmall' pad='small' round='small'>
          <Button disabled icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />} fill='horizontal' label='Global Behaviour Report' plain />
        </Box>
        <Box background='white' margin='xsmall' pad='small' round='small'>
          <Button onClick={() => { history.push('/intervention/report/search') }} icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />} fill='horizontal' label='Intervention Report' plain />
        </Box>
        <Box background='white' margin='xsmall' pad='small' round='small'>
          <Button onClick={() => { history.push('/involvement/report/search') }} icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />} fill='horizontal' label='Involvement Report' plain />
        </Box>
        <Box background='white' margin='xsmall' pad='small' round='small'>
          <Button onClick={() => { history.push('/medicalIntervention/report/search') }} icon={<FontAwesomeIcon icon={['fal', 'clipboard']} />} fill='horizontal' label='Medical Intervention Report' plain />
        </Box>
      </Grid>
    </>
  )
}

export default Reports
