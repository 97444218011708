import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'
import OrganisationTagService from '../../../services/OrganisationTagService'
import ReportService from '../../../services/ReportService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import FieldBox from '../shared/FieldBox.js'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import FormFieldRequired from '../../../components/simple/formField/FormFieldRequired.js'
import JoinBox from '../shared/JoinBox.js'
import OperatorBox from '../shared/OperatorBox.js'
import Text from '../../../components/simple/text/Text'
import Select from '../../../components/simple/input/Select'
import TextInput from '../../../components/simple/input/TextInput'

// utils
import { capitalize } from '../../../utils/text'

function ReportSearch (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  // Count of number of rows of queries
  const [criteriaIndex, setCriteriaIndex] = useState(0)

  // Array of field types for the currently
  // selected Field box
  const [fieldTypes, setFieldTypes] = useState([])

  // ???
  const [query, setQuery] = useState()

  // Add to Quick Search has been clicked
  // so request the name
  const [getQuickSearchName, displayQuickSearchName] = useState(false)

  // List of Quick Searches
  const [quickReportSearches, setQuickReportSearches] = useState([])

  // Selected Quick Search
  const [quickSearchSelected, setQuickSearchValue] = useState([])

  // Set the submission type for the prop passed in
  const [submissionType, setSubmissionType] = useState(props.submissionType)

  // Reports array for each report type
  const reportsByType = {
    incidentreport: [
      'totalNumberOfIncidentsByStudent',
      'totalNumberOfIncidentsByYearGroup',
      'totalNumberOfIncidentsByStudentGroup',
      'totalNumberOfIncidentsByDate',
      'totalNumberOfIncidentsByHour',
      'totalNumberOfIncidentsByLocation',
      'totalNumberOfIncidentsBySystemUser'
    ],
    involvementreport: [
      'numberOfStaffInvolvedPerIncident',
      'otherChildrenInvolvedInIncident',
      'averageNumberOfStaffPerIncidentByDate',
      'totalNumberOfIncidentsByDate',
      'averageNumberOfStaffPerIncidentByHour',
      'averageNumberOfStaffPerIncidentByLocation'
    ],
    medicalinterventionreport: [
      'numIncidentsInvolvingInjuryToChild',
      'numIncidentsInvolvingInjuryToStaff',
      'numIncidentsInvolvingInjuryToOthers',
      'numIncidentsInvolvingInjuryByStudentGroup',
      'numIncidentsInvolvingInjuryWithOtherChildren',
      'totalNumberOfIncidentsByDate',
      'totalNumberOfIncidentsByHour',
    ]
  }
  const goBack = () => {
    history.goBack()
  }

  // Save the Quick Search just to Local Storage for now
  const saveQuickSearch = async (values) => {
    const query = await buildQuery()

    const queryObject = {}

    queryObject.name = values.quickSearchName
    queryObject.query = query

    // let quickSearch = LocalStorageService.get('quickReportSearch')

    // if (!quickSearch) {
    //   quickSearch = []
    // }

    // quickSearch.push(queryObject)

    // LocalStorageService.set('quickReportSearch', quickSearch)

    const searchToSubmit = []

    searchToSubmit.push({
      organisation: activeOrg.id,
      key: 'quickSearch',
      type: `${submissionType}`,
      value: JSON.stringify(queryObject)
    })

    const params = {
      orgId: activeOrg.id
    }

    const tagSet = await OrganisationTagService.set(apiToken, params, searchToSubmit)

    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    } else {
      NotificationService.success('Quick Search has been saved')
    }
  }

  // If a different field has been selected then
  // set the field type of the field selected
  // so that the Operator dropdown can be
  // populated accordingly
  const handleFieldChanged = (fieldName, value) => {
    const fieldIndex = fieldName.substring(fieldName.indexOf('-') + 1)
    const oldFieldTypes = fieldTypes

    oldFieldTypes[fieldIndex] = value.type

    setFieldTypes(oldFieldTypes)
  }

  // One row of the query
  const criteriaRow = (index, addJoin) => {
    return (
      <>
        <Box direction='row-responsive' gap='medium'>
          <FormField
            direction='column'
            label='Field'
            name={`Field-${index}`}
          >
            <FieldBox
              handleFieldChanged={handleFieldChanged}
              name={`Field-${index}`}
            />
          </FormField>

          <FormField
            direction='column'
            label='Operator'
            name={`Operator-${index}`}
          >
            <OperatorBox
              name={`Operator-${index}`}
              dataType={fieldTypes[index]}
            />
          </FormField>

          <FormField
            direction='column'
            label='Value'
            name={`Value-${index}`}
          >
            <TextInput
              name={`Value-${index}`}
              type='text'
            />
          </FormField>
        </Box>

        {addJoin &&
          <Box direction='row-responsive' gap='medium'>
            <FormField
              direction='column'
              label='Join'
              name={`Join-${index}`}
            >
              <JoinBox
                name={`Join-${index}`}
              />
            </FormField>
          </Box>}
      </>

    )
  }

  // Build the whole form by adding one
  // query row for each join option added
  const buildCriteria = () => {
    const criteriaTable = []

    for (let i = 0; i <= criteriaIndex; i++) {
      criteriaTable.push(criteriaRow(i, (criteriaIndex > 0 && i !== criteriaIndex)))
    }

    return criteriaTable
  }

  // Add another row to the query
  const addCriteria = () => {
    setCriteriaIndex(criteriaIndex + 1)
  }

  // Using the entered values post the query to
  // get the relevant report information
  const buildQuery = () => {
    let endOfQuery = false
    let queryCounter = 0
    const queryArray = []

    // If no query return an empty array
    if (query !== undefined) {
      // Add each query row to an array
      while (!endOfQuery) {
        queryArray.push({
          key: query[`Field-${queryCounter}`].key,
          operator: query[`Operator-${queryCounter}`],
          response: query[`Value-${queryCounter}`],
          relation: query[`Join-${queryCounter}`] || null
        })
        queryCounter++
        var checkNextCriteria = `Field-${queryCounter}` in query

        if (!checkNextCriteria) {
          endOfQuery = true
        }
      }
    }

    return queryArray
  }

  // Check a valid query has been entered
  const validateQuery = () => {
    // Check a query has been entered
    if (query === undefined) {
      return true
    }

    // Now check each row has a Field, Operator and Value entered
    let endOfQuery = false
    let validQuery = true
    let queryCounter = 0

    while (!endOfQuery) {
      if (query[`Field-${queryCounter}`] === undefined || query[`Operator-${queryCounter}`] === undefined || query[`Value-${queryCounter}`] === undefined) {
        validQuery = false
        endOfQuery = true
      }

      const checkJoinCriteria = `Join-${queryCounter}` in query

      queryCounter++

      // Now test for the end of the query
      const checkNextFieldCriteria = `Field-${queryCounter}` in query
      const checkNextOperatorCriteria = `Operator-${queryCounter}` in query
      const checkNextValueCriteria = `Value-${queryCounter}` in query

      if (!checkNextFieldCriteria && !checkNextOperatorCriteria && !checkNextValueCriteria) {
        endOfQuery = true
      } else {
        if (!checkJoinCriteria) {
          validQuery = false
          endOfQuery = true
        }
      }
    }

    return validQuery
  }

  const buildReport = async () => {
    // Only build the report if a valid query has been entered
    if (validateQuery()) {
      const queryArray = await buildQuery()

      const results = getReport(queryArray)
      // console.log(results)
    } else {
      NotificationService.warning('Please complete the query before continuing')
    }
  }

  const getReport = async (queryArray) => {
    if (queryArray) {
      const body = {
        submissionType: 'incidentreport',
        query: queryArray,
        reports: reportsByType[submissionType]
        // TODO: Need different fields !
        // fields: 'id,user,organisation,submission,questionKey,questionText,response',
        // limit: 1000
      }

      const params = {
        orgId: activeOrg.id
      }

      const results = await ReportService.getReport(apiToken, params, body)
      // console.log(results)
      history.push({
        pathname: `/report/${submissionType}`,
        state: results
      })
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    const getQuickSearches = async () => {
      const params = {
        fields: 'type,key,value',
        limit: 100,
        orgId: activeOrg.id
      }

      const where = {
        organisation: activeOrg.id,
        type: `${submissionType}`,
        key: 'quickSearch'
      }

      const quickSearches = await OrganisationTagService.get(apiToken, params, where)

      if (!unmounted) {
        console.log(quickSearches)
        // setQuickReportSearches(quickSearches)
      }
    }

    getQuickSearches()

    return () => { unmounted = true }

    // const quickSearches = LocalStorageService.get('quickReportSearch')

    // setQuickReportSearches(quickSearches)
  }, [])

  return (
    <Box width='xlarge'>
      {/* Header */}
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>{capitalize(props.reportTitle)} Report</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {/* Quick Search */}
      {quickReportSearches &&
        <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge'>Quick Search</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              {/* <Select
                options={objectOptions}
                labelKey='name'
                valueKey={{ key: 'query', reduce: true }}
              /> */}
              <Select
                labelKey='name'
                name='quickSearch'
                onChange={({ value: nextValue }) => {
                  // console.log(quickReportSearches)
                  let selectedQuery = null
                  quickReportSearches.forEach((item) => {
                    if (item.name === nextValue) {
                      selectedQuery = item
                    }
                  })
                  // console.log('XXX', selectedQuery)
                  setQuickSearchValue(selectedQuery.query)
                }}
                options={quickReportSearches}
                // NOTE: Normally we'd use 'query' as the key here
                // but because that is an array it seems to mess
                // things up and when selected the item does not
                // display in the dropdown. So we'll use the 'name'
                // and search for the 'query' when selected
                valueKey={{ key: 'name', reduce: true }}
              />
              <Button
                disabled={!quickSearchSelected.length}
                label='View Results >'
                margin={{ horizontal: 'xsmall' }}
                onClick={() => getReport(quickSearchSelected)}
                target='_self'
              />
            </Box>
          </Box>
        </Box>}

      {/* Main */}
      <Box background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='small'>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'none', vertical: 'small' }}>
              <Text margin={{ top: 'xsmall' }} size='large'>Search Criteria</Text>
              <Box alignSelf='center' direction='row'>
                {!getQuickSearchName &&
                  <Button
                    onClick={() => {
                      if (validateQuery()) {
                        displayQuickSearchName(true)
                      }
                    }}
                    label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Add to Quick Search</Text>}
                    margin={{ horizontal: 'xsmall' }} target='_self'
                  />}
                {getQuickSearchName &&
                  <Form
                    onSubmit={({ value: nextValue }) => {
                      saveQuickSearch(nextValue)
                    }}
                  >
                    <FormFieldRequired
                      direction='row'
                      label='Quick Search Name'
                      name='quickSearchName'
                      required
                    >
                      <TextInput
                        name='quickSearchName'
                        type='text'
                      />
                    </FormFieldRequired>

                    <Button type='submit' label='Save' primary />
                  </Form>}
              </Box>
            </Box>

            <Form
              //   validate='blur'
              onChange={nextValue => {
                // console.log(nextValue)

                setQuery(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                buildReport(nextValue)
              }}
              // value={organisationDetails}
            >
              <Box direction='column' gap='xsmall' margin={{ bottom: 'small' }}>
                {buildCriteria()}
                {/* <Button icon={<AddCircle size='medium' />} label='Add Criteria' color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} /> */}
                <Button label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add Criteria</Text>} color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

              {/* <Box direction='column' gap='xsmall' margin={{ top: 'small' }}>
                {queryResults?.length &&
                  <ReportSearchResults
                    results={queryResults}
                  />}
                {!queryResults?.length && <Text color='#666'>- No results to display -</Text>}
              </Box> */}

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Get Report' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ReportSearch
