import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LocalStorageService from '../services/LocalStorageService'
import ContentService from '../services/ContentService'

// Grommet
// import { Add, FormEdit, FormTrash, License, Upload } from 'grommet-icons'

// Appt Components
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import Form from '../components/simple/form/Form.js'
import FormField from '../components/simple/formField/FormField.js'
import H2 from '../components/simple/heading/H2'
import Select from '../components/simple/input/Select'
import Text from '../components/simple/text/Text'
import TextInput from '../components/simple/input/TextInput'

function ContentEdit () {
  const [contentDetails, setContentDetails] = useState({})

  const params = useParams()

  const contentId = params.contentid

  const types = ['Article', 'Audio', 'Category', 'Image', 'PDF', 'Video']

  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])

  const getContentDetails = async (contentId) => {
    const apiToken = LocalStorageService.get('apiToken')

    const contentDetails = await ContentService.getContent(apiToken, contentId)

    setContentDetails(contentDetails.data[0])
    // console.log(userDetails.data)
  }

  // Submit form values
  const submitForm = async (values) => {
    const apiToken = LocalStorageService.get('apiToken')

    console.log('submitForm ', values)

    if (contentId === 'new') {
      var contentAdded = await ContentService.addContent(apiToken, values)
      console.log('User created ', contentAdded)
    } else {
      var contentUpdated = await ContentService.updateContent(apiToken, values)
      console.log('Content edited ', contentUpdated)
    }
  }

  //   const contentIcon = {
  //     audio: 'music',
  //     article: 'newspaper',
  //     video: 'video',
  //     image: 'file',
  //     pdf: 'file-pdf',
  //     category: 'folder-open'
  //   }

  //   const contentIconColor = {
  //     audio: '#e3c754',
  //     article: '#4eaad3',
  //     video: '#cb6588',
  //     image: 'red',
  //     pdf: 'green',
  //     category: '#c94752'
  //   }

  // NOTE: getUser and clearSession currently need to be called on every screen
  // this is definitely a violation of DRY and needs to be refactor

  // check if the user is logged in, if so restored the session
  const getUser = () => {
    // check local storage
    var apiToken = LocalStorageService.get('apiToken')
    if (apiToken) {
      setApiToken(apiToken)
    } else {
      clearSession()
    }
  }

  // clear the user's session if they log out
  const clearSession = () => {
    // remove all localstorage
    LocalStorageService.clear()

    // remove the session data
    deleteApiToken()
    deleteCurrentUser()
    deleteCurrentOrgs()
    history.push('/')
  }

  // Basically componentDidMount
  useEffect(() => {
    getUser()

    if (contentId !== 'new') {
      getContentDetails(contentId)
    }
  }, [])

  return (
    <Box background='white' direction='column' gap='small' round='small'>
      {/* <Box>{apiToken || 'no global Token'}</Box>

      <Button plain onClick={() => { clearSession() }}>
        <Text>Logout</Text>
      </Button> */}

      <Box direction='row-responsive' pad={{ horizontal: 'large', bottom: 'small' }}>
        <H2>Content</H2>
        {/* Warning message goes here */}
      </Box>

      <Box
        border={{
          color: 'border',
          size: 'small',
          style: 'solid'
        }}
        gap='small'
        margin='small'
        pad='small'
        round='small'
      >
        <Box
          border={{
            color: 'border',
            size: 'small',
            style: 'solid',
            side: 'bottom'
          }}
          direction='row-responsive'
          gap='medium'
          margin={{ right: 'large' }}
        >
          <Text color='#000' size='large' weight='bold'>Content Type</Text>
        </Box>

        <Box direction='row-responsive' gap='medium' pad={{ right: 'large' }}>
          <Form
          //   validate='blur'
            onChange={nextValue => {
              // console.log(nextValue)
              setContentDetails(nextValue)
            }}
            onSubmit={({ value: nextValue }) => {
              // console.log(nextValue)
              submitForm(nextValue)
            }}
            value={contentDetails}
          >
            <FormField
              direction='row'
              label='Type'
              name='type'
              required
            >
              <Select
                id='type'
                name='type'
                placeholder='Type'
                //   value={value}
                options={types}
              //   onChange={({ option }) => setRole(option)}
              />
            </FormField>

            <FormField
              direction='row'
              label='Title'
              name='title'
              required
            >
              <TextInput
                name='title'
                type='title'
                // value={userDetails.data ? userDetails.data[0].email : ''}
              />
            </FormField>

            {/* {userId !== 'new' &&
              <>
                <FormField
                  direction='row'
                  label='Firstname'
                  name='firstName'
                  required
                >
                  <TextInput
                    name='firstName'
                    type='text'
                    // value={userDetails.data ? userDetails.data[0].firstName : ''}
                  />
                </FormField>

                <FormField
                  direction='row'
                  label='Lastname'
                  name='lastName'
                  required
                >
                  <TextInput
                    name='lastName'
                    type='text'
                    // value={userDetails.data ? userDetails.data[0].lastName : ''}
                  />
                </FormField>
              </>} */}

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              {/* <Button label='Cancel' />
              <Button type='reset' label='Reset' /> */}
              <Button type='submit' label='Save' primary />
            </Box>
          </Form>
        </Box>
      </Box>
    </Box>
  )
}

export default ContentEdit
