// Format of bulk upload csv file
// for Student (ie. Service User) and standard User
export const fileTypes = {
  student: {
    id: 'student',
    name: 'Student',
    description: 'A CSV of student users to be imported',
    type: 'csv',
    requiredFields: [
      'firstName',
      'lastName',
      'ethnicity',
      'gender',
      'dob'
    ],
    optionalFields: [
      'email'
    ]
  },
  user: {
    id: 'user',
    name: 'User',
    description: 'A CSV of standard users to be imported',
    type: 'csv',
    requiredFields: [
      'username',
      'firstName',
      'lastName',
      'email',
      'school',
      'role'
    ],
    optionalFields: [
      'jobTitle'
    ]
  }
}
