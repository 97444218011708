import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Error handler
// import useErrorHandler from '../utils/useErrorHandler'
// import FlashMessage from '../utils/FlashMessage'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'
import RoleService from '../services/RoleService'

// Grommet
import { Add, FormEdit, FormTrash } from 'grommet-icons'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import DataTable from '../components/simple/dataTable/DataTable.js'
import Text from '../components/simple/text/Text'

// Appt Compound Components
import NotificationLayer from '../components/compound/notification/NotificationLayer'

function RoleList (props) {
  const [roleValues, setRoleValues] = useState([])
  const [showDeleteOptions, showDelete] = useState(false)
  const [roleToDelete, setRoleToDelete] = useState(null)
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  // const { error, showError } = useErrorHandler(null)

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  // Prompt user to delete
  const showDeleteRoleOptions = (role) => {
    setRoleToDelete(role)
    showDelete(true)
  }

  // Delete a Role
  const deleteRole = async () => {
    // Delete from server
    const response = await RoleService.deleteUser(apiToken, roleToDelete)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newRoleValues = roleValues.filter((item) => item.id !== roleToDelete.id)
      setRoleValues(newRoleValues)
      NotificationService.info('Role deleted')
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get roles
    const getRoles = async (where) => {
      const apiToken = LocalStorageService.get('apiToken')

      if (!where) {
        where = {}
      }
      where.organisation = props.activeOrg.id

      setLoading(true)
      const roles = await RoleService.getRoles(apiToken, where)
      if (roles.error) {
        NotificationService.error(roles.error)
      } else {
        if (!unmounted) {
          // if there's no roles, do nothing
          if (!roles || !roles.data) {
            return
          }

          const mappedRoles = roles.data.map((data, index) => ({
            id: data.id,
            title: data.title,
            createdAt: data.createdAt
          }))

          setRoleValues(mappedRoles)
        }
      }
    }

    getRoles()

    return () => { unmounted = true }
  }, [])

  const dataColumns = [
    {
      property: 'title',
      header: <Text>Title</Text>,
      primary: true
    },
    {
      property: 'createdAt',
      header: <Text>Date Created</Text>
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          <Button href={`role/${datum.id}/edit`} icon={<FormEdit />} margin={{ horizontal: 'xsmall' }} primary target='_self' />
          <Button icon={<FormTrash />} margin={{ horizontal: 'xsmall' }} onClick={() => showDeleteRoleOptions(datum)} primary target='_self' />
        </Box>
      )
    }
  ]

  return (
    <Box>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text size='xlarge' margin={{ top: 'small' }} weight='bold'>Role Management</Text>
          {/* {error &&
            <FlashMessage message={error} />} */}
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteRole} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete role {roleToDelete.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box direction='row-responsive' gap='xlarge' justify='between' pad={{ horizontal: 'medium', top: 'medium' }}>
          <Box>
            <Text color='#000' size='large' weight='bold'>Organisations</Text>
          </Box>
          <Box direction='row-responsive' gap='xxsmall'>
            <Button href='role/new/edit' icon={<Add />} label='Add Role' primary target='_self' />
          </Box>
        </Box>

        <Box direction='column' gap='medium' pad={{ horizontal: 'medium', bottom: 'small' }}>
          <Box align='center'>
            <PulseLoader
              size={15}
              color='orange'
              loading={loading}
            />
          </Box>

          <DataTable
            columns={dataColumns}
            data={roleValues}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default RoleList
