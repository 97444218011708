import React, { useState } from 'react'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function BehaviourDetails (props) {
  const [questions, setQuestions] = useState(props.questions)

  // Submit form values
  const submitForm = async (values) => {
    props.submitForm(values)
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Behaviour Details</H2>
          <Button label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>} plain tip='This is the Behaviour Details page of the Incident Report' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.incidentValues}
        >
          <Box>
            {/* Description */}
            <FormField
              label={questions[9].questionText}
              name={questions[9].key}
            >
              <TextArea
                name={questions[9].key}
              />
            </FormField>
          </Box>


          <Box>
            <Text weight='bold'>As concisely as possible</Text>
          </Box>
          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['1/3', '1/3', '1/3'],
              large: ['1/3', '1/3', '1/3'],
              xlarge: ['1/3', '1/3', '1/3']
            }}
            gap='small'
          >
            {/* Low level behaviour student shows */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[0].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Low level behaviours might include:</Text>
                          <Text>Making noises</Text>
                          <Text>Going quiet</Text>
                          <Text>Showing signs of anxiety</Text>
                          <Text>Hiding face in hands</Text>
                          <Text>Pulling down hat</Text>
                          <Text>Tapping</Text>
                          <Text>Withdrawing from group</Text>
                          <Text>Refusing to speak or dismissive</Text>
                          <Text>Refusing to co-operate</Text>
                          <Text>Eye contact increased or decreased</Text>
                          <Text>Changes in breathing</Text>
                        </Box>),
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[0].key}
              required
            >
              <TextInput
                name={questions[0].key}
                type='text'
              />
            </FormField>

            {/* Low level how respond */}
            <FormField
              label={<Box height='40px'><Text size='small'>{questions[1].questionText}</Text></Box>}
              name={questions[1].key}
              required
            >
              <TextInput
                name={questions[1].key}
                type='text'
              />
            </FormField>

            {/* Low level strategies */}
            <FormField
              label={questions[2].questionText}
              label={<Box height='40px'><Text size='small'>{questions[2].questionText}</Text></Box>}
              name={questions[2].key}
              required
            >
              <TextInput
                name={questions[2].key}
                type='text'
              />
            </FormField>

            {/* Medium level behaviour student shows */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[3].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Medium level behaviours might include:</Text>
                          <Text>Adopting defensive postures</Text>
                          <Text>Body position & tension- arms crossed</Text>
                          <Text>Clenched jaws or fists</Text>
                          <Text>Making personal and offensive remarks</Text>
                          <Text>Talking louder – higher - quicker</Text>
                          <Text>Adopting aggressive postures</Text>
                          <Text>Changes in eye contact</Text>
                          <Text>Pacing around</Text>
                          <Text>Breaking minor rules</Text>
                          <Text>Low level destruction</Text>
                          <Text>Picking up objects which could be used as weapons</Text>
                          <Text>Beligerant and abusive</Text>
                        </Box>),
                      dropProps: { plain: true }
                    }}
                  />
                </Box>)}
              name={questions[3].key}
              required
            >
              <TextInput
                name={questions[3].key}
                type='text'
              />
            </FormField>

            {/* Medium level how respond */}
            <FormField
              label={<Box height='40px'><Text size='small'>{questions[4].questionText}</Text></Box>}
              name={questions[4].key}
              required
            >
              <TextInput
                name={questions[4].key}
                type='text'
              />
            </FormField>

            {/* Medium level strategies */}
            <FormField
              label={questions[5].questionText}
              label={<Box height='40px'><Text size='small'>{questions[5].questionText}</Text></Box>}
              name={questions[5].key}
              required
            >
              <TextInput
                name={questions[5].key}
                type='text'
              />
            </FormField>

            {/* High level behaviour student shows */}
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[6].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>High level behaviours might include:</Text>
                          <Text>Hurting self</Text>
                          <Text>Hurting others</Text>
                          <Text>Damaging property</Text>
                          <Text>Throwing objects</Text>
                          <Text>Picking up objects which could be used as weapons</Text>
                          <Text>Kicking</Text>
                          <Text>Punching</Text>
                        </Box>),
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[6].key}
              required
            >
              <TextInput
                name={questions[6].key}
                type='text'
              />
            </FormField>

            {/* High level how respond */}
            <FormField
              label={<Box height='40px'><Text size='small'>{questions[7].questionText}</Text></Box>}
              name={questions[7].key}
            >
              <TextInput
                name={questions[7].key}
              />
            </FormField>

            {/* High level strategies */}
            <FormField
              label={questions[8].questionText}
              name={questions[8].key}
            >
              <TextInput
                name={questions[8].key}
              />
            </FormField>
          </ResponsiveGrid>

          {/* <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => goBack()} secondary />
            <Button type='submit' label='Save' primary />
          </Box> */}
        </Form>
      </Box>
    </Box>
  )
}

export default BehaviourDetails
