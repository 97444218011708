
import ApiService from './ApiService'

class OrganisationSubscriptionService {
  intent = async (subscriptionId) => {
    var url = '/api/organisationsubscription/intent'

    var params = {}

    var options = {
      data: {
        subscription: subscriptionId
      }
    }

    try {
      var result = await ApiService.postNoToken(url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationSubscriptionService:intent ', result)
    return result
  }

  available = async (params) => {
    var url = '/api/organisationsubscription/available'

    try {
      var subscriptions = await ApiService.get(null, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationSubscriptionService:available ', subscriptions)
    return subscriptions
  }

  completeIntent = async (intentId) => {
    var url = '/api/organisationsubscription/intent'

    var params = {}

    var options = {
      data: {
        intentId: intentId,
        status: 'Complete'
      }
    }

    try {
      var result = await ApiService.putNoToken(url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationSubscriptionService:completeIntent ', result)
    return result
  }
}

export default new OrganisationSubscriptionService()
