import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
// import FileUpload from '../components/simple/input/FileUpload.js'
import Form from '../../components/simple/form/Form.js'
import Text from '../../components/simple/text/Text'

import { CSVReader } from 'react-papaparse'
import { buildInsertFields, validateInvalidFields, validateRequiredFields } from '../../utils/csv.js'

function UserUpload (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [userData, setUserData] = useState([])

  const submitForm = () => {
    // Only submit if a file has been uploaded
    if (userData.length) {
      // Check to see if there are any unknown fields
      const fileValidated = validateInvalidFields(userData, props.type === 'serviceuser' ? 'student' : 'user')

      if (!fileValidated.valid) {
        NotificationService.error(fileValidated.error)
        return
      }

      // Check that all required fields have been populated
      const filePopulated = validateRequiredFields(userData, props.type === 'serviceuser' ? 'student' : 'user')
      if (!filePopulated.valid) {
        NotificationService.error(filePopulated.error)
        return
      }

      if (fileValidated.warning) {
        NotificationService.warning(fileValidated.warning)
      }

      // Now build an insert statement
      const dataToInsert = buildInsertFields(userData, props.type === 'serviceuser' ? 'student' : 'user')
      let userError = false
      dataToInsert.forEach((student, index) => {
        console.log(student)
        const response = submitUsers(student)

        if (response.error) {
          NotificationService.error(response.error)
          userError = true
        }
      })

      if (!userError) {
        NotificationService.success('All users added')
      }
    }
  }

  // Submit Users from CSV file
  const submitUsers = async (student) => {
    student.type = props.type === 'serviceuser' ? 'student' : 'user'

    const data = {
      data: student,
      organisation: activeOrg.id
    }

    const userAdded = await UserService.createUser(apiToken, data)

    return userAdded
  }

  // Handle Drag & Drop functionality
  const handleOnDrop = (data) => {
    // Assume first row is a header row
    console.log('on drop', data)

    // // Generate headings from first row
    // const headings = []
    // for (const key in data[0]) {
    //   headings.push(key)
    // }

    // setUserHeadings(headings)

    // // No populate each row in the same order as the headings
    // for (const dataRow of data) {
    //   var thisRow = []
    //   for (const thisKey of headings) {
    //     const value = dataRow[thisKey] || null
    //     thisRow.push(value)
    //   }
    //   rows.push(thisRow)
    // }

    // Validate records to ensure required fields are populated
    // const response = validateFile(data)

    // if (response.status) {
    //   NotificationService.error('Row ' + response.row + ', column ' + response.col + ' is missing. Please fix and add the file again.')
    // } else {
    //   setUserData(data)
    // }
    setUserData(data)
  }

  const handleOnError = (err, file, inputElem, reason) => {
    NotificationService.error(err)
  }

  const handleOnRemoveFile = (data) => {
    console.log('on remove file', data)
  }

  const validateFile = (data) => {
    // Columns should be
    // firstName
    // lastName
    // email
    // ethnicity
    // gender
    // dob
    //
    // Assume all required for now
    let row = 1
    const error = {
      status: false,
      row: null,
      col: null
    }

    data.forEach((itemRow) => {
      // Skip first row (header) or if error found
      if (row !== 1 || !error.status) {
        let col = 1
        itemRow.data.forEach((itemCol) => {
          if (!error.status) {
            if (itemCol === '') {
              error.row = row
              error.col = col
              error.status = true
            }
          }
          col++
        })
      }

      row++
    })

    return error
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  // Basically componentDidMount
  useEffect(() => {
    console.log(props)
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Bulk User Upload</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Text margin={{ top: 'xsmall' }} size='xlarge'>Bulk User Upload</Text>

            <Text>Drop a file in the following box that has been formatted correctly to upload a large number of Users.</Text>

            <Text>Download a template <Anchor href={`/bulk-import-template-${props.type}.csv`}>here</Anchor></Text>
            <Form
              onSubmit={({ value: nextValue }) => {
                console.log(nextValue)
                submitForm(nextValue)
              }}
            //   value={}
            >

              <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                onRemoveFile={handleOnRemoveFile}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserUpload
