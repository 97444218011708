// Grommet
import { deepMerge } from 'grommet/utils'
import { css } from 'styled-components'

// Default theme
import { theme } from './theme'

// This theme will merge with and overwrite if it exists the entries in theme.js
// which in turn does the same with the default grommet theme
//
// For reference here are the default grommet colors
//
// brand: #7D4CDB
// accent-1: #6FFFB0
// accent-2: #FD6FFF
// accent-3: #81FCED
// accent-4: #FFCA58
// neutral-1: #00873D
// neutral-2: #3D138D
// neutral-3: #00739D
// neutral-4: #A2423D
// status-critical: #FF4040
// status-error: #FF4040
// status-warning: #FFAA15
// status-ok: #00C781
// status-unknown: #CCCCCC
// status-disabled: #CCCCCC
// light-1: #F8F8F8
// light-2: #F2F2F2
// light-3: #EDEDED
// light-4: #DADADA
// light-5: #DADADA
// light-6: #DADADA
// dark-1; #333333
// dark-2: #555555
// dark-3: #777777
// dark-4: #999999
// dark-5: #999999
// dark-6: #999999
//
// They can be overridden as follows:
//
// 'dark-6': '#000000'

export const beSmartTheme = deepMerge(theme, {
  // Global Settings
  global: {
    font: {
      family: 'effra, sans-serif'
    },
    colors: {
      brand: '#fe8300', //  Orange
      primary: '#fe8300', //  Orange
      background: '#051449', // Dark Blue
      secondary: '#163567',
      'text-color': '#000A56',
      darkGrey: '#333',
      focus: '#fe8300',
      LabelGreen: 'green',
      LabelYellow: 'yellow',
      LabelRed: 'red'
    },
    control: {
      border: {
        radius: 'none'
      }
    },
    focus: {
      shadow: {
        size: '0px'
      },
      outline: {
        size: '1px'
      }
    },
    input: {
      padding: {
        vertical: '5px'
      },
      weight: '400'
    }
  },
  calendar: {
    extend: 'background-color: white'
  },
  text: {
    xsmall: {
      size: '12px'
    },
    small: {
      size: '14px'
    },
    medium: {
      size: '18px'
    },
    large: {
      size: '22px'
    },
    xlarge: {
      size: '26px'
    },
    xxlarge: {
      size: '34px'
    }
  },
  // heading: {
  // level: {
  // 1: {
  // font: {
  // size: 'small'
  // }
  // }
  // }
  // },
  // Button
  button: {
    default: {
      background: { color: 'brand' },
      color: 'white',
      round: 'small'
    },
    primary: {
      background: { color: 'brand' },
      color: 'white',
      round: 'small',
      font: {
        size: 'medium'
      },
      padding: {
        horizontal: 'large'
      }
    },
    secondary: {
      background: { color: 'secondary' },
      color: 'white',
      border: { color: 'secondary' },
      padding: {
        horizontal: 'medium'
      }
    },
    hover: {
      default: {
        color: '#fff',
        background: { color: 'secondary' }
      },
      primary: {
        color: '#fff',
        background: { color: 'secondary' }
      },
      secondary: {
        color: '#fff',
        background: { color: 'primary' }
      }
    },
    border: {
      radius: 'none'
    },
    size: {
      medium: {
        border: {
          radius: 'small'
        },
        pad: {
          vertical: 'none'
        }
      }
    }

  },
  formField: {
    label: {
      margin: {
        left: '0px',
        bottom: 'none'
      },
      size: 'small'
    },
    content: {
      margin: {
        bottom: 'none'
      },
      color: '#f00'
    },
    extend: {
      color: 'text-color'
    }

  },
  select: {
    container: {
      // extend needed otherwise an empty select dropdown
      // will have the dashboard background as it's background
      extend: `
        background-color: white;
      `
    },
    options: {
      container: {
        background: 'white'
      }
    }
  },
  dataTable: {
    header: {
      font: {
        weight: '500'
      }
    },
    groupHeader: {
      font: {
        weight: '500'
      }
    }
  },
  // tab
  tab: {
    active: {
      background: 'brand',
      color: 'white'
    },
    background: 'white',
    border: undefined,
    color: 'background',
    hover: {
      background: 'background',
      color: 'brand'
    },
    margin: undefined,
    pad: {
      bottom: undefined,
      horizontal: 'small',
      vertical: 'small'
    }
    // extend: ({ theme }) => css`
    //   // border-radius: 4px;
    //   /* or 'border-radius: ${theme.global.control.border.radius}' */
    //   box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
    //   /* or 'box-shadow: ${theme.global.elevation.light.small}' */
    // `
  },
  tabs: {
    // background: 'dark-3',
    gap: 'medium'
    // header: {
    //   // background: 'dark-2',
    //   extend: ({ theme }) => css`
    //   padding: 10px;
    //   /* or 'padding: ${theme.global.edgeSize.small}' */
    //   box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.50);
    //   /* or 'box-shadow: ${theme.global.elevation.light.medium}' */
    // `
    // },
    // panel: {
    //   extend: ({ theme }) => css`
    //   padding: 48px;
    //   /* or 'padding: ${theme.global.edgeSize.large}' */
    //   box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.50);
    //    /* or 'box-shadow: ${theme.global.elevation.light.medium}' */
    // `
    // }
  },
  // textInput
  textInput: {
    extend: {
      background: 'white',
      color: '#444'
    },
    focus: {

    },
    padding: {
      vertical: '5px'
    }

  }
})
