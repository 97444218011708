import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationTagService from '../../services/OrganisationTagService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// utils
import { capitalize, removeSpaces, commaSeparate } from '../../utils/text'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import Meter from '../../components/simple/meter/Meter'
import Tabs from '../../components/simple/tabs/Tabs.js'
import Text from '../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

import BehaviourDetails from './BehaviourPlan-BehaviourDetails'
import BehaviourTechniques from './BehaviourPlan-BehaviourTechniques'
import FollowUp from './BehaviourPlan-FollowUp'
// import PhysicalTechniques from './IncidentEdit-PhysicalTechniques'
// import PostIncidentLearning from './IncidentEdit-PostIncidentLearning'
import ReportSend from './BehaviourPlan-ReportSend'
import RiskAssessment from './BehaviourPlan-RiskAssessment'

function BehaviourPlan (props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [userValues, setUserValues] = useState([])
  const [studentValues, setStudentValues] = useState([])
  // const [submissionResponses, setSubmissionResponses] = useState([])
  const [behaviourPlanValues, setBehaviourPlanValues] = useState({
    // Risk Assessment
    studentName: '',
    dateOfPlan: '',
    reviewDate: '',
    completedBy: '',
    additionalAdjustments: '',
    triggers: '',
    triggerSupport: '',
    // Behaviour Details
    lowLevelBehaviour: '',
    lowLevelTried: '',
    lowLevelOtherThings: '',
    lowLevelHelpMyself: '',
    mediumLevelBehaviour: '',
    mediumLevelTried: '',
    mediumLevelOtherThings: '',
    mediumLevelHelpMyself: '',
    highLevelBehaviour: '',
    highLevelTried: '',
    highLevelOtherThings: '',
    highLevelHelpMyself: '',
    // FollowUp
    recoveryIShow: '',
    helpMyself: '',
    staffHelp: '',
    interests: '',
    factors: '',
    otherInformation: '',
    // Behaviour Management Techniques
    calmTalking: '',
    calmTalkingNotes: '',
    optionsOffered: '',
    optionsOfferedNotes: '',
    plannedIgnoring: '',
    plannedIgnoringNotes: '',
    successReminded: '',
    successRemindedNotes: '',
    contingentTouch: '',
    contingentTouchNotes: '',
    listening: '',
    listeningNotes: '',
    negotiation: '',
    negotiationNotes: '',
    takeUpTime: '',
    takeUpTimeNotes: '',
    empathy: '',
    empathyNotes: ''
  })

  const [notifications, setNotifications] = useState([])

  const [userDetails, setUserDetails] = useState({
    organisation: 0,
    role: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    jobtitle: ''
  })

  const [keyContacts, setKeyContacts] = useState([])

  const [meterValue, setMeterValue] = useState()

  const [responses, setResponses] = useState()

  // This is the tabId for the techniques
  const techniqueTabId = 3

  const [questions, setQuestions] = useState([
    // Risk Assessment
    {
      tabId: 0,
      questions: [
        {
          questionText: `${capitalize(props?.terms?.serviceUser || 'Service User')} Name`,
          key: 'studentName'
        },
        {
          questionText: 'Date of Plan',
          key: 'dateOfPlan'
        },
        {
          questionText: 'Review Date of Plan',
          key: 'reviewDate'
        },
        {
          questionText: 'Completed By',
          key: 'completedBy'
        },
        {
          questionText: 'What should be done additionally?',
          key: 'additionalAdjustments'
        },
        {
          questionText: 'Triggers',
          key: 'triggers'
        },
        {
          questionText: 'Support for Triggers',
          key: 'triggerSupport'
        },
        {
          questionText: 'Any other relevant information',
          key: 'otherInformation'
        }
      ]
    },
    // Behaviour Details
    {
      tabId: 1,
      questions: [
        {
          questionText: 'Low Level Behaviour I show',
          key: 'lowLevelBehaviour'
        },
        {
          questionText: "What's been tried?",
          key: 'lowLevelTried'
        },
        {
          questionText: 'Other things that could be tried',
          key: 'lowLevelOtherThings'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'lowLevelHelpMyself'
        },
        {
          questionText: 'Medium Level Behaviour I show',
          key: 'mediumLevelBehaviour'
        },
        {
          questionText: "What's been tried?",
          key: 'mediumLevelTried'
        },
        {
          questionText: 'Other things that could be tried',
          key: 'mediumLevelOtherThings'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'mediumLevelHelpMyself'
        },
        {
          questionText: 'High Level Behaviour I show',
          key: 'highLevelBehaviour'
        },
        {
          questionText: "What's been tried?",
          key: 'highLevelTried'
        },
        {
          questionText: 'Other things that could be tried',
          key: 'highLevelOtherThings'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'highLevelHelpMyself'
        }
      ]
    },
    // Follow Up
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Recovery Behaviour I sometimes show',
          key: 'recoveryIShow'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'helpMyself'
        },
        {
          questionText: 'What can staff do to help me?',
          key: 'staffHelp'
        },
        {
          questionText: 'What interests me?',
          key: 'interests'
        },
        {
          questionText: 'Are there any factors to consider when debriefing?',
          key: 'factors'
        },
        {
          questionText: 'Any other information',
          key: 'otherInformation'
        }
      ]
    },
    // Behaviour Management Techniques
    {
      tabId: 3,
      questions: [
        {
          questionText: '#1: C.A.L.M talking',
          key: 'calmTalking',
          type: 'deescalationTechnique'
        },
        {
          questionText: 'Notes',
          key: 'calmTalkingNotes'
        },
        {
          questionText: '#2: Options Offered',
          key: 'optionsOffered'
        },
        {
          questionText: 'Notes',
          key: 'optionsOfferedNotes'
        },
        {
          questionText: '#3: Planned Ignoring',
          key: 'plannedIgnoring'
        },
        {
          questionText: 'Notes',
          key: 'plannedIgnoringNotes'
        },
        {
          questionText: '#4: Success Reminded',
          key: 'successReminded'
        },
        {
          questionText: 'Notes',
          key: 'successRemindedNotes'
        },
        {
          questionText: '#5: Contingent touch',
          key: 'contingentTouch'
        },
        {
          questionText: 'Notes',
          key: 'contingentTouchNotes'
        },
        {
          questionText: '#1: Listening',
          key: 'listening'
        },
        {
          questionText: 'Notes',
          key: 'listeningNotes'
        },
        {
          questionText: '#2: Negotiation',
          key: 'negotiation'
        },
        {
          questionText: 'Notes',
          key: 'negotiationNotes'
        },
        {
          questionText: '#3: Negotiation???',
          key: 'negotiation?'
        },
        {
          questionText: 'Notes',
          key: 'negotiationNotes'
        },
        {
          questionText: '#4: Take up time',
          key: 'takeUpTime'
        },
        {
          questionText: 'Notes',
          key: 'takeUpTimeNotes'
        },
        {
          questionText: '#5: Empathy',
          key: 'empathy'
        },
        {
          questionText: 'Notes',
          key: 'empathyNotes'
        }
      ]
    },
    {
      tabId: 4,
      questions: [
        {
          questionText: 'Which key contacts should receive a copy of this report?',
          key: 'notify'
        }
      ]
    }
  ])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const params = useParams()

  const userId = params.userid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    setMeterValue((nextIndex + 1) * 100 / questions.length)
    setTabIndex(nextIndex)
  }

  const tabContent = [
    {
      name: 'Behaviour Plan',
      hideTitle: true,
      content: <RiskAssessment behaviourPlanValues={behaviourPlanValues} questions={questions[0].questions} setBehaviourPlanValues={setBehaviourPlanValues} setStudentValues={setStudentValues} studentValues={studentValues} userId={userId} userValues={userValues} setUserValues={setUserValues} />
    },
    {
      name: 'Behaviour Details',
      hideTitle: true,
      content: <BehaviourDetails behaviourPlanValues={behaviourPlanValues} questions={questions[1].questions} setBehaviourPlanValues={setBehaviourPlanValues} />
    },
    {
      name: 'Follow Up Support',
      hideTitle: true,
      content: <FollowUp behaviourPlanValues={behaviourPlanValues} questions={questions[2].questions} setBehaviourPlanValues={setBehaviourPlanValues} />
    },
    {
      name: 'Behaviour Management Techniques',
      hideTitle: true,
      content: <BehaviourTechniques behaviourPlanValues={behaviourPlanValues} questions={questions[3].questions} setBehaviourPlanValues={setBehaviourPlanValues} />
    },
    {
      name: 'Send Report',
      hideTitle: true,
      content: <ReportSend contacts={keyContacts} questions={questions[4].questions} setNotifications={setNotifications} />
    }
  ]

  const getContacts = async () => {
    const params = {
      fields: 'user,type,key,organisation,value'
    }

    const where = {
      user: behaviourPlanValues.studentName
    }

    let contacts = await UserTagService.get(apiToken, params, where)

    if (contacts) {
      // Filter out the 'keycontacts'
      contacts = contacts.filter((item) => item.key === 'keycontacts')
    }

    if (contacts) {
      const parsedContacts = JSON.parse(contacts[0].value)
      const mappedContacts = parsedContacts.map((contact, index) => ({
        display: <><Box direction='column'><Text weight='bold'>{contact.firstName} {contact.lastName}</Text><Text>{contact.role}</Text></Box></>,
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        name: contact.firstName + ' ' + contact.lastName,
        role: contact.role
      }))

      console.log('saved contacts ', mappedContacts)

      setKeyContacts(mappedContacts)
    }
  }

  const getTechniques = async (techniqueType) => {
    const params = {
      fields: 'value,key',
      limit: 2
    }

    const where = {
      organisation: activeOrg.id,
      type: 'besmart',
      key: techniqueType
    }

    const techniques = await OrganisationTagService.get(apiToken, params, where)

    return techniques
  }

  const getTechniqueQuestions = async () => {
    const techniqueType = ['deescalationTechniques', 'physicalTechniques']

    const techniqueQuestions =
      {
        tabId: techniqueTabId,
        questions: []
      }

    let techniques = null

    techniqueType.forEach(async (techniqueType) => {
      techniques = await getTechniques(techniqueType)
      // Get organisation's deescalation/physical techniques
      // and add them to the relevant tab of questions

      if (techniques) {
        const parsedTechniques = JSON.parse(techniques[0].value)

        let mappedTechnique = {}

        for (const technique of parsedTechniques) {
          // If technique is marked for selection
          // then convert it into the correct format
          // and save to new array
          if (technique.selected) {
            mappedTechnique = {}
            mappedTechnique.questionText = technique.technique
            mappedTechnique.key = removeSpaces(technique.technique.toLowerCase())
            mappedTechnique.responseType = 'number'
            mappedTechnique.type = techniqueType
            techniqueQuestions.questions.push(mappedTechnique)

            // Add the Notes question
            techniqueQuestions.questions.push(
              {
                questionText: 'Notes',
                key: mappedTechnique.key + '-notes'
              }
            )
          }
        }
      }
    })

    const newQuestions = questions
    newQuestions[techniqueTabId] = techniqueQuestions
    setQuestions(newQuestions)
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // Submit form values
  const submitForm = async (draft) => {
    console.log('submit', behaviourPlanValues)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each form value
    for (var key in behaviourPlanValues) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(question => question.key === key)

        if (found && checkInputHasContent(behaviourPlanValues[key])) {
          item = {
            questionKey: key,
            questionText: found.questionText,
            response: behaviourPlanValues[key]
          }
          submitArray.push(item)
        }
      })
    }

    // Build output data
    const data = {
      user: behaviourPlanValues.studentName,
      organisation: activeOrg.id,
      status: draft ? 'Draft' : 'Published',
      type: 'behaviourplan',
      reference: '',
      responses: submitArray
    }

    var submissionAdded = await FormService.makeSubmissions(apiToken, data)
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error)
    } else {
      NotificationService.info('Behaviour Plan added')
      history.push('/serviceusers')
    }
  }

  const getBehaviourPlans = async (userId) => {
    let params = {
      fields: 'id,status',
      limit: 1,
      sort: 'createdAt DESC'
    }

    let where = {
      organisation: activeOrg.id,
      user: userId,
      type: 'behaviourplan'
      // status: 'Draft'
    }

    const formSubmissions = await FormService.getSubmissions(apiToken, params, where)

    // If latest submission is Draft display the responses to edit
    // otherwise start a new one
    if (!formSubmissions?.error && formSubmissions.data[0].status === 'Draft') {
      params = {
        fields: 'questionKey,response,responseType',
        limit: 1000
      }

      where = {
        submission: formSubmissions.data[0].id
      }

      const formResponses = await FormService.getResponses(apiToken, params, where)

      // Get responses and convert into correct format
      if (formResponses.data.length > 0) {
        const mappedResponses = {}
        for (const response of formResponses.data) {
          if (response.responseType === 'number') {
            response.response = parseInt(response.response)
          }
          mappedResponses[response.questionKey] = response.response
        }

        setResponses(mappedResponses)
      }
    }
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack()
    } else {
      const prevPageIndex = tabIndex - 1
      onActiveTab(prevPageIndex)
    }
  }

  const nextPage = () => {
    if (tabIndex === tabContent.length - 1) {
      submitForm()
    } else {
      const nextPageIndex = tabIndex + 1
      onActiveTab(nextPageIndex)
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    const getUsers = async (type = 'user') => {
      const params = {
        fields: 'id,firstName,lastName,reference,type',
        limit: 1000,
        orgId: activeOrg.id,
        type
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (!users || users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            if (type === 'user') {
              // Get system users
              const mappedUsers = users.data.map((data, index) => ({
                id: data.id,
                // learnerNumber: data.reference,
                name: data.firstName + ' ' + data.lastName
              }))

              setUserValues(mappedUsers)
            } else if (type === 'student') {
              // console.log(mappedUsers)
              // Now get students
              const studentUsers = users.data.map((data, index) => ({
                id: data.id,
                learnerNumber: data.reference,
                name: data.firstName + ' ' + data.lastName
              }))

              setStudentValues(studentUsers)
            }
          }
        }
      }
    }

    const getUserDetails = async (userId, type = 'user') => {
      const params = {
        fields: 'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
        limit: 1,
        orgId: activeOrg.id,
        type
      }

      const userDetails = await UserService.getUser(apiToken, params, userId)
      if (userDetails?.error) {
        NotificationService.error(userDetails.error)
      } else {
        if (!unmounted) {
          if (userDetails?.data) {
            setUserDetails(userDetails.data[0])
          }
        }
      }
    }

    // Get all Published Submissions for this User
    const getUserSubmissions = async (userId) => {
      let params = {
        fields: 'id,user,organisation,reference,type',
        limit: 1000,
        sort: 'createdAt DESC'
      }

      let where = {
        status: 'Published',
        type: 'incidentreport',
        user: userId
      }

      const submissions = await FormService.getSubmissions(apiToken, params, where)
      if (submissions?.error) {
        NotificationService.error(submissions.error)
      } else {
        if (!unmounted) {
          // Now get all Responses for each of the Submissions
          const submissionIds = submissions.data.map((item) => { return item.id })
          if (submissions?.data) {
            params = {
              fields: 'questionKey,questionText,response,responseType',
              limit: 1000
            }

            where = {
              user: userId,
              // submission: submissions.data[0].id
              submission: submissionIds
            }
            const responses = await FormService.getResponses(apiToken, params, where)
            if (responses?.error) {
              NotificationService.error(responses.error)
            } else {
              const behaviourResponses = {}
              let followUpString = ''

              // Copy Incident Report responses
              // into the Behaviour Plan
              responses.data.forEach((item) => {
                switch (item.questionKey) {
                  case 'lowLevelBehaviour':
                    behaviourResponses.lowLevelBehaviour = commaSeparate(behaviourResponses.lowLevelBehaviour, item.response)
                    break
                  case 'lowLevelResponse':
                    behaviourResponses.lowLevelTried = commaSeparate(behaviourResponses.lowLevelTried, item.response)
                    break
                  case 'lowLevelStrategy':
                    behaviourResponses.lowLevelOtherThings = commaSeparate(behaviourResponses.lowLevelOtherThings, item.response)
                    break
                  case 'mediumLevelBehaviour':
                    behaviourResponses.mediumLevelBehaviour = commaSeparate(behaviourResponses.mediumLevelBehaviour, item.response)
                    break
                  case 'mediumLevelResponse':
                    behaviourResponses.mediumLevelTried = commaSeparate(behaviourResponses.mediumLevelTried, item.response)
                    break
                  case 'mediumLevelStrategy':
                    behaviourResponses.mediumLevelOtherThings = commaSeparate(behaviourResponses.mediumLevelOtherThings, item.response)
                    break
                  case 'highLevelBehaviour':
                    behaviourResponses.highLevelResponse = commaSeparate(behaviourResponses.highLevelResponse, item.response)
                    break
                  case 'highLevelResponse':
                    behaviourResponses.highLevelTried = commaSeparate(behaviourResponses.highLevelTried, item.response)
                    break
                  case 'highLevelStrategy':
                    behaviourResponses.highLevelOtherThings = commaSeparate(behaviourResponses.highLevelOtherThings, item.response)
                    break
                  case 'recoveryBehaviour':
                    behaviourResponses.recoveryIShow = commaSeparate(behaviourResponses.recoveryIShow, item.response)
                    break
                  case 'recoveryResponse':
                    behaviourResponses.helpMyself = commaSeparate(behaviourResponses.helpMyself, item.response)
                    break
                  case 'recoveryStrategy':
                    behaviourResponses.staffHelp = commaSeparate(behaviourResponses.staffHelp, item.response)
                    break
                  case 'likelyTriggers':
                    behaviourResponses.triggers = commaSeparate(behaviourResponses.triggers, item.response)
                    break
                  case 'adjustments':
                    behaviourResponses.additionalAdjustments = commaSeparate(behaviourResponses.additionalAdjustments, item.response)
                    break
                  case 'studentBehaviour':
                  case 'studentResponse':
                  case 'studentStrategy':
                    if (followUpString !== '') {
                      followUpString = followUpString + '\n' + item.response
                    } else {
                      followUpString += item.response
                    }
                    break
                }
              })

              // What interest me field is filled from
              // a combination of Incident Report fields
              behaviourResponses.interests = followUpString

              setBehaviourPlanValues(behaviourValues => ({
                ...behaviourValues,
                ...behaviourResponses
                // lowLevelBehaviour: behaviourResponses.lowLevelResponse,
                // lowLevelTried: behaviourResponses.lowLevelTried,
                // lowLevelOtherThings: behaviourResponses.lowLevelOtherThings,
                // mediumLevelBehaviour: behaviourResponses.mediumLevelResponse,
                // highLevelBehaviour: behaviourResponses.highLevelResponse
              }))

              // setSubmissionResponses(responses.data)
            }
          }
        }
      }
    }

    getUsers('user')
    getUsers('student')
    getTechniqueQuestions()

    if (userId !== 'new') {
      getUserDetails(userId, 'student')
      getUserSubmissions(userId)

      setBehaviourPlanValues(behaviourValues => ({
        ...behaviourValues,
        studentName: parseInt(userId)
      }))
    }

    setLoading(false)

    // Set initial Meter setting
    setMeterValue(100 / questions.length)

    return () => { unmounted = true }
  }, [])

  useEffect(() => {
    if (behaviourPlanValues.studentName) {
      getContacts()

      getBehaviourPlans(userId)
    }
  }, [behaviourPlanValues.studentName])

  useEffect(() => {
    setBehaviourPlanValues(behaviourPlanValues => ({
      ...behaviourPlanValues,
      ...responses,
      studentName: parseInt(userId)
    }))
  }, [responses])

  // useEffect(() => {
  //   console.log('quesx', questions)
  // }, [questions])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        New Behaviour Plan: {userDetails.firstName} {userDetails.lastName}
      </BreadcrumbBar>
      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>{userId === 'new' ? 'New ' : ''}New Behaviour Plan: {userDetails.firstName} {userDetails.lastName}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box> */}
      <Box gridArea='main' background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Meter background='light-2' size='full' thickness='xsmall' type='bar' values={[{ color: 'brand', value: meterValue }]} />
          {studentValues
            ? <Tabs activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} full />
            : (
              <Box align='center'>
                <PulseLoader
                  size={15}
                  color='orange'
                  loading={loading}
                />
              </Box>)}

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            {tabIndex < tabContent.length - 1 &&
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Save for later</Text>} onClick={() => submitForm(true)} />}
            <Button label={tabIndex !== tabContent.length - 1 ? 'Next Page' : 'Submit'} onClick={() => nextPage()} primary />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BehaviourPlan
