// React
import React from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box.js'
import List from '../../simple/list/List.js'
import Text from '../../simple/text/Text.js'

// Font Awesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * `CardCounter` renders a Card to display a counter of items eg. number of posts
 */
class ListBox extends React.Component {
  render () {
    return (
      <Box background={this.props.background || '#fff'} pad='medium' round='small'>
        <Text size='large' weight='bold'>{this.props.heading}</Text>
        <List data={this.props.data} secondaryKey={this.props.primaryKey} primaryKey={this.props.secondaryKey}>
          {this.props.children}
        </List>
      </Box>)
  }
}

ListBox.propTypes = {
  /**
   * Background colour for box
   */
  background: PropTypes.string,
  /**
   * Array of data objects to pass to List.
   *
   * [
   * &nbsp;"string"
   * &nbsp;{
   * &nbsp;&nbsp;""
   * &nbsp;}
   * ]
   */
  data: PropTypes.array,
  /**
   * Heading
   */
  heading: PropTypes.string
}

export default ListBox
