import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import moment from 'moment'

import { capitalize } from '../utils/text'

// Services
import FormService from '../services/FormService'
import LocalStorageService from '../services/LocalStorageService'
import OrganisationService from '../services/OrganisationService'

// Grommet
import { DocumentPdf, DocumentText, Folder, Image, Video } from 'grommet-icons'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import DateInput from '../components/simple/input/DateInput'
import FontAwesome from '../components/compound/fontAwesome/FontAwesome'
import FormField from '../components/simple/formField/FormField.js'
import Text from '../components/simple/text/Text'
import H1 from '../components/simple/heading/H1'

import BreadcrumbBar from './shared/BreadcrumbBar'

// Appt Widgets
import CounterBox from '../components/compound/counterBox/CounterBox'
import ListBox from '../components/compound/listBox/ListBox'
import { Form } from 'grommet'

function Dashboard (props) {
  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore()
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const [dashboardState, setDashboardState] = useState({
    services: '',
    users: '',
    students: '',
    smartplans: '',
    incidents: ''
  })

  const [services, setServices] = useState()
  const [systemUsers, setSystemUsers] = useState()

  // NOTE: getUser and clearSession currently need to be called on every screen
  // this is definitely a violation of DRY and needs to be refactor

  // check if the user is logged in, if so restored the session
  // const getUser = () => {
  //   // check local storage
  //   var apiToken = LocalStorageService.get('apiToken')
  //   if (apiToken) {
  //     setApiToken(apiToken)
  //     // get currentOrgs
  //     const myOrganisations = LocalStorageService.get('currentOrgs')
  //     setCurrentOrgs(myOrganisations)
  //   } else {
  //     clearSession()
  //   }
  // }

  // // clear the user's session if they log out
  // const clearSession = () => {
  //   // remove all localstorage
  //   LocalStorageService.clear()

  //   // remove the session data
  //   deleteApiToken()
  //   deleteCurrentUser()
  //   deleteCurrentOrgs()
  //   history.push('/')
  // }

  const contentIcons = {
    pdf: <DocumentPdf size='medium' color='brand' />,
    text: <DocumentText size='medium' color='brand' />,
    category: <Folder size='medium' color='brand' />,
    image: <Image size='medium' color='brand' />,
    video: <Video size='medium' color='brand' />
  }

  const [searchDates, setSearchDates] = useState({
    searchFrom: '',
    searchTo: ''
  })

  // // Quick Stats date has been entered
  // const onDateChange = (searchDate, field) => {
  //   // console.log(moment(value).format('YYYY-MM-DD'))
  //   // const x = moment(searchDate, 'YYYY-MM-DD')
  //   // console.log(x.format())
  //   setSearchDates(previousValues => ({
  //     ...previousValues,
  //     fromDate: field === 'from' ? searchDate.value : previousValues.fromDate,
  //     toDate: field === 'to' ? searchDate.value : previousValues.toDate
  //   }))
  // }

  // const dateValidation = [
  //   date => {
  //     console.log(date)
  //     // const expireDate = date.split('/').reverse()
  //     // expireDate.splice(0, 0, '20')
  //     // const newYear = expireDate[0] + expireDate[1]
  //     // expireDate.splice(0, 2, newYear)
  //     // const expires = new Date(expireDate)
  //     // if (
  //     //   expires.getMonth() < currentDate.getMonth() &&
  //     //   expires.getFullYear() <= currentDate.getFullYear()
  //     // ) {
  //     //   return { message: 'invalid date', status: 'error' }
  //     // }
  //     // return undefined
  //     if (searchDates.searchFrom && searchDates.searchTo) {
  //       const fromMoment = moment(searchDates.searchFrom)
  //       const toMoment = moment(searchDates.searchTo)
  //       // if (moment(searchDates.searchTo).isBefore(searchDates.searchFrom)) {
  //       if (toMoment.isBefore(fromMoment, 'day')) {
  //         return { message: 'both dates need to be entered', status: 'error' }
  //       }
  //     }

  //     // if (!searchDates.fromDate || !searchDates.toDate) {
  //     //   return { message: 'both dates need to be entered', status: 'error' }
  //     // }
  //   }
  // ]

  useEffect(() => {
    // console.log(searchDates)
    if (searchDates.searchFrom && searchDates.searchTo) {
      const fromMoment = moment(searchDates.searchFrom)
      const toMoment = moment(searchDates.searchTo)
      // if (moment(searchDates.searchTo).isBefore(searchDates.searchFrom)) {
      if (toMoment.isBefore(fromMoment, 'day')) {
        console.log('early date')
      } else {
        getDashboardCounts(true)
      }
    }
  }, [searchDates])

  // Get number of Users for this Organisation
  const getDashboardCounts = async (dateRestricted) => {
    console.log('This is dash props', props)

    if (!activeOrg || !activeOrg.id || !apiToken) {
      // Temporary use of apiToken
      // const apiToken = LocalStorageService.get('apiToken')
      //
      const apiToken = LocalStorageService.get('apiToken')
      const activeOrg = LocalStorageService.get('activeOrg')
      const organisationId = activeOrg.id

      const dateRestrictedQuery = {
        createdAt: [
          {
            operator: '>',
            value: moment(searchDates.searchFrom).format('YYYY-MM-DD HH:mm:ss')
          },
          {
            operator: '<',
            value: moment(searchDates.searchTo).format('YYYY-MM-DD HH:mm:ss')
          }
        ]
      }

      let params = {
        fields: 'id,name,createdAt',
        sort: 'createdAt DESC'
      }

      if (dateRestricted) {
        params.where = dateRestrictedQuery
      }

      const dashboardCount = {}

      const organisations = await OrganisationService.getOrganisations(apiToken, params, organisationId)

      setServices(organisations.data)

      dashboardCount.services = organisations?.page?.total || 0

      params = {
        fields: 'id,email,firstName,lastName,type',
        orgId: activeOrg.id
      }

      if (dateRestricted) {
        params.where = dateRestrictedQuery
      }

      const userCount = await OrganisationService.getUserCount(apiToken, params, organisationId)

      dashboardCount.users = userCount?.systemUsers?.length || 0

      setSystemUsers(userCount.systemUsers)

      dashboardCount.students = userCount?.students?.length || 0

      params = {
        fields: 'id,user,organisation,reference,type',
        limit: 1000,
        orgId: activeOrg.id
      }

      const where = {
        organisationId: organisationId,
        type: 'incidentreport',
        status: 'Published'
      }

      if (dateRestricted) {
        Object.assign(where, dateRestrictedQuery)
      }

      // get incident reports
      const incidentReportSubmissions = await FormService.getSubmissions(apiToken, params, where)

      dashboardCount.incidents = 0
      if (incidentReportSubmissions?.page?.total) {
        dashboardCount.incidents = incidentReportSubmissions.page.total
      }

      where.type = 'behaviourplan'

      // get incident reports
      const smartPlanSubmissions = await FormService.getSubmissions(apiToken, params, where)

      dashboardCount.smartplans = 0
      if (smartPlanSubmissions?.page?.total) {
        dashboardCount.smartplans = smartPlanSubmissions.page.total
      }

      setDashboardState(dashboardCount)
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    getDashboardCounts(false)
  }, [])

  useEffect(() => {
    getDashboardCounts(false)
  }, [props.activeOrg])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        <H1 margin={{ top: 'small', bottom: 'none' }}>Dashboard </H1>
      </BreadcrumbBar>
      {/* <Box background='white' direction='column' flex='grow' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Dashboard </H1>
          {error &&
            <FlashMessage message={error} />}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box> */}

      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>{/* pad={{ bottom: responsive === 'small' ? 'medium' : 'none' }} */}
            <Text size='xxlarge' weight={400} margin={{ top: 'none' }}>Quick Stats</Text>
          </Box>
          {/* <Box direction='row' gap='medium'> */}
          <Form
            // messages={{
            //   required: 'This is a required field.',
            // }}
            onChange={setSearchDates}
            // onValidate={validationResults => {
            //   console.log('validationResults = ', validationResults)
            //   setValid(validationResults.valid)
            // }}
            value={searchDates}
            // validate='blur'
          >
            <Box direction='row' gap='medium'>
              <FormField
                htmlFor='searchFrom'
                label='From'
                name='searchFrom'
                // validate={dateValidation}
              >
                <DateInput
                  format='dd/mm/yyyy'
                  name='searchFrom'
                  // onChange={(value) => { onDateChange(value, 'from') }}
                  // value={searchDates.fromDate}
                />
              </FormField>

              <FormField
                htmlFor='searchTo'
                label='To'
                name='searchTo'
                // validate={dateValidation}
              >
                <DateInput
                  format='dd/mm/yyyy'
                  name='searchTo'
                  // onChange={(value) => { onDateChange(value, 'to') }}
                  // value={searchDates.toDate}
                />
              </FormField>
            </Box>
          </Form>
          {/* </Box> */}
        </Box>
      </Box>

      <Box direction='row-responsive' gap='medium' flex={1}>
        <Box gap='small' justify='between' margin={{ bottom: 'medium' }}>
          <CounterBox counter={dashboardState.services} iconColor='#52bc94' icon='briefcase'>Services</CounterBox>
          <CounterBox counter={dashboardState.students} iconColor='#52bc94' icon='graduation-cap'>{capitalize(props?.terms?.serviceUserPlural || 'Service Users')}</CounterBox>
          <CounterBox counter={dashboardState.users} iconColor='#52bc94' icon='user-friends'>System Users</CounterBox>
          <CounterBox counter={dashboardState.incidents} iconColor='#52bc94' icon='exclamation-triangle'>Incidents Reported</CounterBox>
          <CounterBox counter={dashboardState.smartplans} iconColor='#52bc94' icon='user-friends'>Behaviour Plans</CounterBox>
        </Box>

        <Box direction='row-responsive' gap='medium' flex={1}>
          <ListBox
            data={services?.slice(0, 15)}
            heading='Recent Services'
          >
            {datum => (
              <Box>
                <Anchor href={`/service/${datum.id}/edit`} label={<Box direction='row' justify='between'><Text>{datum.name}</Text><FontAwesome color='brand' icon={['fas', 'angle-right']} /></Box>} />
              </Box>
            )}
          </ListBox>
        </Box>
        <Box direction='row-responsive' gap='medium' flex={1}>
          <ListBox
            data={systemUsers?.slice(0, 5)}
            heading='System Users'
          >
            {datum => (
              <Box>
                <Anchor href={`/user/${datum.id}/edit`} label={<Box direction='row' justify='between'><Text>{datum.firstName || 'n/a'} {datum.lastName}</Text><FontAwesome color='brand' icon={['fas', 'angle-right']} /></Box>} />
              </Box>
            )}
          </ListBox>
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard
