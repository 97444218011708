import React, { useState } from 'react'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import CheckBoxGroup from '../../components/simple/input/CheckBoxGroup'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import Text from '../../components/simple/text/Text.js'

function ReportSend (props) {
  const [loading, setLoading] = useState(false)

  //   const [actionButton, setActionButton] = useState()

  const [questions, setQuestions] = useState(props.questions)

//   const [toSend, setToSend] = useState([])

  const submitForm = () => {
    // console.log('Send to ', toSend)
    // props.submitForm(false)
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <H2 margin={{ vertical: 'none' }}>Send Report</H2>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {props.contacts
          ? (
            <Form
              //   validate='blur'
            //   onChange={nextValue => {
            //     props.setNotifications(nextValue)
            //   }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={props.behaviourPlanValues}
            >
              <Grid
                rows={['auto', 'auto', 'auto', 'auto']}
                columns={['auto', 'auto', 'auto', 'auto']}
                gap='small'
                areas={[
                  { name: 'main', start: [0, 0], end: [1, 1] }
                ]}
              >
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  required
                >
                  <CheckBoxGroup
                    labelKey='display'
                    name={questions[0].key}
                    onChange={event => {
                    //   setToSend(event.value)
                      props.setNotifications(event.value)
                      console.log('Group1: ', event.value)
                    }}
                    options={props.contacts}
                    valueKey='email'
                  />
                </FormField>
              </Grid>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default ReportSend
