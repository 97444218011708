import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

// Grommet
import { ResponsiveContext } from 'grommet'

import LocalStorageService from '../services/LocalStorageService'
import OrganisationTagService from '../services/OrganisationTagService'
import OrganisationService from '../services/OrganisationService'
import NotificationService from '../services/NotificationService'
import TermsService from '../services/TermsService'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import CheckBox from '../components/simple/input/CheckBox'
import DataTable from '../components/simple/dataTable/DataTable.js'
import Divider from '../components/simple/divider/Divider'
import FontAwesome from '../components/compound/fontAwesome/FontAwesome'
import Form from '../components/simple/form/Form.js'
import FormField from '../components/simple/formField/FormField.js'
import Grid from '../components/simple/grid/Grid'
import Select from '../components/simple/input/Select'
import Text from '../components/simple/text/Text'
import TextInput from '../components/simple/input/TextInput'

// Shared Components
import BreadcrumbBar from './shared/BreadcrumbBar'

import constants from '../utils/constants'

function ServiceSetup () {
  const history = useHistory()

  const [behaviourLeads, setBehaviourLead] = useState([])
  const [safeguardingLeads, setSafeguardingLeads] = useState([])
  const [behaviourLeadAddButton, setBehaviourLeadAddButton] = useState(false)

  const [deescalationTechniques, setDeescalationTechnique] = useState([])
  const [deescalationAddButton, setDeescalationAddButton] = useState(false)

  const [physicalTechniques, setPhysicalTechnique] = useState([])
  const [physicalAddButton, setPhysicalAddButton] = useState(false)

  // Has the Service got terms ?
  const [serviceHasTerms, setServiceHasTerms] = useState(false)
  const [wondeIntegration, setWondeIntegration] = useState(false)

  // What gets rendered
  const [deescalationTechniquesDisplay, setDeescalationTechniquesDisplay] = useState()

  const allAcademicYearStartDays = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
  const allAcademicYearStartMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  // const allAcademicYears = ['Year -10', 'Year -9', 'Year -8', 'Year -7', 'Year -6', 'Year -5', 'Year -4', 'Year -3', 'Year -2', 'Year -1', 'Year 0', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6', 'Year 7', 'Year 8', 'Year 9', 'Year 10', 'Year 11', 'Year 12', 'Year 13', 'Year 14', 'Year 15', 'Year 16', 'Year 17', 'Year 18', 'Year 19', 'Year 20']
  const allAcademicYears = constants.academicYears
  // const [selectedAcademicYear, setSelectedAcademicYear] = useState([])

  // const onRemoveAcademicYear = year => {
  //   const yearIndex = allAcademicYears.indexOf(year)
  //   setSelectedAcademicYear(
  //     selectedAcademicYear.filter(selectedAcademicYear => selectedAcademicYear !== yearIndex)
  //   )
  // }

  // const renderAcademicYear = year => (
  //   <Button
  //     key={`year_tag_${year}`}
  //     href='#'
  //     onClick={event => {
  //       event.preventDefault()
  //       event.stopPropagation()
  //       onRemoveAcademicYear(year)
  //     }}
  //     onFocus={event => event.stopPropagation()}
  //   >
  //     <Box
  //       align='center'
  //       direction='row'
  //       gap='xsmall'
  //       pad={{ vertical: 'xsmall', horizontal: 'small' }}
  //       margin='xsmall'
  //       background='accent-1'
  //       round='large'
  //     >
  //       <Text size='small' color='white'>
  //         {year}
  //       </Text>
  //       <Box background='white' round='full' margin={{ left: 'xsmall' }}>
  //         <FormClose
  //           color='accent-1'
  //           size='small'
  //           style={{ width: '12px', height: '12px' }}
  //         />
  //       </Box>
  //     </Box>
  //   </Button>
  // )

  // const renderOption = (option, state) => (
  //   <Box pad='small' background={state.active ? 'active' : undefined}>
  //     {option}
  //   </Box>
  // )

  const apiToken = LocalStorageService.get('apiToken')
  const params = useParams()
  const orgId = params.orgid

  // Initialize userDetails otherwise we get error
  // A component is changing an uncontrolled input of type text to be controlled
  const [serviceDetails, setServiceDetails] = useState({
    serviceUserTerm: '',
    serviceUserTermPlural: '',
    serviceTerm: '',
    serviceTermPlural: '',
    academicYearStartDay: '',
    academicYearStartMonth: '',
    enabledAcademicYears: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    safeguardingFirstName: '',
    safeguardingLastName: '',
    safeguardingPhone: '',
    safeguardingEmail: '',
    deescalationTechnique: '',
    physicalTechnique: ''
  })

  // Remove a technique from the list
  const removeTechnique = (type, index) => {
    if (type === 'deescalation') {
      const newDeescalationTechniques = deescalationTechniques.slice(0, index).concat(deescalationTechniques.slice(index + 1, deescalationTechniques.length))
      // const newDeescalationTechniques = deescalationTechniques
      // newDeescalationTechniques.splice(index, 1)
      setDeescalationTechnique(newDeescalationTechniques)
    } else {
      const newPhysicalTechniques = physicalTechniques.slice(0, index).concat(physicalTechniques.slice(index + 1, physicalTechniques.length))
      setPhysicalTechnique(newPhysicalTechniques)
    }
  }

  // Submit form values
  // ToDo: Add error checking and loading spinner
  const submitForm = async (values) => {
    console.log('submitForm ', values)

    const tagsToSubmit = []

    // Set up tags for Service Defaults
    const serviceDefaultsToSubmit = ['serviceUserTerm', 'serviceUserTermPlural', 'serviceTerm', 'serviceTermPlural', 'academicYearStartDay', 'academicYearStartMonth', 'enabledAcademicYears']

    let termValue

    // If field being saved is a term tag AND service has no terms
    // then set to null to clear value
    const termTags = ['academicYearStartDay', 'academicYearStartMonth', 'enabledAcademicYears']

    serviceDefaultsToSubmit.forEach(field => {
      if (!serviceHasTerms && termTags.includes(field)) {
        termValue = null
      } else {
        if (field === 'enabledAcademicYears') {
          termValue = JSON.stringify(values[field])
        } else {
          termValue = values[field]
        }
      }

      tagsToSubmit.push({
        organisation: orgId,
        key: field,
        type: 'besmart',
        // value: field === 'enabledAcademicYears' ? JSON.stringify(values[field]) : values[field]
        value: termValue
      })
    })

    // And Behaviour Leads if there are any
    if (behaviourLeads.length > 0) {
      var leads = []

      behaviourLeads.forEach(lead => {
        leads.push(lead)
      })

      tagsToSubmit.push({
        organisation: orgId,
        key: 'behaviourLeads',
        type: 'besmart',
        value: JSON.stringify(leads)
      })
    }

    // And Safeguarding Leads if there are any
    if (safeguardingLeads.length > 0) {
      var sleads = []

      safeguardingLeads.forEach(lead => {
        sleads.push(lead)
      })

      tagsToSubmit.push({
        organisation: orgId,
        key: 'safeguardingLeads',
        type: 'besmart',
        value: JSON.stringify(sleads)
      })
    }

    // And Deescalation Techniques if there are any
    if (deescalationTechniques.length > 0) {
      const techniques = []

      deescalationTechniques.forEach(technique => {
        techniques.push(technique)
      })

      tagsToSubmit.push({
        organisation: orgId,
        key: 'deescalationTechniques',
        type: 'besmart',
        value: JSON.stringify(techniques)
      })
    }

    // And Physical Techniques if there are any
    if (physicalTechniques.length > 0) {
      const techniques = []

      physicalTechniques.forEach(technique => {
        techniques.push(technique)
      })

      tagsToSubmit.push({
        organisation: orgId,
        key: 'physicalTechniques',
        type: 'besmart',
        value: JSON.stringify(techniques)
      })
    }

    const params = {
      orgId: orgId
    }

    await OrganisationTagService.set(apiToken, params, tagsToSubmit)
    await OrganisationService.updateOrganisation(apiToken, {
      wondeSync: wondeIntegration
    }, orgId)

    // refresh and set the terms
    const matchedTerms = await TermsService.getCommonTerms()
    LocalStorageService.set('terms', matchedTerms)

    NotificationService.success('Your changes have been saved')
    // force the screen to refresh to ensure the service terms update

    // set a timeout to display a success message
    setTimeout(() => { window.location.reload() }, 1500)
    // window.location.reload()
  }

  const addBehaviourLead = () => {
    // Don't save if all fields empty
    if (serviceDetails.firstName !== '' || serviceDetails.lastName !== '' || serviceDetails.phone !== '' || serviceDetails.email !== '') {
      const lead = {
        firstName: serviceDetails.firstName,
        lastName: serviceDetails.lastName,
        phone: serviceDetails.phone,
        email: serviceDetails.email
      }
      setBehaviourLead(behaviourLeads => [...behaviourLeads, lead])

      setServiceDetails(serviceDetails => ({
        ...serviceDetails,
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      }))
      // setBehaviourLeadAddButton(false)
    }
  }

  const removeBehaviourLead = (data) => {
    const newLeads = behaviourLeads.filter((item) => item.email !== data.email)

    setBehaviourLead(newLeads)
  }

  const editBehaviourLead = (data) => {
    setServiceDetails(serviceDetails => ({
      ...serviceDetails,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email
    }))

    removeBehaviourLead(data)
  }

  const addSafeguardingLead = () => {
    // Don't save if all fields empty
    if (serviceDetails.safeguardingFirstName !== '' || serviceDetails.safeguardingLastName !== '' || serviceDetails.safeguardingPhone !== '' || serviceDetails.safeguardingmail !== '') {
      const lead = {
        firstName: serviceDetails.safeguardingFirstName,
        lastName: serviceDetails.safeguardingLastName,
        phone: serviceDetails.safeguardingPhone,
        email: serviceDetails.safeguardingEmail
      }
      setSafeguardingLeads(safeguardingLeads => [...safeguardingLeads, lead])

      setServiceDetails(serviceDetails => ({
        ...serviceDetails,
        safeguardingFirstName: '',
        safeguardingLastName: '',
        safeguardingPhone: '',
        safeguardingEmail: ''
      }))
      // setSafeguardingLeadAddButton(false)
    }
  }

  const removeSafeguardingLead = (data) => {
    const newLeads = safeguardingLeads.filter((item) => item.email !== data.email)

    setSafeguardingLeads(newLeads)
  }

  const editSafeguardingLead = (data) => {
    setServiceDetails(serviceDetails => ({
      ...serviceDetails,
      safeguardingFirstName: data.firstName,
      safeguardingLastName: data.lastName,
      safeguardingPhone: data.phone,
      safeguardingEmail: data.email
    }))

    removeSafeguardingLead(data)
  }

  const deescalationTechniqueSelect = (technique) => {
    setDeescalationTechnique(
      deescalationTechniques.map(item =>
        item.technique === technique
          ? { ...item, selected: !item.selected }
          : item
      ))
  }

  const addDeescalationTechnique = () => {
    const techniqueExists = deescalationTechniques.find((item, index) => {
      return (item.technique === serviceDetails.deescalationTechnique)
    })

    if (techniqueExists) {
      NotificationService.error('Deescalation technique already exists, please add a different one')
    } else {
      // Add technique and set to selected
      const technique = {
        technique: serviceDetails.deescalationTechnique,
        selected: true
      }
      setDeescalationTechnique(deescalationTechniques => [...deescalationTechniques, technique])

      // Clear input
      setServiceDetails(serviceDetails => ({
        ...serviceDetails,
        deescalationTechnique: ''
      }))

      // Disable Add button
      setDeescalationAddButton(false)
    }
  }

  const physicalTechniqueSelect = (technique) => {
    setPhysicalTechnique(
      physicalTechniques.map(item =>
        item.technique === technique
          ? { ...item, selected: !item.selected }
          : item
      ))
  }

  const addPhysicalTechnique = () => {
    const techniqueExists = physicalTechniques.find((item, index) => {
      return (item.technique === serviceDetails.physicalTechnique)
    })

    if (techniqueExists) {
      NotificationService.error('Physical technique already exists, please add a different one')
    } else {
      // Add technique and set to selected
      const technique = {
        technique: serviceDetails.physicalTechnique,
        selected: true
      }
      setPhysicalTechnique(physicalTechniques => [...physicalTechniques, technique])

      // Clear input
      setServiceDetails(serviceDetails => ({
        ...serviceDetails,
        physicalTechnique: ''
      }))

      // Disable Add button
      setPhysicalAddButton(false)
    }
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    let unmounted = false

    const getServiceSetup = async () => {
      const params = {
        fields: 'type,key,value',
        limit: 100,
        orgId: orgId
      }

      const where = {
        type: 'besmart'
      }

      const organisation = (await OrganisationService.getOrganisation(apiToken,{
        fields: 'id,wondeSync'
      }, orgId)).data[0]
      setWondeIntegration(!!organisation.wondeSync)

      const organisationTags = await OrganisationTagService.get(apiToken, params, where)

      // ToDo: Add error checking and loading spinner
      if (!unmounted) {
        console.log(organisationTags)

        const tags = {}
        let bLeads = []
        let sLeads = []
        let deescalationTechniques = [
          {
            technique: 'Empathy',
            selected: true
          },
          {
            technique: 'Verbal advice and support',
            selected: true
          },
          {
            technique: 'Giving Space',
            selected: true
          },
          {
            technique: 'Reassurance',
            selected: true
          },
          {
            technique: 'Scripts',
            selected: true
          },
          {
            technique: 'Negotiation',
            selected: true
          },
          {
            technique: 'Positive Choices',
            selected: true
          },
          {
            technique: 'Humour',
            selected: true
          },
          {
            technique: 'Logical Consequences',
            selected: true
          },
          {
            technique: 'Tactical Ignoring',
            selected: true
          },
          {
            technique: 'Take up time',
            selected: true
          },
          {
            technique: 'Time-out',
            selected: true
          },
          {
            technique: 'Supportive touch',
            selected: true
          },
          {
            technique: 'Transfer staff',
            selected: true
          },
        ]
        let physicalTechniques = []

        if (organisationTags) {
          organisationTags.forEach(tag => {
            switch (tag.key) {
              case 'serviceUserTerm':
                tags.serviceUserTerm = tag.value
                break
              case 'serviceUserTermPlural':
                tags.serviceUserTermPlural = tag.value
                break
              case 'serviceTerm':
                tags.serviceTerm = tag.value
                break
              case 'serviceTermPlural':
                tags.serviceTermPlural = tag.value
                break
              case 'academicYearStartDay':
                tags.academicYearStartDay = tag.value
                break
              case 'academicYearStartMonth':
                tags.academicYearStartMonth = tag.value
                break
              case 'enabledAcademicYears':
                try {
                  tags.enabledAcademicYears = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
              case 'behaviourLeads':
                try {
                  bLeads = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
              case 'safeguardingLeads':
                try {
                  sLeads = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
              case 'deescalationTechniques':
                try {
                  deescalationTechniques = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
              case 'physicalTechniques':
                try {
                  physicalTechniques = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
            }
          })
        }

        setServiceDetails(serviceDetails => ({
          ...serviceDetails,
          serviceUserTerm: tags.serviceUserTerm,
          serviceUserTermPlural: tags.serviceUserTermPlural,
          serviceTerm: tags.serviceTerm,
          serviceTermPlural: tags.serviceTermPlural,
          academicYearStartDay: tags.academicYearStartDay,
          academicYearStartMonth: tags.academicYearStartMonth,
          enabledAcademicYears: tags.enabledAcademicYears
        }))
        setServiceHasTerms(tags.academicYearStartDay || false)
        setBehaviourLead(bLeads)
        setSafeguardingLeads(sLeads)
        setDeescalationTechnique(deescalationTechniques)
        setPhysicalTechnique(physicalTechniques)
      }
    }

    getServiceSetup()

    return () => { unmounted = true }
  }, [])

  // Behaviour Leads have been altered
  // so rebuild the display list
  const [mappedBehaviourLeads, setMappedBehaviourLeads] = useState([])
  const [behaviourLeadColumns, setBehaviourLeadDataColumns] = useState()

  useEffect(() => {
    setBehaviourLeadDataColumns([
      {
        property: 'firstName',
        header: <Text pad='none' margin='none'>First Name</Text>
      },
      {
        property: 'lastName',
        header: <Text>Last Name</Text>
      },
      {
        property: 'phone',
        header: <Text>Phone</Text>
      },
      {
        property: 'email',
        header: <Text>Email</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeBehaviourLead(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editBehaviourLead(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedBehaviourLeads = behaviourLeads.map((lead, index) => ({
      firstName: lead.firstName,
      lastName: lead.lastName,
      phone: lead.phone,
      email: lead.email
    }))

    setMappedBehaviourLeads(mappedBehaviourLeads)
  }, [behaviourLeads])

  // Safeguarding Leads have been altered
  // so rebuild the display list
  const [mappedSafeguardingLeads, setMappedSafeguardingLeads] = useState([])
  const [safeguardingLeadColumns, setSafeguardingLeadDataColumns] = useState()

  useEffect(() => {
    setSafeguardingLeadDataColumns([
      {
        property: 'firstName',
        header: <Text pad='none' margin='none'>First Name</Text>
      },
      {
        property: 'lastName',
        header: <Text>Last Name</Text>
      },
      {
        property: 'phone',
        header: <Text>Phone</Text>
      },
      {
        property: 'email',
        header: <Text>Email</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeSafeguardingLead(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editSafeguardingLead(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedSafeguardingLeads = safeguardingLeads.map((lead, index) => ({
      firstName: lead.firstName,
      lastName: lead.lastName,
      phone: lead.phone,
      email: lead.email
    }))

    setMappedSafeguardingLeads(mappedSafeguardingLeads)
  }, [safeguardingLeads])

  return (
    <Box>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Service Setup
      </BreadcrumbBar>

      {/*
            <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
<Box direction='column' pad={{ horizontal: 'medium' }}>
        <H1 margin={{ top: 'small', bottom: 'none' }}>Service Setup</H1>
        <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
      </Box>
            </Box>
*/}

      <Box background='white' direction='column' gap='small' round='small' flex='grow' margin={{ bottom: 'medium' }}>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Text margin={{ top: 'xsmall' }} size='large'>Wonde Integration</Text>
          <CheckBox checked={wondeIntegration} label={<Text>Enable MIS Integration</Text>} onChange={() => setWondeIntegration(!wondeIntegration)} />

        </Box>
      </Box>

      {!serviceDetails?.serviceTerm && (
        <Box background='white' direction='column' gap='small' round='small' flex='grow' margin={{ bottom: 'medium' }}>
          <Box
            gap='small'
            margin={{ horizontal: 'small' }}
            pad='small'
            round='small'
          >
            <Text margin={{ top: 'xsmall' }} size='large'>Setup to Continue</Text>
            <Text margin={{ bottom: 'xsmall' }}>Please complete the following information before using BehaviourSmart.</Text>

          </Box>
        </Box>
      )}

      <Box background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              onChange={nextValue => {
                // Enable Behaviour Lead Add button if all fields have data
                // setBehaviourLeadAddButton(nextValue.firstName !== '' && nextValue.lastName !== '' && nextValue.phone !== '' && nextValue.email !== '')

                // Enable Deescalation Technique Add button if field has data
                setDeescalationAddButton(nextValue.deescalationTechnique)
                // Enable Physical Technique Add button if field has data
                setPhysicalAddButton(nextValue.physicalTechnique)
                setServiceDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={serviceDetails}
            >
              <Box fill direction='row' gap='small'>
                <Text margin={{ top: 'xsmall' }} size='large'>What type of service are you? Eg. School, Children’s Home, Health Care Setting.</Text>
                <Button
                  label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />}
                  plain
                  tip={{
                    content: (
                      <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                        <Text>What type of service are you? Eg. School, Children’s Home, Health Care Setting.</Text>
                      </Box>
                    )
                  }}
                />
              </Box>

                <Box direction='row-responsive' gap='medium'>

                  <FormField
                    label='Service (singular)'
                    name='serviceTerm'
                    required
                  >
                    <TextInput
                      name='serviceTerm'
                      placeholder='service'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Service (plural)'
                    name='serviceTermPlural'
                    required
                  >
                    <TextInput
                      name='serviceTermPlural'
                      placeholder='services'
                      type='text'
                    />
                  </FormField>

                </Box>

              <Divider color='primary' margin={{ vertical: 'small' }} />

                <Box fill direction='row' gap='small'>
                <Text margin={{ top: 'xsmall' }} size='large'>How do you refer to the individuals who use your service? Eg. Students, Pupils, Young People, Clients.</Text>
                <Button
                  label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />}
                  plain
                  tip={{
                    content: (
                      <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                        <Text>How do you refer to the individuals who use your service? Eg. Students, Pupils, Young People, Clients.</Text>
                      </Box>
                    )
                  }}
                />
              </Box>

              <Box direction='column'>
                <Box direction='row-responsive' gap='medium'>

                <FormField
                    label='Service User (singular)'
                    name='serviceUserTerm'
                    required
                  >
                    <TextInput
                      name='serviceUserTerm'
                      placeholder='service user'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Service Users (plural)'
                    name='serviceUserTermPlural'
                    required
                  >
                    <TextInput
                      name='serviceUserTermPlural'
                      placeholder='service users'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Divider color='primary' margin={{ vertical: 'small' }} />
                <Text margin={{ top: 'xsmall' }} size='large'>Term dates and year groups covered</Text>

                <CheckBox checked={serviceHasTerms} label={<Text>My service has term dates</Text>} onChange={() => setServiceHasTerms(!serviceHasTerms)} />

                {serviceHasTerms &&
                  <>
                    <Box direction='row-responsive' gap='medium'>
                      <FormField
                        label='Start Day'
                        name='academicYearStartDay'
                        required
                      >
                        <Select
                          name='academicYearStartDay'
                          options={allAcademicYearStartDays}
                        />
                      </FormField>

                      <FormField
                        label='Start Month'
                        name='academicYearStartMonth'
                        required
                      >
                        <Select
                          name='academicYearStartMonth'
                          options={allAcademicYearStartMonths}
                        />
                      </FormField>

                      <FormField
                        label='Years Covered'
                        name='enabledAcademicYears'
                        required
                      >
                        <Select
                          closeOnChange={false}
                          multiple
                          name='enabledAcademicYears'
                          options={allAcademicYears}
                        />
                      </FormField>
                    </Box>
                  </>}
              </Box>

              <Divider color='primary' margin={{ vertical: 'small' }} />

              <Box fill direction='row' gap='small' margin={{ bottom: 'none' }}>
                <Text margin={{ top: 'xsmall', bottom: 'none' }} size='large'>Who would you like to receive a copy of the Incident Reports, Logs and Plans when they have been completed?</Text><Button
                  label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />}
                  plain
                  tip={{
                    content: (
                      <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                        <Text>Who would you like to receive a copy of the Incident Reports, Logs and Plans when they have been completed?</Text>
                      </Box>
                    )
                  }}
                />
              </Box>

              <Box direction='row-responsive' gap='medium'>
                <ResponsiveContext.Consumer>
                  {responsive => (
                    <DataTable
                      columns={responsive === 'small' ? behaviourLeadColumns?.filter(el => el.property !== 'phone' && el.property !== 'email') : behaviourLeadColumns}
                      data={mappedBehaviourLeads}
                      fill='horizontal'
                      size='large'
                    />)}
                </ResponsiveContext.Consumer>
              </Box>
              <Box direction='row-responsive' gap='medium'>
                <FormField
                  label='First Name'
                  name='firstName'
                >
                  <TextInput
                    name='firstName'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Last Name'
                  name='lastName'
                >
                  <TextInput
                    name='lastName'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Phone Number'
                  name='phone'
                >
                  <TextInput
                    name='phone'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Email'
                  name='email'
                >
                  <TextInput
                    name='email'
                    type='email'
                  />
                </FormField>

                {/* <Button disabled={!behaviourLeadAddButton} label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /></Text>} color='primary' onClick={() => addLead()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} /> */}
                <Button label={<Text>Add</Text>} color='primary' onClick={() => addBehaviourLead()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ vertical: 'small' }} />

              <Box fill direction='row' gap='small' margin={{ bottom: 'none' }}>
                <Text margin={{ top: 'xsmall' }} size='large'>Analytics Page and Systems Users</Text>
                <Button
                  label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />}
                  plain
                  tip={{
                    content: (
                      <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                        <Text>Who would you like to have access to the Analytics and System Users page?</Text>
                      </Box>
                    )
                  }}
                />
              </Box>
              <Box fill direction='row' gap='small' margin={{ top: 'none', bottom: 'small' }}>
                <Text size='small'>Who would you like to have access to the Analytics and System Users page?</Text>
              </Box>

              <Box direction='row-responsive' gap='medium'>
                <ResponsiveContext.Consumer>
                  {responsive => (
                    <DataTable
                      columns={responsive === 'small' ? safeguardingLeadColumns?.filter(el => el.property !== 'phone' && el.property !== 'email') : safeguardingLeadColumns}
                      data={mappedSafeguardingLeads}
                      fill='horizontal'
                      size='large'
                    />)}
                </ResponsiveContext.Consumer>
              </Box>
              <Box direction='row-responsive' gap='medium'>
                <FormField
                  label='First Name'
                  name='safeguardingFirstName'
                >
                  <TextInput
                    name='safeguardingFirstName'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Last Name'
                  name='safeguardingLastName'
                >
                  <TextInput
                    name='safeguardingLastName'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Phone Number'
                  name='safeguardingPhone'
                >
                  <TextInput
                    name='safeguardingPhone'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Email'
                  name='safeguardingEmail'
                >
                  <TextInput
                    name='safeguardingEmail'
                    type='email'
                  />
                </FormField>

                <Button label={<Text>Add</Text>} color='primary' onClick={() => addSafeguardingLead()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ vertical: 'small' }} />

              <Box fill direction='row' gap='small'>
                <Text margin={{ top: 'xsmall' }} size='large'>Behaviour Management Techniques</Text>
                <Button
                  label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />}
                  plain
                  tip={{
                    content: (
                      <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                        <Text>The techniques entered here will be included in your bespoke incident report. These can be changed at any time.</Text>
                      </Box>
                    )
                  }}
                />
              </Box>

              <Box direction='row-responsive' gap='medium' pad={{ top: 'small' }}>
                <ResponsiveContext.Consumer>
                  {responsive => (
                    <Box direction='column' width={responsive === 'small' ? '100%' : '50%'}>
                      <Box fill direction='row' gap='small'>
                        <Text size='small'>What are the most common De-escalation Techniques used in your service ?</Text>
                        <Button
                          alignSelf='start'
                          label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />}
                          plain
                          tip={{
                            content: (
                              <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                                <Text>Please list the most common de-escalation used within the service setting. EG Empathy</Text>
                                <Text>Verbal advice and support</Text>
                                <Text>Giving space</Text>
                                <Text>Reassurance</Text>
                                <Text>Scripts</Text>
                                <Text>Negotiation</Text>
                                <Text>Positive Choices</Text>
                                <Text>Humour</Text>
                                <Text>Logical Consequences</Text>
                                <Text>Tactical ignoring</Text>
                                <Text>Take up time</Text>
                                <Text>Time-out</Text>
                                <Text>Supportive touch</Text>
                                <Text>Transfer staff</Text>
                                <Text>Success reminded</Text>
                                <Text>Listening</Text>
                                <Text>Acknowledgement</Text>
                                <Text>Removing audience</Text>
                              </Box>)
                          }}
                        />
                      </Box>
                      {deescalationTechniques.length > 0 &&
                        <Grid columns={{ count: 2, size: 'auto' }}>
                          {deescalationTechniques.map((technique, index, techniques) => {
                            return (
                              <Box direction='row' key={techniques[index].technique} margin={{ vertical: 'xsmall' }}>
                                <CheckBox checked={deescalationTechniques[index].selected} label={<Text size='small'>{techniques[index].technique}</Text>} onChange={() => deescalationTechniqueSelect(techniques[index].technique)} />
                                <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} size='xs' /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => removeTechnique('deescalation', index)} plain target='_self' />
                              </Box>)
                          })}
                        </Grid>}
                      <Box direction='row' gap='small'>
                        <FormField
                          name='deescalationTechnique'
                        >
                          <TextInput
                            name='deescalationTechnique'
                            placeholder='new technique'
                            type='text'
                          />
                        </FormField>

                        <Button label={<Text><FontAwesome icon={['fal', 'plus-circle']} /> Add</Text>} disabled={!deescalationAddButton} color='primary' onClick={() => addDeescalationTechnique()} style={{ height: '35px' }} />
                      </Box>
                    </Box>)}
                </ResponsiveContext.Consumer>

                <ResponsiveContext.Consumer>
                  {responsive => (
                    <Box direction='column' width={responsive === 'small' ? '100%' : '50%'}>
                      <Box fill direction='row' gap='small'>
                        <Text size='small'>Name the Physical Interventions used in your service (If applicable)</Text>
                        <Button
                          alignSelf='start'
                          label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />}
                          plain
                          tip={{
                            content: (
                              <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                                <Text>Please list the Physical techniques used within the service setting.</Text>
                                <Text>Figure of 4</Text>
                                <Text>Single elbow</Text>
                                <Text>Break way techniques</Text>
                                <Text>Team Control</Text>
                                <Text>Guiding hold</Text>
                                <Text>Double Embrace</Text>
                                <Text>The name of techniques used and trained within your service will be available from you training provider or course workbook.</Text>
                              </Box>)
                          }}
                        />
                      </Box>
                      {physicalTechniques.length > 0 &&

                        <Grid columns={{ count: 2, size: 'auto' }}>
                          {physicalTechniques.map((technique, index, techniques) => {
                            return (
                              <Box direction='row' key={index} margin={{ vertical: 'xsmall' }}>
                                <CheckBox checked={physicalTechniques[index].selected} label={<Text size='small'>{techniques[index].technique}</Text>} onChange={() => physicalTechniqueSelect(techniques[index].technique)} />
                                <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} size='xs' /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => removeTechnique('physical', index)} plain target='_self' />
                              </Box>)
                          })}
                        </Grid>}
                      <Box direction='row' gap='small'>
                        <FormField
                          name='physicalTechnique'
                        >
                          <TextInput
                            name='physicalTechnique'
                            placeholder='new technique'
                            type='text'
                          />
                        </FormField>

                        <Button label={<Text><FontAwesome icon={['fal', 'plus-circle']} /> Add</Text>} disabled={!physicalAddButton} onClick={() => addPhysicalTechnique()} color='primary' style={{ height: '35px' }} />
                      </Box>
                    </Box>)}
                </ResponsiveContext.Consumer>
              </Box>

              {/* <Divider color='primary' margin={{ vertical: 'medium' }} />
              <Text margin={{ top: 'xsmall', bottom: 'medium' }} size='large'>Subscription Management</Text>

              <Box direction='row' gap='medium' pad={{ top: 'small' }}>
                <Button label='Subscription Management' onClick={() => history.push('/service/subscription')} />
              </Box> */}

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ vertical: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ServiceSetup
