import ApiService from './ApiService'

class UserGroupService {
  createGroup = async (apiToken, options) => {
    var url = '/api/usergroup'

    var params = {}

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:createGroup ', result)
    return result
  }

  //   getUser = async (apiToken, userId) => {
  //     var url = `/api/user/${userId}`

  //     var params = {
  //       fields: 'id,firstName,lastName,email,userName,ethnicity,gender,createdAt',
  //       limit: 1
  //     }

  //     try {
  //       var user = await ApiService.get(apiToken, url, params)
  //     } catch (err) {
  //       console.log(err)
  //     }
  //     console.log('UserService:getUser ', user)
  //     return user
  //   }

  getGroups = async (apiToken, params, where) => {
    var url = '/api/usergroup'

    if (where) {
      params.where = where
    }

    try {
      var groups = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:getGroups ', groups)
    return groups
  }

  //   updateUser = async (apiToken, data) => {
  //     const userId = data.id
  //     var url = `/api/user/${userId}`

  //     var params = {}

  //     var options = {
  //       data: {
  //         email: data.email,
  //         firstName: data.firstName,
  //         lastName: data.lastName
  //       }
  //     }

  //     try {
  //       var result = await ApiService.put(apiToken, url, params, options)
  //     } catch (err) {
  //       console.log(err)
  //     }
  //     console.log('UserService:updateUser ', result)
  //     return result
  //   }

  //   deleteUser = async (apiToken, data) => {
  //     const userId = data.id
  //     var url = `/api/user/${userId}`

  //     var params = {}

  //     var options = {}
  //     try {
  //       var result = await ApiService.sendDelete(apiToken, url, params, options)
  //     } catch (err) {
  //       console.log(err)
  //     }
  //     console.log('UserService:deleteUser ', result)
  //     return result
  //   }
}
export default new UserGroupService()
