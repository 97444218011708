import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LocalStorageService from '../services/LocalStorageService'
import ContentService from '../services/ContentService'

// Grommet
import { Add, FormEdit, FormTrash, License, Upload } from 'grommet-icons'

// Appt Components
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import ContentMenu from '../components/compound/contentMenu/ContentMenu'
// import DataTable from '../components/simple/dataTable/DataTable.js'
import H2 from '../components/simple/heading/H2'
import Text from '../components/simple/text/Text'

function ContentList(props) {
  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const [parentValues, setParentValues] = useState([])
  const [dataValues, setDataValues] = useState([])

  const mountedRef = useRef()

  const contentIcon = {
    audio: 'music',
    article: 'newspaper',
    video: 'video',
    image: 'file',
    pdf: 'file-pdf',
    category: 'folder-open'
  }

  const contentIconColor = {
    audio: '#e3c754',
    article: '#4eaad3',
    video: '#cb6588',
    image: 'red',
    pdf: 'green',
    category: '#c94752'
  }

  // NOTE: getUser and clearSession currently need to be called on every screen
  // this is definitely a violation of DRY and needs to be refactor

  // check if the user is logged in, if so restored the session
  // const getUser = () => {
  //   // check local storage
  //   var apiToken = LocalStorageService.get('apiToken')
  //   if (apiToken) {
  //     setApiToken(apiToken)
  //   } else {
  //     clearSession()
  //   }
  // }

  // // clear the user's session if they log out
  // const clearSession = () => {
  //   // remove all localstorage
  //   LocalStorageService.clear()

  //   // remove the session data
  //   deleteApiToken()
  //   deleteCurrentUser()
  //   deleteCurrentOrgs()
  //   history.push('/')
  // }

  // Sort the Content
  const sortContent = (content) => {
    console.log('sort ', content)
    const headings = parentValues
    console.log('headings ', headings)
    console.log('dataValues ', parentValues)
    const mappedOutput = []
    let singleHeadingOutput = {}
    let singleHeadingContent = []

    for (const heading of headings) {
      singleHeadingOutput = {}
      singleHeadingContent = []
      singleHeadingOutput.header = { borderColor: 'red', icon: 'folder-open', iconColor: 'red', title: heading.title }
      for (const item of content.data) {
        if (item.parent === heading.id) {
          singleHeadingContent.push({
            id: item.id,
            icon: contentIcon[item.type],
            iconColor: contentIconColor[item.type],
            link: item.id,
            selectMenuItem: editContent,
            title: item.title + ' ' + item.type,
            parent: item.parent,
            type: item.type
          })
        }
      }
      // singleHeadingOutput.content = singleHeadingContent.map((e) => { return e })
      // singleHeadingOutput.content = [...singleHeadingContent]
      singleHeadingOutput.content = singleHeadingContent.slice(0)
      mappedOutput.push(singleHeadingOutput)
    }

    setDataValues(mappedOutput)
  }

  const editContent = (contentId) => {
    history.push('content/' + contentId)
  }

  // Get Content for this User
  const getContent = async (where) => {
    const apiToken = LocalStorageService.get('apiToken')

    console.log('ContentListScreen:getContent ', apiToken)

    if (!where) {
      where = {}
    }
    where.organisation = props.activeOrg.id

    const content = await ContentService.getAllContent(apiToken, where)

    return content
  }

  // Basically componentDidMount
  useEffect(() => {
    // getUser()

    const fetchData = async () => {
      const content = await getContent({ parent: null })

      const mappedContent = content.data.map((data, index) => ({
        id: data.id,
        title: data.title,
        parent: data.parent || ''
      }))

      console.log('about to set state ', mappedContent)
      setParentValues(mappedContent)
      mountedRef.current = true
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchChildData = async () => {
      console.log('set state ', parentValues)

      const childContent = await getContent()

      sortContent(childContent)
    }

    if (mountedRef.current) {
      fetchChildData()
    }
  }, [parentValues])

  // const dataColumns = [
  //   {
  //     property: 'id',
  //     header: <Text>Id</Text>,
  //     primary: true
  //   },
  //   {
  //     property: 'title',
  //     header: <Text>Title</Text>
  //   },
  //   {
  //     property: 'parent',
  //     header: <Text>Parent</Text>
  //   }
  // ]

  return (
    <Box background='white' direction='column' gap='small' round='small'>
      {/* <Box>{apiToken || 'no global Token'}</Box>

      <Button plain onClick={() => { clearSession() }}>
        <Text>Logout</Text>
      </Button> */}

      <Box direction='row-responsive' gap='medium' pad={{ horizontal: 'large', bottom: 'medium' }}>
        <H2>Content Menu</H2>
        {/* Warning message goes here */}
      </Box>

      <Box direction='row-responsive' gap='xlarge' pad={{ horizontal: 'large' }}>
        <Box direction='row-responsive' gap='xxsmall'>
          <Button href='user/new' icon={<Add />} label='Add Content' primary target='_self' />
        </Box>
      </Box>

      <Box direction='row-responsive' gap='medium' pad={{ horizontal: 'large' }}>
        {/* <DataTable
          columns={dataColumns}
          data={dataValues}
        /> */}
        <ContentMenu data={dataValues} />
      </Box>
    </Box>
  )
}

export default ContentList
