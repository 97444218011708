import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationTagService from '../../services/OrganisationTagService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Grommet
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// utils
import { capitalize, removeSpaces } from '../../utils/text'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import Meter from '../../components/simple/meter/Meter'
import Tabs from '../../components/simple/tabs/Tabs.js'
import Text from '../../components/simple/text/Text'

import BehaviourDetails from './IncidentEdit-BehaviourDetails'
import DeescalationTechniques from './IncidentEdit-DeescalationTechniques'
import IncidentReport from './IncidentEdit-IncidentReport'
import MedicalIntervention from './IncidentEdit-MedicalIntervention'
import PhysicalTechniques from './IncidentEdit-PhysicalTechniques'
import PostIncidentLearning from './IncidentEdit-PostIncidentLearning'
import ReportSend from './IncidentEdit-ReportSend'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function IncidentEdit (props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [userValues, setUserValues] = useState([])
  const [studentValues, setStudentValues] = useState([])
  const [incidentValues, setIncidentValues] = useState({
    // Incident Report
    studentName: '',
    dateOfIncident: '',
    timeOfIncident: '',
    location: '',
    completedBy: '',
    staffInvolved: [],
    studentsInvolved: [],
    witnesses: '',
    hurtingSelf: '',
    hurtingOthers: '',
    propertyDamage: '',
    behaviour: '',
    commitOffence: '',
    otherQuestion: '',
    otherResponse: '',
    bestInterestQuestion: '',
    bestInterest: '',
    // Behaviour Details
    lowLevelBehaviour: '',
    lowLevelResponse: '',
    lowLevelStrategy: '',
    mediumLevelBehaviour: '',
    mediumLevelResponse: '',
    mediumLevelStrategy: '',
    highLevelBehaviour: '',
    highLevelResponse: '',
    highLevelStrategy: '',
    // Medical Intervention
    injurySufferedBy: '',
    injury: '',
    treatment: '',
    otherConcerns: '',
    // Deescalation Techniques - these will be overidden
    // with the techniques set up for the organisation
    calmTalking: '',
    listening: '',
    optionsOffered: '',
    socialSpace: '',
    plannedIgnoring: '',
    negotiation: '',
    successReminded: '',
    otherTechniques: '',
    contingentTouch: '',
    otherTechniquesInfo: '',
    // Physical techniques - these will be overidden
    // with the techniques set up for the organisation
    positiveStrategies: '',
    doubleElbow: '',
    caringGuide: '',
    wrap: '',
    figureFour: '',
    singleElbow: '',
    singleElbowSeats: '',
    otherPhysicalTechniques: '',
    friendlyEscort: '',
    seatedWrap: '',
    otherPhysicalTechniquesInfo: '',
    // Post Incident Learning
    recoveryBehaviour: '',
    recoveryResponse: '',
    recoveryStrategy: '',
    studentBehaviour: '',
    studentResponse: '',
    studentStrategy: '',
    likelyTriggers: '',
    adjustments: ''
  })

  const [interventions, setIntervention] = useState([])
  const [notifications, setNotifications] = useState([])

  const [userDetails, setUserDetails] = useState({
    organisation: 0,
    role: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    jobtitle: ''
  })

  const [keyContacts, setKeyContacts] = useState([])

  const [meterValue, setMeterValue] = useState()

  const [responses, setResponses] = useState()

  // This is a lookup to find the questions tabId
  // for the relevant technique as those questions will be overwritten
  const techniqueTabLookup = {
    deescalationTechniques: 3,
    physicalTechniques: 4
  }

  const [questions, setQuestions] = useState([
  // const questions = [
    {
      tabId: 0,
      questions: [
        {
          questionText: `${capitalize(props?.terms?.serviceUser || 'Service User')} Name`,
          key: 'studentName'
        },
        {
          questionText: 'Date and Time of Incident',
          key: 'dateOfIncident',
          responseType: 'date'
        },
        // Time not Used but leave in otherwise will
        // need to renumber everything in IcidentReport
        {
          questionText: 'Time of Incident',
          key: 'timeOfIncident',
          responseType: 'time'
        },
        {
          questionText: 'Location',
          key: 'location'
        },
        {
          questionText: 'Report completed by',
          key: 'completedBy'
        },
        {
          questionText: 'Name of staff involved',
          key: 'staffInvolved'
        },
        {
          questionText: `Other ${props?.terms?.serviceUserPlural || 'service users'} involved`,
          key: 'studentsInvolved'
        },
        {
          questionText: 'Names of witnesses',
          key: 'witnesses'
        },
        {
          questionText: 'Hurting self',
          key: 'hurtingSelf',
          responseType: 'boolean'
        },
        {
          questionText: 'Hurting others',
          key: 'hurtingOthers',
          responseType: 'boolean'
        },
        {
          questionText: 'Committing an offence',
          key: 'commitOffence',
          responseType: 'boolean'
        },
        {
          questionText: 'Damage to property',
          key: 'propertyDamage',
          responseType: 'boolean'
        },
        {
          questionText: 'Behaviour we are trying to support',
          key: 'behaviour',
          responseType: 'boolean'
        },
        {
          questionText: 'Other',
          key: 'otherQuestion',
          responseType: 'boolean'
        },
        {
          questionText: 'Other - Please Elaborate',
          key: 'otherResponse'
        },
        {
          questionText: `Was your action in the best interest of the ${props?.terms?.serviceUser || 'service user'}?`,
          key: 'bestInterestQuestion',
          responseType: 'boolean'
        },
        {
          // NOTE: This is hardcoded on the form field
          // as the parameter wasn't updating when set here
          questionText: `Why was this ${incidentValues.bestInterestQuestion === 'No' ? 'not' : ''} in the best interest of the ${props?.terms?.serviceUser || 'service user'}?`,
          key: 'bestInterest'
        }
      ]
    },
    {
      tabId: 1,
      questions: [
        {
          questionText: `What Low Level Behaviour did the ${props?.terms?.serviceUser || 'Service User'} show?`,
          key: 'lowLevelBehaviour'
        },
        {
          questionText: 'How did you respond?',
          key: 'lowLevelResponse'
        },
        {
          questionText: 'Alternative strategies that could have been used.',
          key: 'lowLevelStrategy'
        },
        {
          questionText: `What Medium Level Behaviour did the ${props?.terms?.serviceUser || 'Service User'} show?`,
          key: 'mediumLevelBehaviour'
        },
        {
          questionText: 'How did you respond?',
          key: 'mediumLevelResponse'
        },
        {
          questionText: 'Alternative strategies that could have been used.',
          key: 'mediumLevelStrategy'
        },
        {
          questionText: `What High Level Behaviour did the ${props?.terms?.serviceUser || 'Service User'} show?`,
          key: 'highLevelBehaviour'
        },
        {
          questionText: 'How did you respond?',
          key: 'highLevelResponse'
        },
        {
          questionText: 'Alternative strategies that could have been used.',
          key: 'highLevelStrategy'
        },
        {
          questionText: 'Brief Description of the incident.',
          key: 'incidentDescription'
        }
      ]
    },
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Breathing monitored',
          key: 'breathingMonitored'
        },
        {
          questionText: 'Duration (in minutes) of physical intervention',
          key: 'physicalInterventionDuration',
          responseType: 'number'
        },
        {
          questionText: 'Number of staff involved',
          key: 'numberStaffInvolved'
        },
        {
          questionText: 'Injury suffered by',
          key: 'injurySufferedBy'
        },
        {
          questionText: 'Injury',
          key: 'injury'
        },
        {
          questionText: 'Treatment',
          key: 'treatment'
        },
        {
          questionText: 'Any other concerns',
          key: 'otherConcerns'
        }
      ]
    },
    {
      tabId: 3,
      questions: [
        {
          questionText: 'C.A.L.M talking',
          key: 'calmTalking'
        },
        {
          questionText: 'Listening',
          key: 'listening'
        },
        {
          questionText: 'Options Offered',
          key: 'optionsOffered'
        },
        {
          questionText: 'Social Space (CALM Body Language)',
          key: 'socialSpace'
        },
        {
          questionText: 'Planned Ignoring',
          key: 'plannedIgnoring'
        },
        {
          questionText: 'Negotiation',
          key: 'negotiation'
        },
        {
          questionText: 'Success Reminded',
          key: 'successReminded'
        },
        {
          questionText: 'Other',
          key: 'otherTechniques'
        },
        {
          questionText: 'Contingent Touch',
          key: 'contingentTouch'
        },
        {
          questionText: 'Other - Please elaborate',
          key: 'otherTechniquesInfo'
        }
      ]
    },
    {
      tabId: 4,
      questions: [
        {
          questionText: 'Positive handling strategies used & effectiveness rating',
          key: 'positiveStrategies'
        },
        {
          questionText: 'Double Elbow',
          key: 'doubleElbow'
        },
        {
          questionText: 'Caring C Guide',
          key: 'caringGuide'
        },
        {
          questionText: 'Wrap',
          key: 'wrap'
        },
        {
          questionText: 'Figure of Four',
          key: 'figureFour'
        },
        {
          questionText: 'Single Elbow',
          key: 'singleElbow'
        },
        {
          questionText: 'Single Elbow in seats',
          key: 'singleElbowSeats'
        },
        {
          questionText: 'Friendly Escort',
          key: 'friendlyEscort'
        },
        {
          questionText: 'Seated Wrap',
          key: 'seatedWrap'
        },
        {
          questionText: 'Other',
          key: 'otherPhysicalTechniques'
        },
        {
          questionText: 'Other - Please elaborate',
          key: 'otherPhysicalTechInfo'
        }
      ]
    },
    {
      tabId: 5,
      questions: [
        {
          questionText: `What Recovery Behaviour did the ${props?.terms?.serviceUser || 'Service User'} show?`,
          key: 'recoveryBehaviour'
        },
        {
          questionText: 'How did you respond?',
          key: 'recoveryResponse'
        },
        {
          questionText: 'Alternative strategies that might be tried next time',
          key: 'recoveryStrategy'
        },
        {
          questionText: `What happened from the ${props?.terms?.serviceUser || 'Service User'}'s point of view?`,
          key: 'studentBehaviour'
        },
        {
          questionText: `How did the ${props?.terms?.serviceUser || 'Service User'} feel?`,
          key: 'studentResponse'
        },
        {
          questionText: `What will the ${props?.terms?.serviceUser || 'Service User'} do differently next time they feel that way?`,
          key: 'studentStrategy'
        },
        {
          questionText: 'Likely triggers',
          key: 'likelyTriggers'
        },
        {
          questionText: `What reasonable adjustments could be made (in the future) to support the ${props?.terms?.serviceUser || 'Service User'}?`,
          key: 'adjustments'
        }
      ]
    },
    {
      tabId: 6,
      questions: [
        {
          questionText: 'Which key contacts should receive a copy of this report?',
          key: 'notify'
        }
      ]
    }
  ])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  const params = useParams()

  const userId = params.userid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    setMeterValue((nextIndex + 1) * 100 / questions.length)
    setTabIndex(nextIndex)
  }

  const tabContent = [
    {
      name: 'Incident Report',
      hideTitle: true,
      content: <IncidentReport incidentValues={incidentValues} setIncidentValues={setIncidentValues} questions={questions[0].questions} setStudentValues={setStudentValues} studentValues={studentValues} userId={userId} userValues={userValues} setUserValues={setUserValues} />
    },
    {
      name: 'Behaviour Details',
      hideTitle: true,
      content: <BehaviourDetails incidentValues={incidentValues} questions={questions[1].questions} setIncidentValues={setIncidentValues} />
    },
    {
      name: 'Medical Intervention',
      hideTitle: true,
      content: <MedicalIntervention incidentValues={incidentValues} interventions={interventions} questions={questions[2].questions} setIncidentValues={setIncidentValues} setIntervention={setIntervention} />
    },
    {
      name: 'Deescalation Techniques',
      hideTitle: true,
      content: <DeescalationTechniques incidentValues={incidentValues} questions={questions[3].questions} setIncidentValues={setIncidentValues} />
    },
    {
      name: 'Physical Techniques',
      hideTitle: true,
      content: <PhysicalTechniques incidentValues={incidentValues} questions={questions[4].questions} setIncidentValues={setIncidentValues} />
    },
    {
      name: 'Post Incident Learning',
      hideTitle: true,
      content: <PostIncidentLearning incidentValues={incidentValues} questions={questions[5].questions} setIncidentValues={setIncidentValues} />
    },
    {
      name: 'Send Report',
      hideTitle: true,
      content: <ReportSend contacts={keyContacts} incidentValues={incidentValues} questions={questions[6].questions} setNotifications={setNotifications} />
    }
  ]

  const getContacts = async () => {
    const params = {
      fields: 'user,type,key,organisation,value'
    }

    const where = {
      user: incidentValues.studentName.id
    }

    let contacts = await UserTagService.get(apiToken, params, where)

    if (contacts) {
      // Filter out the 'keycontacts'
      contacts = contacts.filter((item) => item.key === 'keycontacts')
    }

    if (contacts) {
      const parsedContacts = JSON.parse(contacts[0].value)
      const mappedContacts = parsedContacts.map((contact, index) => ({
        display: <><Box direction='column'><Text weight='bold'>{contact.firstName} {contact.lastName}</Text><Text>{contact.role}</Text></Box></>,
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        name: contact.firstName + ' ' + contact.lastName,
        role: contact.role
      }))

      console.log('saved contacts ', mappedContacts)

      setKeyContacts(mappedContacts)
    }
  }

  const getIncidentPlans = async (userId) => {
    let params = {
      fields: 'id,status',
      limit: 1,
      sort: 'createdAt DESC'
    }

    let where = {
      organisation: activeOrg.id,
      user: userId,
      type: 'incidentReport'
      // status: 'Draft'
    }

    const formSubmissions = await FormService.getSubmissions(apiToken, params, where)

    // If latest submission is Draft display the responses to edit
    // otherwise start a new one
    if (!formSubmissions?.error && formSubmissions.data[0].status === 'Draft') {
      params = {
        fields: 'questionKey,response,responseType',
        limit: 1000
      }

      where = {
        submission: formSubmissions.data[0].id
      }

      const formResponses = await FormService.getResponses(apiToken, params, where)

      // Get responses and convert into correct format
      if (formResponses.data.length > 0) {
        const mappedResponses = {}
        for (const response of formResponses.data) {
          if (response.responseType === 'number') {
            response.response = parseInt(response.response)
          }
          mappedResponses[response.questionKey] = response.response
        }

        setResponses(mappedResponses)
      }
    }
  }

  const getTechniques = async (technique) => {
    // Get organisation's deescalation/physical techniques
    // and add them to the relevant tab of questions
    // which is identifed in techniqueTabLookup
    const params = {
      fields: 'value',
      limit: 1,
      orgId: activeOrg.id
    }

    const where = {
      organisation: activeOrg.id,
      type: 'besmart',
      key: technique
    }

    const techniques = await OrganisationTagService.get(apiToken, params, where)

    if (techniques) {
      const parsedTechniques = JSON.parse(techniques[0].value)

      let mappedTechnique = {}

      const techniqueQuestions =
        {
          tabId: techniqueTabLookup[technique],
          questions: []
        }

      for (const technique of parsedTechniques) {
        // If technique is marked for selection
        // then convert it into the correct format
        // and save to new array
        if (technique.selected) {
          mappedTechnique = {}
          mappedTechnique.questionText = technique.technique
          mappedTechnique.key = removeSpaces(technique.technique.toLowerCase())
          mappedTechnique.responseType = 'number'
          techniqueQuestions.questions.push(mappedTechnique)
        }
      }

      // Add the Notes question
      techniqueQuestions.questions.push(
        {
          questionText: 'Other',
          key: technique === 'deescalationTechniques' ? 'otherTechniques' : 'otherPhysicalTechniques',
          responseType: 'number'
        })

      techniqueQuestions.questions.push(
        {
          questionText: 'Other - Please elaborate',
          key: technique === 'deescalationTechniques' ? 'otherTechniquesInfo' : 'otherPhysicalTechInfo'
        })

      const newQuestions = questions
      newQuestions[techniqueTabLookup[technique]] = techniqueQuestions
      setQuestions(newQuestions)
    }
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = true
        // inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // Submit form values
  const submitForm = async (draft) => {
    console.log('submit', incidentValues)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each form value
    for (var key in incidentValues) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(question => question.key === key)

        // if (found && incidentValues[key]) {
        if (found && checkInputHasContent(incidentValues[key])) {
          // console.log(found.questionText)
          let response = null

          if (key === 'studentName') {
            response = incidentValues[key].id
          } else {
            response = incidentValues[key]
          }

          item = {
            questionKey: key,
            questionText: found.questionText,
            response: response,
            responseType: found?.responseType || 'string'
          }
          submitArray.push(item)
        }
      })
    }

    // Get action button result
    // submitArray.push({ key: 'bestInterestQuestion', questionText: 'Was your action in the best interest of the [student]?', answer: actionButton })
    // console.log(submitArray)

    // Add interventions if there are any
    if (interventions.length > 0) {
      const injuries = []

      interventions.forEach(intervention => {
        injuries.push(intervention)
      })

      submitArray.push({
        // organisation: activeOrganisation.id,
        questionKey: 'interventions',
        questionText: 'Interventions',
        response: JSON.stringify(injuries)
      })
    }

    // Add notifications
    if (notifications.length > 0) {
      var notifyArray = []

      let contactFound = {}

      // Build array of notifications
      notifications.forEach(notification => {
        contactFound = keyContacts.find((contact) => contact.email === notification)
        notifyArray.push({
          firstName: contactFound.firstName,
          lastName: contactFound.lastName,
          email: contactFound.email
        })
      })
    }

    // Build output data
    const data = {
      user: incidentValues.studentName.id,
      organisation: activeOrg.id,
      status: draft ? 'Draft' : 'Published',
      type: 'incidentreport',
      reference: '',
      notify: JSON.stringify(notifyArray),
      responses: submitArray
    }

    console.log(data)
    // if (userId === 'new') {
    console.log('Notify ', notifications)
    var submissionAdded = await FormService.makeSubmissions(apiToken, data)
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error)
    } else {
      NotificationService.success('Incident report added')
      history.push('/serviceusers')
    }
    // }
  }

  // Map the form values into format required for api submission
  //   const mapForm = (values) => {
  //     var newArray = []
  //     var newObject = {
  //       key: '',
  //       questionText: '',
  //       answer: ''
  //     }
  //     for (var key in values) {
  //       var found = questions.find(q => q.key === key)
  //       if (found) {
  //         console.log(found.questionText)
  //         newObject = {
  //           key: key,
  //           questionText: found.questionText,
  //           answer: values[key]
  //         }
  //         newArray.push(newObject)
  //       }
  //     }
  //     console.log(newArray)
  //   }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack()
    } else {
      const prevPageIndex = tabIndex - 1
      onActiveTab(prevPageIndex)
    }
  }

  const nextPage = () => {
    if (tabIndex === tabContent.length - 1) {
      submitForm()
    } else {
      const nextPageIndex = tabIndex + 1
      onActiveTab(nextPageIndex)
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    const getUsers = async (type = 'user') => {
      const params = {
        fields: 'id,firstName,lastName,reference,type',
        limit: 1000,
        orgId: activeOrg.id,
        type
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (!users || users?.error) {
        // NotificationService.error(users.error)
      } else {
        // if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            if (type === 'user') {
              // Get system users
              const mappedUsers = users.data.map((data, index) => ({
                id: data.id,
                learnerNumber: data.reference,
                name: data.firstName + ' ' + data.lastName
              }))

              setUserValues(mappedUsers)

            } else if (type === 'student') {
              // Now get students
              const studentUsers = users.data.map((data, index) => ({
                id: data.id,
                learnerNumber: data.reference,
                name: data.firstName + ' ' + data.lastName
              }))

              setStudentValues(studentUsers)
            }
          }
        // }
      }
    }

    const getUserDetails = async (userId, type = 'user') => {
      const params = {
        fields: 'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
        limit: 1,
        orgId: activeOrg.id,
        type
      }

      const userDetails = await UserService.getUser(apiToken, params, userId)
      if (!userDetails || userDetails?.error) {
        // NotificationService.error(userDetails.error)
      } else {
        // if (!unmounted) {
        if (userDetails?.data) {
          setUserDetails(userDetails.data[0])

          setIncidentValues(incidentValues => ({
            ...incidentValues,
            // studentName: parseInt(userId),
            studentName: userDetails.data[0],
            completedBy: activeUser.id
          }))
        }
        // }
      }
    }

    getUsers('user')
    getUsers('student')
    getTechniques('deescalationTechniques')
    getTechniques('physicalTechniques')

    if (userId !== 'new') {
      getUserDetails(userId, 'student')

      // getContacts()
    }
    setLoading(false)

    // Set initial Meter setting
    setMeterValue(100 / questions.length)

    return () => { unmounted = true }
  }, [])

  useEffect(() => {
    if (incidentValues.studentName) {
      getContacts()

      getIncidentPlans(userId)
    }
  }, [incidentValues.studentName])

  useEffect(() => {
    setIncidentValues(incidentValues => ({
      ...incidentValues,
      ...responses,
      // studentName: parseInt(userId),
      studentName: userDetails
    }))
  }, [responses])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        {userId === 'new' ? 'New ' : ''}Incident Report{userId !== 'new' ? ': ' + userDetails.firstName + ' ' + userDetails.lastName : ''}
      </BreadcrumbBar>

      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>{userId === 'new' ? 'New ' : ''}Incident Report{userId !== 'new' ? ': ' + userDetails.firstName + ' ' + userDetails.lastName : ''}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box> */}
      <Box gridArea='main' background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Meter background='light-2' size='full' thickness='xsmall' type='bar' values={[{ color: 'brand', value: meterValue }]} />
          {studentValues
            ? <Tabs activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} full />
            : (
              <Box align='center'>
                <PulseLoader
                  size={15}
                  color='orange'
                  loading={loading}
                />
              </Box>)}

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            {tabIndex < tabContent.length - 1 &&
              <Button icon={<FontAwesomeIcon icon={['fal', 'save']} color='#fff' />} label='Save for later' onClick={() => submitForm(true)} />}
            <Button label={tabIndex !== tabContent.length - 1 ? 'Next Page' : 'Submit'} onClick={() => nextPage()} primary />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default IncidentEdit
