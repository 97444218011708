// react
import React from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box'
import H1 from '../../simple/heading/H1'
import Text from '../../simple/text/Text'

import ContentMenuItem from './ContentMenuItem'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * `ContentMenu` renders a list of Content Menu Items
 */
function ContentMenu (props) {
  const renderOutput = []

  return (
    <Box background='#fff' round='small'>

      {props.data.forEach((item, index) => {
        renderOutput.push(
          <Box key={index} pad='small'>
            <Box pad='small' border={{ color: item.header.borderColor, side: 'bottom', size: 'small' }}>
              <span>
                <FontAwesomeIcon color={item.header.iconColor} icon={[item.header.iconPrefix, item.header.icon]} size='lg' /><Text textStyle={{ size: '18px' }} margin='medium'>{item.header.title}</Text>
              </span>
            </Box>
          </Box>
        )

        item.content.forEach((menuItem, menuIndex) => {
          renderOutput.push(
            <Box key={`${index}-${menuIndex}`} pad={{ vertical: 'xsmall', left: 'medium', right: 'small' }}>
              <Box elevation='xsmall' round='small'>
                <ContentMenuItem contentMenuItemParams={menuItem} selectMenuItem={menuItem.selectMenuItem} />
              </Box>
            </Box>)
        })
      })}

      {renderOutput}

      {/* {props.data.map((item, index) => (
        <Box key={index} pad='small'>
          <Box pad='small' border={{color: item.header.borderColor, side: 'bottom', size: 'small'}}>
            <span>
              <FontAwesomeIcon color={item.header.iconColor} icon={[item.header.iconPrefix, item.header.icon]} size='lg' /><Text textStyle={{size: '18px'}} margin='medium'>{item.header.title}</Text>
            </span>
          </Box>
        </Box>

        // {
        //   item.content.map((menuItem, menuIndex) => (
        //     <Box pad={{vertical: 'xsmall', left: 'medium', right: 'small'}}>
        //       <Box elevation='xsmall' round='small'>
        //         <ContentMenuItem contentMenuItemParams={menuItem} key={menuItem.id} selectMenuItem={menuItem.selectMenuItem} />
        //       </Box>
        //     </Box>
        //   ))
        // }

      ))} */}

      {/* <H1 margin='small' size='small'>Content Menu</H1>
      <Box pad='small'>
        <Box pad='small' border={{color: props.header.borderColor, side: 'bottom', size: 'small'}}>
          <span>
            <FontAwesomeIcon color={props.header.iconColor} icon={[props.header.iconPrefix, props.header.icon]} size='lg' /><Text textStyle={{size: '18px'}} margin='medium'>{props.header.title}</Text>
          </span>
        </Box>
      </Box>
      {props.contentMenuItems.map(item => (
        <Box pad={{vertical: 'xsmall', left: 'medium', right: 'small'}}>
          <Box elevation='xsmall' round='small'>
            <ContentMenuItem contentMenuItemParams={item} key={item.id} selectMenuItem={props.selectMenuItem} />
          </Box>
        </Box>
      ))} */}
    </Box>
  )
}

ContentMenu.propTypes = {
  /**
   * An array of menu items
   *
   * [
   * &nbsp; header: {
   * &nbsp;&nbsp;borderColor: "string"
   * &nbsp;&nbsp;icon: "string"
   * &nbsp;&nbsp;iconColor: "string"
   * &nbsp;&nbsp;title: "string"
   * &nbsp;},
   * &nbsp; content: [{
   * &nbsp;&nbsp;icon: "string"
   * &nbsp;&nbsp;iconColor: "string"
   * &nbsp;&nbsp;title: "string"
   * &nbsp;}]
   * ]
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.shape({
        borderColor: PropTypes.string,
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        title: PropTypes.string.isRequired
      }).isRequired,
      content: PropTypes.arrayOf(ContentMenuItem.propTypes.contentMenuItemParams).isRequired
    })
  ),
  /**
   * The function called when a ContentMenuItem is clicked
   */
  selectMenuItem: PropTypes.func
}

// ContentMenu.defaultProps = {
//   header: {
//     borderColor: '#0f0',
//     iconColor: '#000',
//     iconPrefix: 'fas'
//   }
// }

export default ContentMenu
