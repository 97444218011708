
// const hideValues = false

import ApiService from './ApiService'

class AuditTrailService {
  get = async (apiToken, params, where) => {
    var url = '/api/audittrail'

    if (where) {
      params.where = where
    }

    try {
      var auditTrails = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('AuditTrailService:get ', auditTrails)
    return auditTrails
  }
}
export default new AuditTrailService()
