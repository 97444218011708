import React, { useState, useEffect, createContext } from 'react'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Grommet
import { Grommet, ResponsiveContext } from 'grommet'

// Appt router
import PrivateRoute from './PrivateRoute'

// Appt Components
import Box from './components/simple/box/Box'

// Appt Screens
import BehaviourPlan from './screens/behaviourPlan/BehaviourPlan'
import ContentEdit from './screens/ContentEdit'
import ContentList from './screens/ContentList'
import Dashboard from './screens/Dashboard'
import IncidentEdit from './screens/incidentEdit/IncidentEdit'
import IncidentReport from './screens/reports/IncidentReport'
import IncidentSearch from './screens/search/incidentSearch/IncidentSearch'
import InvolvementReport from './screens/reports/InvolvementReport'
import InterventionReport from './screens/reports/InterventionReport'
// import BehaviourPlan from './screens/behaviourPlan/BehaviourPlan'
import LoginScreen from './screens/Login'
import Logout from './screens/Logout'
import MedicalInterventionReport from './screens/reports/MedicalInterventionReport'
import OrganisationEdit from './screens/organisations/OrganisationEdit'
import OrganisationList from './screens/organisations/OrganisationList'
import PasswordReset from './components/compound/login/PasswordReset'
import Privacy from './screens/Privacy'
import Reports from './screens/reports/Reports'
import ReportSearch from './screens/search/reportSearch/ReportSearch'
import RoleEdit from './screens/RoleEdit'
import RoleList from './screens/RoleList'
import TandC from './screens/TandC'
import UserActivate from './screens/User/UserActivate'
import UserEdit from './screens/User/UserEdit'
import UserList from './screens/User/UserList'
import UserSignup from './screens/User/UserSignup'
import UserUpload from './screens/User/UserUpload'
import ServiceSetup from './screens/ServiceSetup'
import ServiceSubscription from './screens/ServiceSubscription'
import ServiceUserList from './screens/serviceUser/ServiceUserList'
import ServiceUserProfile from './screens/serviceUser/ServiceUserProfile'
import ServiceUserEdit from './screens/serviceUser/ServiceUserEdit'
import TermsService from './services/TermsService'

// Appt Menu
import Sidebar from './components/compound/sidebar/Sidebar'

// Stores
import { useStore, withStore } from 'react-context-hook'

// Services
import LocalStorageService from './services/LocalStorageService'
import NotificationService from './services/NotificationService'
import OrganisationService from './services/OrganisationService'
import OrganisationTagService from './services/OrganisationTagService'
import PasswordService from './services/PasswordService'

// Utilities
import { capitalize } from './utils/text'

// Stripe
import { loadStripe } from '@stripe/stripe-js'
import {
  Elements
} from '@stripe/react-stripe-js'

// Theme
import { beSmartTheme } from './utils/beSmartTheme'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome as falHome,
  faSave as falSave,
  faUser as falUser,
  faClipboard as falClipboard,
  faUserCircle as falUserCircle,
  faInfoCircle as falInfoCircle,
  faExclamationCircle as falExclamationCircle,
  faClipboardListCheck as falClipboardListCheck,
  faFolderOpen as falFolderOpen,
  faSearch as falSearch,
  faChartPie as falChartPie,
  faPlusCircle as falPlusCircle,
  faMinusCircle as falMinusCircle,
  faQuestionCircle as falQuestionCircle,
  faCloudUpload as falCloudUpload,
  faKey as falKey,
  faTrash as falTrash,
  faEdit as falEdit,
  faEye as falEye,
  faFileAlt as falFileAlt,
  faDownload as falDownload,
  faEnvelope as falEnvelope,
  faCog as falCog,
  faSquare as falSquare,
  faCircle as falCircle,
} from '@fortawesome/pro-light-svg-icons'
import { faCircle, faSquare, faInfoCircle, faExclamationCircle, faExclamationTriangle, faGraduationCap, faHome, faSave, faUser, faUserFriends, faPlusSquare, faQuestion, faQuestionCircle, faVideo, faFolderOpen, faMusic, faNewspaper, faFilePdf, faFile, faUpload, faPlus, faThLarge, faGavel, faLink, faAngleRight, faBriefcase } from '@fortawesome/pro-solid-svg-icons'

// Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
  // Link
} from 'react-router-dom'

export const GlobalContext = createContext()

function App () {
  library.add(falCircle, falInfoCircle, falSquare, falHome, falSave, falUser, falClipboard, falClipboardListCheck, falFolderOpen, falUserCircle, falExclamationCircle, falSearch, falChartPie, falPlusCircle, falMinusCircle, falQuestionCircle, falCloudUpload, falKey, falTrash, falEdit, falEye, falFileAlt, falDownload, falEnvelope, faLink, falCog)
  library.add(faCircle, faSquare, faInfoCircle, faExclamationCircle, faExclamationTriangle, faGraduationCap, faHome, faSave, faUser, faUserFriends, faPlusSquare, faQuestion, faQuestionCircle, faVideo, faFolderOpen, faMusic, faNewspaper, faFilePdf, faFile, faUpload, faPlus, faThLarge, faGavel, faAngleRight, faBriefcase)

  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore()
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore({})
  const [terms, setTerms, deleteTerms] = useStore({})

  const [activeOrgState, setActiveOrgState] = useState([])
  const [myOrganisations, setMyOrganisations] = useState([])
  const [myPermissions, setMyPermissions] = useState([])
  const [loginState, setLoginState] = useState({
    loggedInStatus: false,
    apiToken: '',
    userOrganisations: [],
    userDetails: {}
  })

  const stripePromise = loadStripe('pk_test_51I9vWmJcjjdzjSkJ99F4QxWzx9tnSntqhPzsm3Lxct69p2KTXR8YnpFbYqw7tll1SMqBOUZT1nw9VEgYXpPpElQd00EH1uUfxf')

  const checkLoginStatus = () => {
    // If no apiToken in global store get from local storage
    if (!apiToken) {
      const localApiToken = LocalStorageService.get('apiToken')

      if (localApiToken) {
        setApiToken(localApiToken)
        setCurrentOrgs(LocalStorageService.get('userOrganisations'))
        setCurrentUser(LocalStorageService.get('userDetails'))
        setActiveOrg(LocalStorageService.get('activeOrg'))
        console.log('checkLoginStatus ', LocalStorageService.get('activeOrg'))
        setActiveOrgState(LocalStorageService.get('activeOrg'))
        setMyPermissions(LocalStorageService.get('myPermissions'))

      } else {
        clearSession()
      }
    }

    // always set the terms
    setTerms(LocalStorageService.get('terms'))

    // If no apiToken in local storage either,
    // clear out the session just in case and
    // force a login
    // if (!apiToken) {
    //   // setApiToken(apiToken)
    //   // get currentOrgs
    //   // const myOrganisations = LocalStorageService.get('currentOrgs')
    //   // setCurrentOrgs(myOrganisations)
    // // } else {
    //   clearSession()
    // }
  }

  // clear the user's session if they log out
  const clearSession = () => {
    // remove all localstorage
    LocalStorageService.clear()

    // remove the session data
    deleteApiToken()
    deleteCurrentUser()
    deleteCurrentOrgs()
    deleteActiveOrg()
    deleteTerms()
    // history.push('/')
  }
  // Get system tags to handle common terms
  const getCommonTerms = async () => {

    const matchedTerms = await TermsService.getCommonTerms()

    LocalStorageService.set('terms', matchedTerms)
    setTerms(matchedTerms)
  }

  const handleLogin = (data) => {
    // Set state
    data.loggedInStatus = true
    setLoginState(data)

    // And set in local storage & global store
    LocalStorageService.set('apiToken', data.apiToken)
    setApiToken(data.apiToken)
    LocalStorageService.set('userOrganisations', data.userOrganisations)
    setCurrentOrgs(data.userOrganisations)
    LocalStorageService.set('activeOrg', data.userOrganisations[0])
    setActiveOrg(data.userOrganisations[0])
    console.log('handleLogin', data.userOrganisations[0])
    setActiveOrgState(data.userOrganisations[0])
    LocalStorageService.set('userDetails', data.userDetails)
    setCurrentUser(data.userDetails)

    getCommonTerms(data.apiToken)

    // store the permissions for easy access
    var myPermissions = []
    if (data?.userOrganisations?.[0]?.permissions) {
      myPermissions = data.userOrganisations.[0].permissions
    }
    LocalStorageService.set('myPermissions', myPermissions)
    setMyPermissions(myPermissions)
  }

  const handleLogout = () => {
    setLoginState({
      loggedInStatus: false,
      apiToken: '',
      userOrganisations: [],
      userDetails: {}
    })
    clearSession()
  }

  const handlePasswordReset = async (token, data) => {
    console.log(data, token)
    try {
      var passwordResetStatus = await PasswordService.resetPassword(token, data.password)
    } catch (err) {
      NotificationService.error(err)
    }

    NotificationService.info('Your password has been reset')

    // history doesn't work in App.js, we'll use this as a test case
    window.location.href = '/login'
  }

  // Set local storage etc with the active organisation
  const selectActiveOrg = (org) => {
    LocalStorageService.set('activeOrg', currentOrgs.find(x => x.id === org))
    setActiveOrg(currentOrgs.find(x => x.id === org))
    console.log('selectActiveOrg ', currentOrgs.find(x => x.id === org))
    setActiveOrgState(currentOrgs.find(x => x.id === org))
    // Why does this not work ?
    // history.push('/')
  }

  // const apiToken = LocalStorageService.get('apiToken')

  // Get Organisation for this User
  const getMyOrganisations = async (apiToken) => {
    if (apiToken) {
      const myOrganisations = await OrganisationService.myOrganisations(apiToken)

      setMyOrganisations(myOrganisations)
    }
  }

  // Basically componentDidMount
  // useEffect(() => {
  //   getMyOrganisations(apiToken)
  // }, [apiToken])
  useEffect(() => {
    console.log('App.js fired')
    checkLoginStatus()
  }, [])

  // useEffect(() => {
  //   console.log('TEST ', activeOrg)
  // }, [activeOrg])

  useEffect(() => {
    var menuSetup = []
    menuSetup.push({ linkHref: '/dashboard', icon: 'home', linkTitle: 'Dashboard ' })
    menuSetup.push({ linkHref: '/services', icon: 'clipboard-list-check', linkTitle: 'Services' })
    menuSetup.push({ linkHref: '/users', icon: 'user-circle', linkTitle: 'System Users' })
    setMenuState(menuSetup)
  }, [activeOrg, myPermissions])

  const serviceSetupTitle = `${terms && terms.service ? terms.service.charAt(0).toUpperCase() + terms.service.slice(1) : 'Service'} Setup`

  const [menuState, setMenuState] = useState([])

  const generateMenu = () => {
    var menuItems = []
    menuItems.push({ linkHref: '/dashboard', icon: 'home', linkTitle: 'Dashboard ' })
    // menuItems.push({ linkHref: '/incident/new/edit', icon: 'exclamation-circle', linkTitle: 'New Incident' })

    // if (myPermissions?.includes('editOrganisation')) {
      menuItems.push({ linkHref: '/services', icon: 'clipboard-list-check', linkTitle: 'Services' })
      // menuItems.push({ linkHref: '/servicesetup', icon: 'clipboard-list-check', linkTitle: serviceSetupTitle })
    // }

    // menuItems.push({ linkHref: '/serviceusers', icon: 'folder-open', linkTitle: 'Directory' })
    // menuItems.push({ linkHref: '/incident/search', icon: 'search', linkTitle: 'Incident Search' })
    // menuItems.push({ linkHref: '/reports', icon: 'chart-pie', linkTitle: 'Reports' })

    // if (myPermissions?.includes('userEditAll')) {
      menuItems.push({ linkHref: '/users', icon: 'user-circle', linkTitle: 'System Users' })
    // }

    return menuItems
  }

  return (
    <GlobalContext.Provider value={{ menuState, setMenuState }}>
      <Router>
        <Grommet background={{ color: 'background', image: 'url(/Behaviour-Smart-Background-2.png)' }} full theme={beSmartTheme}>
          <ToastContainer position={toast.POSITION.TOP_RIGHT} />

          <ResponsiveContext.Consumer>
            {size => {
              return (
                <Box className='pageWrapper' align='center' width='100%' height={size !== 'small' ? '100%' : null}>
                  <Box className='pageInnerWrapper' align='center' alignSelf='center' direction='row-responsive' gap='medium' justify='center' margin={{ top: size }} pad={{ horizontal: size }} width='xxlarge'>
                    <Sidebar
                      background='#fff'
                      round='small'
                      width='medium'
                      data={generateMenu()}
                      dividerColor='brand'
                      history={history}
                      image={myOrganisations && myOrganisations.data ? myOrganisations.data[0].logoUrl : '/BeSmart-Logo-Blue.png'}
                      select={currentOrgs}
                      selected={activeOrgState ? activeOrgState.id : -1}
                      selectActive={selectActiveOrg}
                    />
                    <Switch>
                      <Route
                        exact
                        path='/service/subscription'
                        render={props => (
                          <Elements stripe={stripePromise}>
                            <ServiceSubscription
                              {...props}
                            />
                          </Elements>
                        )}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/services'
                        component={OrganisationList}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/incident/search'
                        component={IncidentSearch}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/intervention/report/search'
                        component={ReportSearch}
                        submissionType='interventionreport'
                        reportTitle='Intervention'
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/report/interventionreport'
                        component={InterventionReport}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/involvement/report/search'
                        component={ReportSearch}
                        submissionType='involvementreport'
                        reportTitle='Involvement'
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/report/involvementreport'
                        component={InvolvementReport}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/medicalIntervention/report/search'
                        component={ReportSearch}
                        submissionType='medicalinterventionreport'
                        reportTitle='Medical Intervention'
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/report/medicalinterventionreport'
                        component={MedicalInterventionReport}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/incident/report/search'
                        component={ReportSearch}
                        submissionType='incidentreport'
                        reportTitle='Global Incident'
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/report/incidentreport'
                        component={IncidentReport}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/reports'
                        component={Reports}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/behaviour/:userid/edit'
                        component={BehaviourPlan}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/incident/:userid/edit'
                        component={IncidentEdit}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        exact
                        path='/role/:roleid/edit'
                        component={RoleEdit}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/roles'
                        component={RoleList}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/service/:organisationid/edit'
                        component={OrganisationEdit}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/services'
                        component={OrganisationList}
                        activeOrg={activeOrgState}
                      />
                      <Route
                        exact
                        path='/user/signup'
                        render={props => (
                          <Elements stripe={stripePromise}>
                            <UserSignup
                              {...props}
                              handleLogin={handleLogin}
                            />
                          </Elements>
                        )}
                      />
                      <Route path='/user/activate' component={UserActivate} />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/user/:userid/edit'
                        component={UserEdit}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/serviceuser/new/upload'
                        component={UserUpload}
                        activeOrg={activeOrgState}
                        type='serviceuser'
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/user/new/upload'
                        component={UserUpload}
                        activeOrg={activeOrgState}
                        type='user'
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/users'
                        component={UserList}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/servicesetup/:orgid'
                        component={ServiceSetup}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/serviceusers'
                        component={ServiceUserList}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/serviceuser/:userid/edit'
                        component={ServiceUserEdit}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/serviceuser/:userid/profile'
                        component={ServiceUserProfile}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/content/:contentid/edit'
                        component={ContentEdit}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/content'
                        component={ContentList}
                        activeOrg={activeOrgState}
                      />
                      <Route path='/privacy' component={Privacy} />
                      <Route path='/tandc' component={TandC} />
                      <Route
                        exact
                        path='/logout'
                        render={props => (
                          <Logout
                            {...props}
                            handleLogout={handleLogout}
                            loggedInStatus={loginState}
                          />
                        )}
                      />
                      {/* NOTE: Use this render method to pass props to the component */}
                      <Route
                        exact
                        path='/login'
                        render={props => (
                          <LoginScreen
                            {...props}
                            handleLogin={handleLogin}
                            handleLogout={handleLogout}
                            loggedInStatus={loginState}
                          />
                        )}
                      />
                      <Route
                        exact
                        path='/reset-password'
                        render={props => (
                          <PasswordReset
                            {...props}
                            handlePasswordReset={handlePasswordReset}
                          />)}
                      />

                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/dashboard'
                        component={Dashboard}
                        loggedInStatus={loginState}
                        activeOrg={activeOrgState}
                      />
                      <PrivateRoute
                        terms={terms}
                        exact
                        path='/'
                        component={Dashboard}
                        loggedInStatus={loginState}
                        activeOrg={activeOrgState}
                      />
                      <Route render={() => <h1>404: page not found - to be created</h1>} />
                    </Switch>
                  </Box>
                </Box>
              )
            }}
          </ResponsiveContext.Consumer>
        </Grommet>
      </Router>
    </GlobalContext.Provider>
  )
}

export default withStore(App)
