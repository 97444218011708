import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Grommet
import { ResponsiveContext } from 'grommet'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationService from '../../services/OrganisationService'
import ReportService from '../../services/ReportService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Text from '../../components/simple/text/Text'
import H1 from '../../components/simple/heading/H1'

import NotificationLayer from '../../components/compound/notification/NotificationLayer'

function OrganisationList () {
  const [showDeleteOptions, showDelete] = useState(false)
  const [orgToDelete, setOrgToDelete] = useState(null)
  const [orgValues, setOrgValues] = useState([])
  const [loading, setLoading] = useState(false)

  const [serviceContacts, setServiceContacts] = useState({})


  const history = useHistory()

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'name',
    direction: 'asc'
  })

  // const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  // Prompt user to delete
  const showDeleteOrgOptions = (org) => {
    setOrgToDelete(org)
    showDelete(true)
  }

  useEffect(() => {
    getServiceContact()
  }, [])

  const getServiceContact = async () => {
    const body = {
      submissionType: 'incidentreport',
      query: [],
      reports: ['systemOrganisationsAndKeyContacts']
    }

    const params = {
      orgId: activeOrg.id
    }

    const results = await ReportService.getReport(apiToken, params, body)

    if (results?.systemOrganisationsAndKeyContacts) {
      var newServiceContacts = {}
      for (const organisation of results.systemOrganisationsAndKeyContacts) {
        newServiceContacts[organisation.organisationId] = organisation
      }
      setServiceContacts(newServiceContacts)
    }
    return results
  }

  // Delete an Organisation
  const deleteOrg = async () => {
    // Delete from server
    const response = await OrganisationService.deleteOrg(apiToken, orgToDelete)

    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newOrgValues = orgValues.filter((item) => item.id !== orgToDelete.id)
      setOrgValues(newOrgValues)
      NotificationService.info('Organisation deleted')
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  useEffect(() => {
    let unmounted = false

    // Get organisations
    const getOrganisations = async () => {
      const apiToken = LocalStorageService.get('apiToken')

      const params = {
        fields: 'id,name,addressLineOne,addressLineTwo,postcode,notes,city,admin,label',
        limit: 1000
      }

      setLoading(true)
      const organisations = await OrganisationService.getOrganisations(apiToken, params)
      if (organisations?.error) {
        NotificationService.error(organisations.error)
      } else {
        if (!unmounted) {
          // if there's no organisations, do nothing
          if (!organisations || !organisations.data) {
            return
          }

          const mappedOrganisations = organisations.data.map((data, index) => ({
            id: data.id,
            name: data.name,
            notes: data.notes,
            label: data.label,
            address: `${data.addressLineOne} ${data.addressLineTwo ? ', ' + data.addressLineTwo : ''}, ${data.postcode}, ${data.city}`,
            admin: `${serviceContacts[data.id]?.firstname} ${serviceContacts[data.id]?.lastname}`,
            adminEmail: serviceContacts[data.id]?.email,
            createdAt: data.createdAt
          }))

          setOrgValues(mappedOrganisations)
        }
      }
    }

    getOrganisations()

    setLoading(false)

    return () => { unmounted = true }
  }, [serviceContacts])

  const dataColumns = [
    {
      property: 'name',
      header: <Text>Name</Text>,
      primary: true,
      render: datum => (
        <>
          <Text weight='bold'>
            {datum.label ? (<Text><FontAwesome color={'Label' + datum.label} icon={['fa', 'circle']} />&nbsp;</Text>) : ''}
            {datum.name}
          </Text>
          <Text>{datum.notes}</Text>
        </>
      )
    },
    {
      property: 'admin',
      header: <Text>Primary Contact</Text>,
      render: datum => (
        <>
          <Text weight='bold'>{datum.admin}</Text>
          <Text><Anchor weight={400} href={`mailto:${datum.adminEmail}`}>{datum.adminEmail}</Anchor></Text>
        </>
      )
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          <Button href={`servicesetup/${datum.id}`} label={<Text><FontAwesome color='background' icon={['fal', 'cog']} /></Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
          <Button href={`service/${datum.id}/edit`} label={<Text><FontAwesome color='background' icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
          <Button label={<Text><FontAwesome color='background' icon={['fal', 'trash']} /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => showDeleteOrgOptions(datum)} target='_self' />
        </Box>
      )
    }
  ]

  return (
    <>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Services </H1>
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteOrg} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete organisation {orgToDelete.name}? This cannot be undone.</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Behaviour Smart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box direction='row-responsive' gap='xlarge' justify='between' pad={{ horizontal: 'medium', top: 'medium' }}>
          <Box>
            <Text color='#000' size='large' weight='bold'>Services</Text>
          </Box>
          <Box direction='row-responsive' gap='xxsmall'>
            <Button href='service/new/edit' label={<Text><FontAwesome color='background' icon={['fal', 'plus-circle']} /> Add Service</Text>} primary target='_self' />
          </Box>
        </Box>

        <Box direction='column' gap='medium' pad={{ horizontal: 'medium', bottom: 'medium' }}>
          <Box align='center'>
            <PulseLoader
              size={15}
              color='orange'
              loading={loading}
            />
          </Box>
          <ResponsiveContext.Consumer>
            {responsive => (
              <DataTable
                columns={responsive === 'small' ? dataColumns.filter(el => el.property !== 'addressLineOne' && el.property !== 'createdAt') : dataColumns}
                // columns={dataColumns}
                data={orgValues}
                onSort={setSort}
                paginate
                sort={sort}
                step={1000}
              />)}
          </ResponsiveContext.Consumer>
          {!orgValues.length && <Text size='small' margin={{ horizontal: 'small' }}>There are no organisations to display</Text>}
        </Box>
      </Box>
    </>
  )
}

export default OrganisationList
