// React
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box.js'
import Text from '../../simple/text/Text.js'

/**
 * `SimpleCounter` renders a Card to display a counter of items eg. number of posts
 */
function SimpleCounter (props) {
  const [count, setCount] = useState(0)

  useEffect(() => {
    let start = 0

    // first three numbers from props
    const end = parseInt(props.count.substring(0, 3))

    // if zero, return
    if (start === end) return

    // find duration per increment
    const totalMilSecDur = parseInt(props.duration || 1)
    const incrementTime = (totalMilSecDur / end) * 1000

    const timer = setInterval(() => {
      start += 1
      setCount(String(start) + props.count.substring(3))
      if (start === end) clearInterval(timer)
    }, incrementTime)
  }, [props.count, props.duration])

  return (
    <Box align='center' background={props.background || '#fff'} pad='medium' round='small'>
      <Text color='brand' size='128px' weight={300}>{count}</Text>
      <Text color='#000' size='medium' weight={300}>{props.label}</Text>
    </Box>)
}

SimpleCounter.propTypes = {
  /**
   * Either a color identifier to use for the background color.
   * For example: 'neutral-1'. Or, a 'url()' for an image. Dark is not needed if color is provided.
   *
   * "neutral-1"
   * "url(//my.com/assets/img.png)"
   * {
   * &nbsp;"color": "neutral-1",
   * &nbsp;"dark": true,
   * &nbsp;"opacity": true,
   * &nbsp;"position": "bottom",
   * &nbsp;"repeat": "no-repeat",
   * &nbsp;"size": "cover",
   * &nbsp;"image": "url(//my.com/assets/img.png)"
   * }
   * {"dark": "light-2", "light": "dark-2"}
   *
   * where opacity could be:
   *  &nbsp;"weak"
   *  &nbsp;"medium"
   *  &nbsp;"strong"
   *  &nbsp;true
   *
   * where position could be:
   *  &nbsp;any CSS for background-position
   *
   * where repeat could be:
   *  &nbsp;"no-repeat"
   *  &nbsp;"repeat"
   *  &nbsp;"string"
   *
   * where size could be:
   *  &nbsp;"cover"
   *  &nbsp;"contain"
   *  &nbsp;"string"
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}

export default SimpleCounter
