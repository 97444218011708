// React
import React from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box.js'
import Text from '../../simple/text/Text.js'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * `CounterBox` renders a Card to display a counter of items eg. number of posts
 */
class CounterBox extends React.Component {
  render () {
    const counter = this.props.counter || this.props.counter === 0 ? this.props.counter : '.'
    return (
      <Box background={this.props.background || '#fff'} pad='medium' round='small' style={{flex: 1}}>
        <Text color={this.props.iconColor} size='xxlarge'>
          <FontAwesomeIcon icon={['fas', this.props.icon]} />
          <Text margin={{ left: 'xsmall' }} style={{ flex: 1 }} color='#000' size='xxlarge' weight='bold'>{counter}</Text>
          <Text margin={{ left: 'xsmall' }} color='#000' size='large' weight={400}> {this.props.children}</Text>
        </Text>
      </Box>)
  }
}

CounterBox.propTypes = {
  /**
   * Either a color identifier to use for the background color.
   * For example: 'neutral-1'. Or, a 'url()' for an image. Dark is not needed if color is provided.
   *
   * "neutral-1"
   * "url(//my.com/assets/img.png)"
   * {
   * &nbsp;"color": "neutral-1",
   * &nbsp;"dark": true,
   * &nbsp;"opacity": true,
   * &nbsp;"position": "bottom",
   * &nbsp;"repeat": "no-repeat",
   * &nbsp;"size": "cover",
   * &nbsp;"image": "url(//my.com/assets/img.png)"
   * }
   * {"dark": "light-2", "light": "dark-2"}
   *
   * where opacity could be:
   *  &nbsp;"weak"
   *  &nbsp;"medium"
   *  &nbsp;"strong"
   *  &nbsp;true
   *
   * where position could be:
   *  &nbsp;any CSS for background-position
   *
   * where repeat could be:
   *  &nbsp;"no-repeat"
   *  &nbsp;"repeat"
   *  &nbsp;"string"
   *
   * where size could be:
   *  &nbsp;"cover"
   *  &nbsp;"contain"
   *  &nbsp;"string"
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * A large sized number displayed in the card
   */
  counter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * The icon displayed on the card
   */
  icon: PropTypes.string,
  /**
   * The icon color
   */
  iconColor: PropTypes.string
}

export default CounterBox
