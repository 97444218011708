import React, { useState, useEffect } from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'

function FollowUp (props) {
  const [questions, setQuestions] = useState(props.questions)

  // Submit form values
  const submitForm = async (values) => {
    props.submitForm(values)
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Follow up Support</H2>
          <Button label={<Text><FontAwesomeIcon color='#fe8300' icon={['fas', 'question-circle']} /></Text>} plain tip='This is the first page of the Incident Report' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setBehaviourPlanValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.behaviourPlanValues}
        >
          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto', 'auto'],
              large: ['auto', 'auto', 'auto'],
              xlarge: ['auto', 'auto', 'auto']
            }}
            gap='small'
          >
          {/* <Grid
            rows={['auto', 'auto']}
            columns={['auto', 'auto', 'auto']}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          > */}
            <FormField
              label={questions[0].questionText}
              name={questions[0].key}
              required
            >
              <TextArea
                name={questions[0].key}
              />
            </FormField>

            <FormField
              label={questions[1].questionText}
              name={questions[1].key}
              required
            >
              <TextArea
                name={questions[1].key}
              />
            </FormField>

            <FormField
              label={questions[2].questionText}
              name={questions[2].key}
              required
            >
              <TextArea
                name={questions[2].key}
              />
            </FormField>

            <FormField
              label={questions[3].questionText}
              name={questions[3].key}
            >
              <TextArea
                name={questions[3].key}
              />
            </FormField>

            <FormField
              label={questions[4].questionText}
              name={questions[4].key}
            >
              <TextArea
                name={questions[4].key}
              />
            </FormField>

            <FormField
              label={questions[5].questionText}
              name={questions[5].key}
            >
              <TextArea
                name={questions[5].key}
              />
            </FormField>

          </ResponsiveGrid>
        </Form>
      </Box>
    </Box>
  )
}

export default FollowUp
