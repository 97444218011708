export const constants = {
  gender: [
    {
      label: 'Male',
      value: 'male'
    },
    {
      label: 'Female',
      value: 'female'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  ethnicity: [
    {
      label: 'White',
      value: 'white'
    },
    {
      label: 'Mixed or Multiple ethnic groups',
      value: 'mixed'
    },
    {
      label: 'Asian or Asian British',
      value: 'asian'
    },
    {
      label: 'Black, African Caribbean or Black British',
      value: 'black'
    },
    {
      label: 'Other ethnic group',
      value: 'other'
    }
  ],
  reportSearch: [
    {
      label: '[Student]',
      value: 'studentName',
      type: 'autocomplete'
      // Trying to setup a Select for ValueBox
      // select: {
      //   fields: 'id,firstName,lastName',
      //   label: 'firstName lastName',
      //   value: 'id'
      // }
    },
    {
      label: '[Student] - Learner Number',
      value: 'serviceuser_reference',
      type: 'text'
    },
    {
      label: '[Student] - Age',
      value: 'serviceuser_age',
      type: 'integer'
    },
    {
      label: '[Student] - Gender',
      value: 'serviceuser_gender',
      type: 'list',
      list: 'gender'
    },
    {
      label: '[Student] - Ethnicity',
      value: 'serviceuser_ethnicity',
      type: 'list',
      list: 'ethnicity'
    },
    {
      label: '[Student] - Year Group',
      value: 'serviceuser_academicyear',
      type: 'autocomplete'
    },
    {
      label: '[Student] - Student Group',
      value: 'serviceuser_usergroup',
      type: 'text'
    },
    {
      label: 'Staff',
      value: 'staffName',
      type: 'autocomplete' // staff members
    },
    {
      label: 'Staff - Involved',
      value: 'staffInvolved',
      type: 'autocomplete' // staff members
    },
    {
      label: 'Staff - Num Involved',
      value: 'numberStaffInvolved',
      type: 'integer'
    },
    {
      label: 'Behaviour - Low Level',
      value: 'lowLevelBehaviour',
      type: 'text'
    },
    {
      label: 'Behaviour - Medium Level',
      value: 'mediumLevelBehaviour',
      type: 'text'
    },
    {
      label: 'Behaviour - High Level',
      value: 'highLevelBehaviour',
      type: 'text'
    },
    {
      label: 'Behaviour - Recovery Level',
      value: 'recoveryBehaviour',
      type: 'text'
    },
    {
      label: 'Deescalation Techniques Used',
      value: 'deescalationTechnique',
      type: 'autocomplete' // list of deescalation techniques
    },
    {
      label: 'Breathing Monitored',
      value: 'breathingMonitored',
      type: 'boolean' // yes or no
    },
    {
      label: 'Duration of Physical Intervention',
      value: 'physicalInterventionDuration',
      type: 'integer'
    },
    {
      label: 'Injury - Suffered By Child',
      value: 'injurySufferedByChild',
      type: 'boolean'
    },
    {
      label: 'Injury - Suffered By Staff',
      value: 'injurySufferedByStaff',
      type: 'boolean'
    },
    {
      label: 'Injury - Suffered By Others',
      value: 'injurySufferedByOthers',
      type: 'boolean'
    },
    {
      label: 'Injury - Type',
      value: 'injury',
      type: 'text'
    },
    {
      label: 'Injury - Treatment',
      value: 'treatment',
      type: 'text'
    },
    {
      label: 'Incident - Reference',
      value: 'id',
      type: 'text'
    },
    {
      label: 'Incident - Date',
      value: 'dateOfIncident',
      type: 'date'
    },
    {
      label: 'Incident - Time',
      value: 'timeOfIncident',
      type: 'time'
    },
    {
      label: 'Incident - Location',
      value: 'location',
      type: 'text'
    },
    {
      label: 'Incident - Likely Triggers',
      value: 'likelyTriggers',
      type: 'text'
    }
  ],
  academicYears: [
    'Year -10',
    'Year -9',
    'Year -8',
    'Year -7',
    'Year -6',
    'Year -5',
    'Year -4',
    'Year -3',
    'Year -2',
    'Year -1',
    'Year 0',
    'Year 1',
    'Year 2',
    'Year 3',
    'Year 4',
    'Year 5',
    'Year 6',
    'Year 7',
    'Year 8',
    'Year 9',
    'Year 10',
    'Year 11',
    'Year 12',
    'Year 13',
    'Year 14',
    'Year 15',
    'Year 16',
    'Year 17',
    'Year 18',
    'Year 19',
    'Year 20'
  ],
  injurySufferedBy: [
    'child',
    'staff',
    'other'
  ]
}

export default constants
