// React
import React from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box'
import Text from '../../simple/text/Text'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * `ContentMenuItem` renders a container to show an Item header
 */
function ContentMenuItem (props) {
  console.log(props)
  return (
    <Box background={props.background || '#fff'} onClick={() => props.selectMenuItem(props.contentMenuItemParams.link)} pad='small' round='small'>
      <span>
        <FontAwesomeIcon color={props.contentMenuItemParams.iconColor} icon={[props.contentMenuItemParams.iconPrefix, props.contentMenuItemParams.icon]} size='lg' /><Text textStyle={{ size: '18px' }} margin='medium'>{props.contentMenuItemParams.title}</Text>
      </span>
    </Box>
  )
}

ContentMenuItem.propTypes = {
  /**
   * Either a color identifier to use for the background color.
   * For example: 'neutral-1'. Or, a 'url()' for an image. Dark is not needed if color is provided.
   *
   * "neutral-1"
   * "url(//my.com/assets/img.png)"
   * {
   * &nbsp;"color": "neutral-1",
   * &nbsp;"dark": true,
   * &nbsp;"opacity": true,
   * &nbsp;"position": "bottom",
   * &nbsp;"repeat": "no-repeat",
   * &nbsp;"size": "cover",
   * &nbsp;"image": "url(//my.com/assets/img.png)"
   * }
   * {"dark": "light-2", "light": "dark-2"}
   *
   * where opacity could be:
   *  &nbsp;"weak"
   *  &nbsp;"medium"
   *  &nbsp;"strong"
   *  &nbsp;true
   *
   * where position could be:
   *  &nbsp;any CSS for background-position
   *
   * where repeat could be:
   *  &nbsp;"no-repeat"
   *  &nbsp;"repeat"
   *  &nbsp;"string"
   *
   * where size could be:
   *  &nbsp;"cover"
   *  &nbsp;"contain"
   *  &nbsp;"string"
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   *
   * - An object containing the ContentMenuItem parameters.
   * - title - the item title
   * - icon - the ContentMenuItem icon displayed to the left of the title
   * - iconColor - the icon color
   * - link - the url link
   */
  contentMenuItemParams: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconPrefix: PropTypes.string,
    link: PropTypes.string
  }),
  /**
   * The function called when the ContentMenuItem is clicked
   */
  selectMenuItem: PropTypes.func
}

ContentMenuItem.defaultProps = {
  contentMenuItemParams: {
    iconPrefix: 'fas'
  }
}

export default ContentMenuItem
