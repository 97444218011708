// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Anchor as GAnchor } from 'grommet'

/**
 * `Anchor` renders a Grommet Anchor
 */
class Anchor extends React.Component {
  render () {
    // const handleClick = () => this.props.onClick()

    return (
      <GAnchor
        {...this.props}
        color={this.props.color || '#000'}
      //   onClick={() => handleClick()}
      />)
  }
}

Anchor.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   *  "start"  
   *  "end"  
   *  "center"  
   *  "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * The DOM tag or react component to use for the element.  
   *   
   * "string"  
   * function
   */
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * Label color and icon color, if not specified on the icon.  
   *   
   * control
   */
  color: PropTypes.string,
  /**
   * Whether the anchor is disabled.  
   *   
   * true  
   * **false**
   */
  disabled: PropTypes.bool,
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * Hyperlink reference to place in the anchor.  
   *   
   * "//my.com/path"
   */
  href: PropTypes.string,
  /**
   * Icon element to place in the Anchor.  
   *   
   * eg. "\<Add \/>""
   */
  icon: PropTypes.object,
  /**
   * Label text to place in the Anchor.  
   *   
   * "Add"  
   * \<Box>...\</Box>
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Click handler. It can be used, for example, to add analytics and track who clicked in the anchor.  
   *   
   * () => {}
   */  
  onClick: PropTypes.func,
  /**
   * Whether an icon and label should be reversed so that the icon is at the end of the anchor.  
   *   
   * true  
   * **false**
   */
  reverse: PropTypes.bool,
  /**
   * The font size is typically driven by the components containing this component.  
   * But, it can be adjusted directly via this size property, typically when it is not contained in a 'Heading', 'Paragraph', or 'Text'.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "xxlarge"  
   * "string"
   */
  size: PropTypes.string
}

export default Anchor
