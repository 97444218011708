import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import Moment from 'react-moment'

import AuditTrailService from '../../services/AuditTrailService'
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// Font Awesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import List from '../../components/simple/list/List'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function ServiceUserProfile () {
  const history = useHistory()

  const [userDetails, setUserDetails] = useState({})
  const [auditTrailDetails, setAuditTrailDetails] = useState([])
  const [LatestBehaviourPDF, setLatestBehaviourPDF] = useState('')
  const [keyContacts, setKeyContacts] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const params = useParams()

  const userId = params.userid

  const getUserTags = async (userId) => {
    const params = {
      fields: 'type,value',
      orgId: activeOrg.id
    }

    const where = {
      user: userId,
      organisation: activeOrg.id
    }
    const tags = await UserTagService.get(apiToken, params, where)

    let mappedContacts = []
    let contacts = ''

    if (tags) {
      tags.forEach((tag) => {
        switch (tag.type) {
          case 'keycontacts':
            contacts = JSON.parse(tag.value)

            mappedContacts = contacts.map(item => ({
              contact: (
                <Box direction='column'>
                  <Text>{item.role}: {item.firstName} {item.lastName}</Text>
                  <Box direction='row' margin={{ left: 'small' }}>
                    <FontAwesome color='#fe8300' icon={['fal', 'envelope']} size='sm' /><Text margin={{ left: 'small' }} size='small'>{item.email}</Text>
                  </Box>
                </Box>)
            }))

            setKeyContacts(mappedContacts)
            break
        }
      })
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Check if adding a new User or editing an existing one
    const getUserDetails = async (userId) => {
      const params = {
        fields: 'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
        limit: 1,
        orgId: activeOrg.id,
        type: 'student'
      }

      const userDetails = await UserService.getUser(apiToken, params, userId)
      if (userDetails.error) {
        // NotificationService.error(userDetails.error)
      } else {
        if (!unmounted) {
          setUserDetails(userDetails.data[0])
          // console.log(userDetails.data)
        }
      }
    }

    const getAuditTrail = async (userId) => {
      let params = {
        fields: 'id,description,type,key,actionedBy,createdAt,subject,organisation,additionalData',
        limit: 1000,
        orgId: activeOrg.id,
        sort: 'id DESC'
      }

      let where = {
        subject: userId,
        organisation: activeOrg.id
      }
      const auditTrail = await AuditTrailService.get(apiToken, params, where)
      if (!auditTrail.error) {
        if (!unmounted) {
          // If an action log entry is a published submission
          // get and attach the file for download purposes
          for (let i = 0; i < auditTrail.data.length; i++) {
          // auditTrail.data.forEach(element => {
            if (auditTrail.data[i].description === 'Published incidentreport has been submitted') {
              // Get submission id
              const additionalData = JSON.parse(auditTrail.data[i].additionalData)

              params = {
                fields: 'id,pdfUrl',
                orgId: activeOrg.id
              }

              where = {
                id: additionalData.submissionId
              }

              const submission = await FormService.getSubmissions(apiToken, params, where)

              if (submission.data[0].pdfUrl) {
                auditTrail.data[i].pdfUrl = submission.data[0].pdfUrl
              }
            }

            if (auditTrail.data[i].description === 'Published behaviourplan has been submitted') {
              // Get submission id
              const additionalData = JSON.parse(auditTrail.data[i].additionalData)

              params = {
                fields: 'id,pdfUrl',
                orgId: activeOrg.id
              }

              where = {
                id: additionalData.submissionId
              }

              const submission = await FormService.getSubmissions(apiToken, params, where)

              if (submission.data[0].pdfUrl) {
                auditTrail.data[i].pdfUrl = submission.data[0].pdfUrl
                setLatestBehaviourPDF(submission.data[0].pdfUrl)
              }
            }
          }

          setAuditTrailDetails(auditTrail.data)
        }
      }
    }

    if (userId !== 'new') {
      getUserDetails(userId)
      getAuditTrail(userId)
      getUserTags(userId)
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      {/* Breadcrumb section */}
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        User: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}
      </BreadcrumbBar>

      {/* Actions section */}
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>
            <Text size='xlarge'>Quick Actions</Text>
          </Box>
          <Box alignSelf='center' direction='row-responsive' gap='small'>
            <Button href={`/serviceuser/${userDetails.id}/edit`} label={<Text size='small'><FontAwesome icon={['fal', 'edit']} /> Edit Profile</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
            <Button href={`/incident/${userDetails.id}/edit`} label={<Text size='small'><FontAwesome icon={['fal', 'plus-circle']} /> Record An Incident</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
            <Button href={`/behaviour/${userDetails.id}/edit`} label={<Text size='small'><FontAwesome icon={['fal', 'file-alt']} /> Update Smart Plan</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
            {LatestBehaviourPDF && <Button href={LatestBehaviourPDF} label={<Text size='small'><FontAwesome icon={['fal', 'download']} /> Download Smart Plan</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />}
          </Box>
        </Box>
      </Box>

      {/* Main section */}
      <Box background='white' direction='row-responsive' gap='small' gridArea='main' margin={{ bottom: 'medium' }} pad='small' round='small' flex='grow'>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto', 'auto'],
            large: ['auto', 'auto'],
            xlarge: ['auto', 'auto']
          }}
          gap='small'
        >
          <Box direction='column' width='medium' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box margin={{ bottom: 'medium' }}>
              <Text size='large' weight='bold'>Name</Text>
              <Text color='brand' size='xlarge' weight='bold'>{userDetails.firstName} {userDetails.lastName}</Text>
            </Box>

            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Box direction='row' justify='between' margin={{ bottom: 'medium' }}>
              <Box direction='column'>
                <Text size='small'>Learner Number</Text>
                <Text size='large' weight='bold'>{userDetails.reference || 'n/a'}</Text>
              </Box>
              <Box direction='column'>
                <Text size='small'>Gender</Text>
                <Text size='large' weight='bold'>{userDetails.gender || 'n/a'}</Text>
              </Box>
            </Box>
            <Box direction='column'>
              <Text size='small'>Ethnicity</Text>
              <Text size='large' weight='bold'>{userDetails.ethnicity || 'n/a'}</Text>
            </Box>

            <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

            {keyContacts.length
              ? (
                <Box direction='row' justify='between' margin={{ bottom: 'medium' }}>
                  <Box direction='column'>
                    <Text size='small'>Key Contacts</Text>
                    <List border={false} data={keyContacts} pad={{ left: 'none' }} primaryKey='contact' />
                  </Box>
                </Box>)
              : null}

          </Box>

          <Box direction='column' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Text size='large' weight='bold'>Action Log</Text>
            {auditTrailDetails.length > 0
              ? auditTrailDetails?.slice(0).map((item) => (
                // ? auditTrailDetails?.slice(0).reverse().map((item) => (
                <Box direction='column' key={item.id} margin={{ left: '20px', top: '20px' }}>
                  <Box direction='row'>
                    <Box margin={{ right: 'medium' }}>
                      <FontAwesome color='brand' icon={['fas', 'info-circle']} size='2x' />
                    </Box>
                    <Box direction='column'>
                      <Text color='brand' size='small'><Moment format='MMM Do YYYY-HH:mm'>{item.createdAt}</Moment></Text>
                      <Text size='large' weight='bold'>{item.description}</Text>
                      {item.pdfUrl &&
                        <Button
                          target='_self'
                          plain
                          href={item.pdfUrl}
                          children={
                            <Box pad='5px'>
                              <FontAwesome icon={['fal', 'download']} color='brand' />
                            </Box>
                          }
                        />}
                    </Box>
                  </Box>
                </Box>)
              )
              : <Text>No Action Log details available</Text>}
          </Box>
        </ResponsiveGrid>
      </Box>
    </Box>
  )
}

export default ServiceUserProfile
