// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { ResponsiveContext } from 'grommet'

// Appt
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Text from '../../components/simple/text/Text'

/**
 * `QuickActionBar` renders a box with 'quick Actions' and action buttons
 */
function QuickActionBar (props) {
  return (
    <ResponsiveContext.Consumer>
      {responsive => (
        <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box pad={{ bottom: responsive === 'small' ? 'medium' : 'none' }}>
              <Text size='xxlarge' weight={400} margin={{ top: 'none' }}>{props.label}</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              <Button label={props.button1Label} margin={{ horizontal: 'xsmall' }} onClick={() => { props.button1Click() }} target='_self' />
              <Button label={props.button2Label} margin={{ horizontal: 'xsmall' }} onClick={() => { props.button2Click() }} target='_self' />
            </Box>
          </Box>
        </Box>)}
    </ResponsiveContext.Consumer>)
}

QuickActionBar.defaultProps = {
  label: 'Quick Actions'
}

QuickActionBar.propTypes = {
  /**
   * The header label on the bar
   */
  label: PropTypes.string,
  /**
   * The label on button1
   */
  button1Label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  /**
   * The function called when button1 is clicked
   */
  button1Click: PropTypes.func,
  /**
   * The label on button2
   */
  button2Label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  /**
   * The function called when button2 is clicked
   */
  button2Click: PropTypes.func
}

export default QuickActionBar
