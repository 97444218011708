import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Divider from '../../components/simple/divider/Divider.js'
import Grid from '../../components/simple/grid/Grid'
import Text from '../../components/simple/text/Text'

// App widgets
import SimpleCounter from '../../components/compound/simpleCounter/SimpleCounter'

import { Bar, Doughnut, Line, Polar } from 'react-chartjs-2'

const MedicalInterventionReport = (props) => {
  const history = useHistory()

  const [numIncidentsInvolvingInjuryToChild, setNumIncidentsInvolvingInjuryToChild] = useState(0)

  const [numIncidentsInvolvingInjuryToStaff, setNumIncidentsInvolvingInjuryToStaff] = useState(0)

  const [numIncidentsInvolvingInjuryToOthers, setNumIncidentsInvolvingInjuryToOthers] = useState(0)

  const [numIncidentsInvolvingInjuryByStudentGroup, setNumIncidentsInvolvingInjuryByStudentGroup] = useState([])

  const [numIncidentsInvolvingInjuryWithOtherChildren, setNumIncidentsInvolvingInjuryWithOtherChildren] = useState([])

  const [totalNumberOfIncidentsByDate, setTotalNumberOfIncidentsByDate] = useState([])

  const [totalNumberOfIncidentsByHour, setTotalNumberOfIncidentsByHour] = useState([])

  // Child Injury
  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [{
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by Date
  const incidentsByDate = {
    labels: totalNumberOfIncidentsByDate[1],
    datasets: [{
      // label: '# of Incidents',
      data: totalNumberOfIncidentsByDate[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by hour
  const incidentsByHour = {
    labels: totalNumberOfIncidentsByHour[1],
    datasets: [{
      // label: '# of Incidents',
      data: totalNumberOfIncidentsByHour[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }]
  }

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets

    // Child injuries
    if (props?.location?.state?.numIncidentsInvolvingInjuryToChild !== null) {
      const numIncidentsInvolvingInjuryToChild = props?.location?.state?.numIncidentsInvolvingInjuryToChild

      setNumIncidentsInvolvingInjuryToChild(numIncidentsInvolvingInjuryToChild)
    }

    // Staff injuries
    if (props?.location?.state?.numIncidentsInvolvingInjuryToStaff !== null) {
      const numIncidentsInvolvingInjuryToStaff = props?.location?.state?.numIncidentsInvolvingInjuryToStaff

      setNumIncidentsInvolvingInjuryToStaff(numIncidentsInvolvingInjuryToStaff)
    }

    // Other injuries
    if (props?.location?.state?.numIncidentsInvolvingInjuryToOthers !== null) {
      const numIncidentsInvolvingInjuryToOthers = props?.location?.state?.numIncidentsInvolvingInjuryToOthers

      setNumIncidentsInvolvingInjuryToOthers(numIncidentsInvolvingInjuryToOthers)
    }

    // Student Group
    // numIncidentsInvolvingInjuryByStudentGroup

    // Other Children
    // numIncidentsInvolvingInjuryWithOtherChildren

    // Date
    const totalNumberOfIncidentsByDate = props?.location?.state?.totalNumberOfIncidentsByDate

    if (totalNumberOfIncidentsByDate !== null) {
      const totalNumberOfIncidentsByDateLabels = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.month
      })

      const totalNumberOfIncidentsByDateData = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByDate([totalNumberOfIncidentsByDateData, totalNumberOfIncidentsByDateLabels])
    }

    // Time
    const totalNumberOfIncidentsByHour = props?.location?.state?.totalNumberOfIncidentsByHour

    if (totalNumberOfIncidentsByHour !== null) {
      const totalNumberOfIncidentsByHourLabels = totalNumberOfIncidentsByHour.map((data, index) => {
        return data.hour
      })

      const totalNumberOfIncidentsByHourData = totalNumberOfIncidentsByHour.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByHour([totalNumberOfIncidentsByHourData, totalNumberOfIncidentsByHourLabels])
    }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Medical Intervention Report</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>
            <Text size='xxlarge' weight={400} margin={{ top: 'none' }}>Search Criteria</Text>
            <Text>-Coming Soon-</Text>
          </Box>
        </Box>
      </Box>

      <Box direction='column' gap='small' round='small'>

        <Grid
          rows={['auto', 'auto', 'auto']}
          columns={['auto', 'auto', 'auto']}
          gap='medium'
          areas={[
            { name: 'main', start: [0, 0], end: [1, 1] }
          ]}
        >
          <SimpleCounter count={String(numIncidentsInvolvingInjuryToChild)} label='Incidents involving injury to child' />
          <SimpleCounter count={String(numIncidentsInvolvingInjuryToStaff)} label='Incidents involving injury to staff member' />
          <SimpleCounter count={String(numIncidentsInvolvingInjuryToOthers)} label='Incidents involving injury to other' />

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Student Group</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Doughnut
              data={data}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Other Children</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Doughnut
              data={data}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>
        </Grid>

        <Box
          background='white'
          direction='column'
          pad='small'
          round='small'
        >
          <Text size='medium' weight='bold'>Date</Text>
          <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

          <Bar
            data={incidentsByDate}
            height={25}
            legend={{ display: false }}
            // options={{ maintainAspectRatio: false }}
            width={50}
          />
        </Box>

        <Box
          background='white'
          direction='column'
          pad='small'
          round='small'
        >
          <Text size='medium' weight='bold'>Time</Text>
          <Text margin={{ bottom: 'small' }} size='small'>Average number of Incidents</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

          <Line
            data={incidentsByHour}
            height={25}
            legend={{ display: false }}
            // options={{ maintainAspectRatio: false }}
            width={50}
          />
        </Box>

        <Grid
          rows={['auto']}
          columns={['auto']}
          gap='medium'
          // areas={[
          //   { name: 'main', start: [0, 0], end: [1, 1] }
          // ]}
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Location</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Average # of staff</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Polar
              data={data}
              height={25}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

        </Grid>
      </Box>
    </Box>
  )
}

export default MedicalInterventionReport
