import OrganisationTagService from './OrganisationTagService'
import LocalStorageService from './LocalStorageService'

// const hideValues = false
const apiToken = LocalStorageService.get('apiToken')

class TermsService {
  getCommonTerms = async () => {
    const params = {
      fields: 'type,key,value'
    }

    const where = {
      type: 'besmart'
    }

    // Default term
    var serviceUserTerm = {
      value: 'service user'
    }

    var serviceUserTermPlural = {
      value: 'service users'
    }

    var serviceTerm = {
      value: 'service'
    }

    var serviceTermPlural = {
      value: 'services'
    }

    const organisationTags = await OrganisationTagService.get(apiToken, params, where)

    // Override terms if set
    if (organisationTags) {
      serviceUserTerm = organisationTags.find(tag => (tag.type === 'besmart' && tag.key === 'serviceUserTerm'))
      serviceUserTermPlural = organisationTags.find(tag => (tag.type === 'besmart' && tag.key === 'serviceUserTermPlural'))
      serviceTerm = organisationTags.find(tag => (tag.type === 'besmart' && tag.key === 'serviceTerm'))
      serviceTermPlural = organisationTags.find(tag => (tag.type === 'besmart' && tag.key === 'serviceTermPlural'))
    }

    var updatedTerms = {}
    if (serviceUserTerm?.value) {
      updatedTerms.serviceUser = serviceUserTerm.value
    }

    if (serviceUserTermPlural?.value) {
      updatedTerms.serviceUserPlural = serviceUserTermPlural.value
    }

    if (serviceTerm?.value) {
      updatedTerms.service = serviceTerm.value
    }

    if (serviceTermPlural?.value) {
      updatedTerms.serviceTerm = serviceTermPlural.value
    }

    return updatedTerms
  }
}
export default new TermsService()
