
import ApiService from './ApiService'

class UserTagService {
  set = async (apiToken, data) => {
    var url = '/api/usertag'

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserTagService:set ', result)
    return result
  }

  get = async (apiToken, params, where) => {
    var url = '/api/usertag'

    params.where = where

    try {
      var userTags = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserTagService:get ', userTags)
    return userTags?.data
  }
}
export default new UserTagService()
