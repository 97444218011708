import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Error handler
// import useErrorHandler from '../utils/useErrorHandler'
// import FlashMessage from '../utils/FlashMessage'

import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'
import RoleService from '../services/RoleService'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import Form from '../components/simple/form/Form.js'
import FormField from '../components/simple/formField/FormField.js'
import Text from '../components/simple/text/Text'
import TextInput from '../components/simple/input/TextInput'

function RoleEdit () {
  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const [roleDetails, setRoleDetails] = useState({
    title: '',
    permissions: []
  })

  // const { error, showError } = useErrorHandler(null)

  const params = useParams()

  const roleId = params.roleid

  // Submit form values
  const submitForm = async (values) => {
    const apiToken = LocalStorageService.get('apiToken')

    if (roleId === 'new') {
      const roleAdded = await RoleService.addRole(apiToken, values)
      if (roleAdded.error) {
        NotificationService.error('Error adding the role, please try again - ' + roleAdded.error)
      } else {
        NotificationService.info('Role added')
        console.log('Role created ', roleAdded)
      }
    } else {
      const roleUpdated = await RoleService.updateRole(apiToken, values)
      if (roleUpdated.error) {
        NotificationService.error('Error updating the role, please try again - ' + roleUpdated.error)
      } else {
        NotificationService.info('Role updated')
        console.log('Role updated ', roleUpdated)
      }
    }
  }

  const goBack = () => {
    history.goBack()
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get Role details
    const getRoleDetails = async (userId) => {
      const apiToken = LocalStorageService.get('apiToken')

      const roleDetails = await RoleService.getRole(apiToken, roleId)
      if (roleDetails.error) {
        NotificationService.error(roleDetails.error)
      } else {
        if (!unmounted) {
          setRoleDetails(roleDetails.data[0])
          console.log(roleDetails.data[0])
        }
      }
    }

    if (roleId !== 'new') {
      getRoleDetails(roleId)
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Role: {roleDetails.id ? roleDetails.title : 'New Role'}</Text>
          {/* {error &&
            <FlashMessage message={error} />} */}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              onChange={nextValue => {
                // console.log(nextValue)
                setRoleDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={roleDetails}
            >
              <FormField
                direction='row'
                label='Title'
                name='title'
                required
              >
                <TextInput
                  name='title'
                  type='text'
                  // value={roleDetails.data ? roleDetails.data[0].title : ''}
                />
              </FormField>

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RoleEdit
