import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Divider from '../../components/simple/divider/Divider.js'
import Grid from '../../components/simple/grid/Grid'
import Text from '../../components/simple/text/Text'

import { Bar, Line, Polar } from 'react-chartjs-2'

const IncidentReport = (props) => {
  const history = useHistory()

  const [totalNumberOfIncidentsByStudent, setTotalNumberOfIncidentsByStudent] = useState([])

  const [totalNumberOfIncidentsByYearGroup, setTotalNumberOfIncidentsByYearGroup] = useState([])

  const [totalNumberOfIncidentsByStudentGroup, setTotalNumberOfIncidentsByStudentGroup] = useState([])

  const [totalNumberOfIncidentsByDate, setTotalNumberOfIncidentsByDate] = useState([])

  const [totalNumberOfIncidentsByHour, setTotalNumberOfIncidentsByHour] = useState([])

  const [totalNumberOfIncidentsByLocation, setTotalNumberOfIncidentsByLocation] = useState([])

  const [totalNumberOfIncidentsBySystemUser, setTotalNumberOfIncidentsBySystemUser] = useState([])

  // Incidents by Student
  const studentData = {
    // From props.location.state.toalNumberOfIncidentsByStudent.name
    labels: totalNumberOfIncidentsByStudent[1],
    datasets: [{
      // label: '# of Votes',
      data: totalNumberOfIncidentsByStudent[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by Year Group
  const yeargroupData = {
    labels: totalNumberOfIncidentsByYearGroup[1],
    datasets: [{
      // label: '# of Votes',
      data: totalNumberOfIncidentsByYearGroup[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by Student Group
  const studentgroupData = {
    labels: totalNumberOfIncidentsByStudentGroup[1],
    datasets: [{
      // label: '# of Votes',
      data: totalNumberOfIncidentsByStudentGroup[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Location
  const locationData = {
    labels: totalNumberOfIncidentsByLocation[1],
    datasets: [{
      // label: '# of Votes',
      data: totalNumberOfIncidentsByLocation[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // User
  const userData = {
    labels: totalNumberOfIncidentsBySystemUser[1],
    datasets: [{
      // label: 'Users',
      data: totalNumberOfIncidentsBySystemUser[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by Date
  const incidentsByDate = {
    labels: totalNumberOfIncidentsByDate[1], // ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
    datasets: [{
      // label: '# of Incidents',
      data: totalNumberOfIncidentsByDate[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }]
  }

  // Incidents by hour
  const incidentsByHour = {
    labels: totalNumberOfIncidentsByHour[1], // ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16'],
    datasets: [{
      // label: '# of Incidents',
      data: totalNumberOfIncidentsByHour[0],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }]
  }

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets

    // Student
    const totalNumberOfIncidentsByStudent = props?.location?.state?.totalNumberOfIncidentsByStudent

    if (totalNumberOfIncidentsByStudent !== null) {
      const totalNumberOfIncidentsByStudentLabels = totalNumberOfIncidentsByStudent.map((data, index) => {
        return data.name
      })

      const totalNumberOfIncidentsByStudentData = totalNumberOfIncidentsByStudent.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByStudent([totalNumberOfIncidentsByStudentData, totalNumberOfIncidentsByStudentLabels])
    }

    // Year Group
    const totalNumberOfIncidentsByYearGroup = props?.location?.state?.totalNumberOfIncidentsByYearGroup

    if (totalNumberOfIncidentsByYearGroup !== null) {
      const totalNumberOfIncidentsByYearGroupLabels = totalNumberOfIncidentsByYearGroup.map((data, index) => {
        return data.name
      })

      const totalNumberOfIncidentsByYearGroupData = totalNumberOfIncidentsByYearGroup.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByYearGroup([totalNumberOfIncidentsByYearGroupData, totalNumberOfIncidentsByYearGroupLabels])
    }

    // Student Group
    const totalNumberOfIncidentsByStudentGroup = props?.location?.state?.totalNumberOfIncidentsByStudentGroup

    if (totalNumberOfIncidentsByStudentGroup !== null) {
      const totalNumberOfIncidentsByStudentGroupLabels = totalNumberOfIncidentsByStudentGroup.map((data, index) => {
        return data.name
      })

      const totalNumberOfIncidentsByStudentGroupData = totalNumberOfIncidentsByStudentGroup.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByStudentGroup([totalNumberOfIncidentsByStudentGroupData, totalNumberOfIncidentsByStudentGroupLabels])
    }

    // Date
    const totalNumberOfIncidentsByDate = props?.location?.state?.totalNumberOfIncidentsByDate

    if (totalNumberOfIncidentsByDate !== null) {
      const totalNumberOfIncidentsByDateLabels = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.month
      })

      const totalNumberOfIncidentsByDateData = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByDate([totalNumberOfIncidentsByDateData, totalNumberOfIncidentsByDateLabels])
    }

    // Time
    const totalNumberOfIncidentsByHour = props?.location?.state?.totalNumberOfIncidentsByHour

    if (totalNumberOfIncidentsByHour !== null) {
      const totalNumberOfIncidentsByHourLabels = totalNumberOfIncidentsByHour.map((data, index) => {
        return data.hour
      })

      const totalNumberOfIncidentsByHourData = totalNumberOfIncidentsByHour.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByHour([totalNumberOfIncidentsByHourData, totalNumberOfIncidentsByHourLabels])
    }

    // Location
    const totalNumberOfIncidentsByLocation = props?.location?.state?.totalNumberOfIncidentsByLocation

    if (totalNumberOfIncidentsByLocation !== null) {
      const totalNumberOfIncidentsByLocationLabels = totalNumberOfIncidentsByLocation.map((data, index) => {
        return data.response
      })

      const totalNumberOfIncidentsByLocationData = totalNumberOfIncidentsByLocation.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByLocation([totalNumberOfIncidentsByLocationData, totalNumberOfIncidentsByLocationLabels])
    }

    // User
    const totalNumberOfIncidentsBySystemUser = props?.location?.state?.totalNumberOfIncidentsBySystemUser

    if (totalNumberOfIncidentsBySystemUser !== null) {
      const totalNumberOfIncidentsBySystemUserLabels = totalNumberOfIncidentsBySystemUser.map((data, index) => {
        return data.name
      })

      const totalNumberOfIncidentsBySystemUserData = totalNumberOfIncidentsBySystemUser.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsBySystemUser([totalNumberOfIncidentsBySystemUserData, totalNumberOfIncidentsBySystemUserLabels])
    }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Global Incident Report</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>
            <Text size='xxlarge' weight={400} margin={{ top: 'none' }}>Search Criteria</Text>
            <Text>-Coming Soon-</Text>
          </Box>
        </Box>
      </Box> */}

      <Box direction='column' gap='small' round='small'>

        <Grid
          rows={['auto', 'auto', 'auto']}
          columns={['auto', 'auto', 'auto']}
          gap='medium'
          areas={[
            { name: 'main', start: [0, 0], end: [1, 1] }
          ]}
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Student</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Polar
              data={studentData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Year Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Polar
              data={yeargroupData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Student Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Polar
              data={studentgroupData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>
        </Grid>

        <Box
          background='white'
          direction='column'
          pad='small'
          round='small'
        >
          <Text size='medium' weight='bold'>Date</Text>
          <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

          <Bar
            data={incidentsByDate}
            height={25}
            legend={{ display: false }}
            // options={{ maintainAspectRatio: false }}
            width={50}
          />
        </Box>

        <Box
          background='white'
          direction='column'
          pad='small'
          round='small'
        >
          <Text size='medium' weight='bold'>Time</Text>
          <Text margin={{ bottom: 'small' }} size='small'>Average number of Incidents</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

          <Line
            data={incidentsByHour}
            height={25}
            legend={{ display: false }}
            // options={{ maintainAspectRatio: false }}
            width={50}
          />
        </Box>

        <Grid
          rows={['auto']}
          columns={['auto', 'auto']}
          gap='medium'
          // areas={[
          //   { name: 'main', start: [0, 0], end: [1, 1] }
          // ]}
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Location</Text>
            <Text margin={{ bottom: 'small' }} size='small'>All Locations</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Polar
              data={locationData}
              height={25}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>User</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Top Users</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Polar
              data={userData}
              height={25}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

        </Grid>
      </Box>
    </Box>
  )
}

export default IncidentReport
