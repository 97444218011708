// React
import React from 'react'
import PropTypes from 'prop-types'

// Bootstrap
// import Container from 'react-bootstrap/Container'
// import Form from 'react-bootstrap/Form'

// Appt
import Anchor from '../../simple/anchor/Anchor.js'
import Box from '../../simple/box/Box.js'
import Button from '../../simple/button/Button.js'
import Form from '../../simple/form/Form.js'
import FormField from '../../simple/formField/FormField.js'
import Image from '../../simple/image/Image.js'
import Text from '../../simple/text/Text.js'
import TextInput from '../../simple/input/TextInput.js'

/**
 * `PasswordResetRequest` renders a PasswordResetRequest screen
 */
class PasswordResetRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loginValues: {
        email: ''
      }
    }
  }

  render() {
    const handleBackClick = () => this.props.onBackClick()

    // const [value, setValue] = useState({ name: 'a', username: 'b' });

    const onChange = (nextValue) => {
      this.setState({ passwordResetValues: nextValue })
    }

    const onSubmit = () => {
      this.props.onResetPasswordClick(this.state.passwordResetValues)
    }

    return (
      <>
        <Box align='center' animation='fadeIn' alignSelf='center' width='medium'>

          <Box width='medium'>
            <Text color={this.props.labelColor} margin={{ top: 'medium', bottom: 'medium' }} textAlign='start' weight='bold' size='26px'>Reset Password</Text>
          </Box>

          <Box width='medium'>
            <Form
              value={this.state.passwordResetValues}
              onChange={(nextValue) => onChange(nextValue)}
              onSubmit={() => onSubmit()}
            >
              <FormField
                label={<Text size='medium' >Email</Text>}
                style={{ color: '#fff' }}
                name='email' required
              >
                <TextInput name='email' type='email' placeholder='Email address' />
              </FormField>



              {/* {message && (
                  <Box pad={{ horizontal: 'small' }}>
                    <Text color="status-error">{message}</Text>
                  </Box>
                )} */}

              <Button fill='horizontal' label='Send reset link' primary type='submit' />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Anchor color='white' onClick={() => handleBackClick()} size='xsmall' label='< Back to login' />
              </Box>
            </Form>
          </Box>
        </Box>
      </>)
  }
}

PasswordResetRequest.propTypes = {
  /**
   * The image at the top of the Login screen
   */
  image: PropTypes.string,
  /**
   * The function called on signing in
   */
  onResetPasswordClick: PropTypes.func,
  /**
   * The function called on clicking the Back link
   */
  onBackClick: PropTypes.func
}

export default PasswordResetRequest
