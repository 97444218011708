import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Appt
import Box from '../components/simple/box/Box.js'
import Text from '../components/simple/text/Text.js'

import Login from '../components/compound/login/Login.js'
import PasswordResetRequest from '../components/compound/login/PasswordResetRequest.js'
import UserVerification from '../components/compound/login/UserVerification.js'
import Image from '../components/simple/image/Image.js'

// Services
import ApiService from '../services/ApiService'
import NotificationService from '../services/NotificationService'
import LocalStorageService from '../services/LocalStorageService'
import PasswordService from '../services/PasswordService'

function LoginScreen (props) {
  const history = useHistory()

  const [viewLogin, setLoginView] = useState(true)
  const [verificationNeeded, setVerification] = useState(false)
  const [userValues, setUserValues] = useState()

  const signInClicked = async (values) => {
    setUserValues(values)
    var loginStatus = await ApiService.login(values.username, values.password)

    // stop if the login failed
    if (loginStatus.error) {
      NotificationService.error(loginStatus.error)
    } else {
      if (loginStatus.data?.verificationNeeded) {
        setVerification(true)
      } else if (!loginStatus.data?.userDetails?.isGlobalAdmin) {

        NotificationService.error('Access Denied - you do not have permission to access this resource.')
        LocalStorageService.clear()
        history.push('/login')


      } else {
        // debugger
        props.handleLogin(loginStatus.data)

        // if you're here, you're logged in
        history.push('/dashboard')
      }
    }
  }

  const resetPasswordClicked = async (values) => {
    // organisationId not needed for a reset password from the login screen
    const organisationId = 50
    try {
      var passwordResetStatus = await PasswordService.resetPasswordRequest(organisationId, values.email)
    } catch (err) {
      NotificationService.error(err)
    }

    NotificationService.info('A password reset has been requested. Please check your inbox.')

    console.log(passwordResetStatus)
    setLoginView(true)
  }

  const handleVerificationCode = async (code) => {
    const loginStatus = await ApiService.verifyAccount(userValues.username, userValues.password, code)

    // stop if the login failed
    if (loginStatus.error) {
      NotificationService.error(loginStatus.error)
    } else {
      // if you're here, you're logged in
      props.handleLogin(loginStatus.data)
      history.push('/dashboard')
    }
  }

  const viewLoginScreen = () => {
    setLoginView(true)
  }

  const viewPasswordForgotScreen = () => {
    setLoginView(false)
  }

  // Login screen parameters
  const title = {
    label: 'Backend Login',
    position: 'start'
  }

  const username = {
    label: 'Email',
    position: 'above'
  }

  const password = {
    label: 'Password',
    position: 'above'
  }

  const submit = {
    label: 'Next',
    size: 'small',
    position: 'end'
  }

  const forgotPassword = {
    label: 'Forgotten your password?',
    position: 'start'
  }

  return (
    <>
      <Box align='center' height='100%' justify='center' alignSelf='start'>
        <Box width='small' alignSelf='center' height='xsmall' margin={{ bottom: 'medium' }}>
          <Image fit='contain' src='/besmart-logo-white.png?r=001' />
        </Box>
        <Box border={{ color: 'brand', side: 'top' }}>
          {viewLogin && !verificationNeeded &&
            <>
              <Box pad={{ vertical: 'medium' }} width='medium'>
                <Login forgotPassword={forgotPassword} labelColor='#ffffff' onForgotPasswordClick={viewPasswordForgotScreen} onSignInClick={signInClicked} password={password} submit={submit} title={title} username={username} />
              </Box>
            </>}
          {!viewLogin && !verificationNeeded &&
            <Box fill justify='center' pad={{ vertical: 'medium' }} border={{ color: 'brand', side: 'bottom' }}>
              <PasswordResetRequest labelColor='#ffffff' onBackClick={viewLoginScreen} onResetPasswordClick={resetPasswordClicked} />
            </Box>}
          {verificationNeeded &&
            <Box fill justify='center' pad={{ vertical: 'medium' }} border={{ color: 'brand', side: 'bottom' }}>
              <UserVerification labelColor='#ffffff' onBackClick={viewLoginScreen} handleVerificationCode={handleVerificationCode} />
            </Box>}
        </Box>
      </Box>

    </>
  )
}

export default LoginScreen
