
// const hideValues = false

import ApiService from './ApiService'

class ContentService {
  getAllContent = async (apiToken, where) => {
    var url = '/api/media'

    var params = {
      fields: 'id,title,parent,type',
      limit: 1000
    }

    if (where) {
      params.where = where
    }

    try {
      var content = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('ContentService:getAllContent ', content)
    return content
  }

  getContent = async (apiToken, contentId) => {
    var url = `/api/media/${contentId}`

    var params = {
      fields: 'id,title,parent,type',
      limit: 1
    }

    try {
      var content = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('ContentService:getContent ', content)
    return content
  }

  getContentCount = async (apiToken, organisationId) => {
    var url = '/api/media'

    var params = {
      fields: 'id,organisation',
      limit: 1,
      where: { organisation: organisationId }
    }

    try {
      var content = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('ContentService:getContentCount ', content)

    return content.error ? 0 : content.page.total
  }

  getRecentlyCreatedContent = async (apiToken, organisationId) => {
    var url = '/api/media'

    var params = {
      fields: 'id,type,title',
      limit: 5,
      where: { organisation: organisationId }
    }

    try {
      var content = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('ContentService:getRecentlyCreatedContent ', content)
    return content.data
  }
}
export default new ContentService()
