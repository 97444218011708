
// const hideValues = false

import ApiService from './ApiService'

class FormService {
  getSubmissions = async (apiToken, params, where) => {
    var url = '/api/formsubmission'

    // var params = {
    //   fields: 'id,user,organisation,reference,type',
    //   limit: 1000
    // }

    if (where) {
      params.where = where
    }

    try {
      var submissions = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('FormService:getSubmissions ', submissions)
    return submissions
  }

  getResponses = async (apiToken, params, where) => {
    var url = '/api/formresponse'

    if (where) {
      params.where = where
    }

    // var params = {
    //   fields: 'id,user,organisation,submission,questionKey,questionText,response',
    //   limit: 1000
    // }

    try {
      var responses = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('FormService:getResponses ', responses)
    return responses
  }

  makeSubmissions = async (apiToken, data) => {
    var url = '/api/formsubmission'

    var params = {}

    var options = {
      data: data
    }

    try {
      var response = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('FormService:makeSubmissions ', response)

    return response
  }
}
export default new FormService()
