import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Grommet
// import { Download, FormEdit } from 'grommet-icons'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
// import DataTable from '../../components/simple/dataTable/DataTable.js'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider.js'
import Grid from '../../components/simple/grid/Grid'
import List from '../../components/simple/list/List.js'
import Text from '../../components/simple/text/Text'

// Services
// import FormService from '../../services/FormService'
// import LocalStorageService from '../../services/LocalStorageService'

import { Pie } from 'react-chartjs-2'

const InterventionReport = (props) => {
  const history = useHistory()

  // Demo Data
  const studentData = {
    labels: ['Fred Jones', 'Billy Bones', 'Flora Mitchell', 'Doris Dogood', 'Simon Smith', 'Ewan McTavish'],
    datasets: [{
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  const yeargroupData = {
    labels: ['Year 1', 'Year 8', 'Year 2', 'Year 3', 'Year 11', 'Year 13'],
    datasets: [{
      label: '# of Votes',
      data: [1, 19, 13, 15, 17, 8],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  const studentgroupData = {
    labels: ['Group 1', 'Group 2', 'Group 4', 'Group 18', 'Group 3'],
    datasets: [{
      label: '# of Votes',
      data: [13, 11, 6, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [{
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  }

  useEffect(() => {
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Global Intervention Report</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>
            <Text size='xxlarge' weight='400' margin={{ top: 'none' }}>Search Criteria</Text>
            <Text>-Coming Soon-</Text>
          </Box>
        </Box>
      </Box>

      <Box direction='column' gap='small' round='small'>

        <Grid
          rows={['auto', 'auto', 'auto']}
          columns={['auto', 'auto', 'auto']}
          gap='medium'
          areas={[
            { name: 'main', start: [0, 0], end: [1, 1] }
          ]}
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Most Common Reasons for Intervention</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
            <List
              border={false}
              pad={{ horizontal: 'none', vertical: 'xsmall' }}
              data={['1.Lorem Ipsum', '2. Lorem Ipsum']}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Most Frequent De-Escalation/Physical Technique</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
            <List
              pad={{horizontal: 'none', vertical:'xsmall'}}
              border={false}
              data={['1.Lorem Ipsum', '2. Lorem Ipsum', '3. Lorem Ipsum', '4. Lorem Ipsum']}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            height='xsmall'
            round='small'
          >
            <Text size='xxlarge' weight='bold'>4 Minutes </Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
            <Text size='small'>Avg. Duration of Physical Interventions</Text>
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Student</Text>
            <Text margin={{ bottom: 'small' }} size='small'>% of Incidents in which Breathing Monitored</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Pie
              data={studentData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Year Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>% of Incidents in which Breathing Monitored</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Pie
              data={yeargroupData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Student Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>% of Incidents in which Breathing Monitored</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            <Pie
              data={studentgroupData}
              height={50}
              legend={{ display: false }}
              // options={{ maintainAspectRatio: false }}
              width={50}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default InterventionReport
