// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Tabs as GTabs, Tab as GTab } from 'grommet'

/**
 * `Tabs` renders a Grommet Tabs
 */
class Tabs extends React.Component {
  render () {
    const tabContent = this.props.content

    const topTabs = []
    tabContent.forEach((item) => {
      if (item.hideTitle) {
        topTabs.push(
          <GTab key={item.name} title={false} plain>
            {item.content}
          </GTab>
        )
      } else {
        topTabs.push(
          <GTab key={item.name} title={item.name} disabled={item.disabled}>
            {item.content}
          </GTab>
        )
      }
    })

    return (
      <GTabs
        {...this.props}
      >
        {topTabs}
      </GTabs>)
  }
}

Tabs.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.
   *
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * Active tab index.
   * If specified, Tabs will be a controlled component.
   * This means that future tab changes will not work unless you subscribe to onActive function and update activeIndex accordingly.
   *
   * 0
   */
  activeIndex: PropTypes.number,
  /**
   * How to align the tab controls within the tabs header
   *
   *  "start"
   *  "end"
   *  "center"
   *  "stretch"
   */
  alignControls: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.
   *
   *  "start"
   *  "end"
   *  "center"
   *  "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * Array of Tab.
   *
   * node
   */
  children: PropTypes.array,
  /**
   * As children but a different method
   * Pass an array of objects containing the following
   *
   * {
   *   name: string - the Tab title
   *   content: node - the content of the Tab
   *   disabled: boolean - if the tab is disabled
   * }
   */
  content: PropTypes.array,
  /**
   * Whether flex-grow and/or flex-shrink is true.
   *
   * "grow"
   * "shrink"
   * true
   * false
   */
  flex: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  /**
   * The name of the area to place this inside a parent Grid.
   *
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * How to align the tabs along the main axis.
   *
   * "start"
   * **"center"**
   * "end"
   */
  justify: PropTypes.string,
  /**
   * The amount of margin around the component.
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.
   *
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * &nbsp;{
   * &nbsp;&nbsp;"vertical": "...",
   * &nbsp;&nbsp;"horizontal": "...",
   * &nbsp;&nbsp;"top": "...",
   * &nbsp;&nbsp;"bottom": "...",
   * &nbsp;&nbsp;"left": "...",
   * &nbsp;&nbsp;"right": "..."
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Custom messages for Tabs.
   * Used for accessibility by screen readers.
   *
   * {"tabContents": "Tab Contents"}
   */
  messages: PropTypes.object,
  /**
   * Function that will be called with the active tab index when the currenty active tab changes.
   *
   * (1) => {}
   */
  onActive: PropTypes.func
}

export default Tabs
