// React
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

import { Text, Button, DropButton, Nav, ResponsiveContext, Sidebar as GSidebar } from 'grommet'
import styled from 'styled-components'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Menu as MenuIcon
} from 'grommet-icons'

// Appt
import Box from '../../simple/box/Box.js'
import Divider from '../../simple/divider/Divider.js'
import Image from '../../simple/image/Image'
import Select from '../../simple/input/Select'

// Services
import LocalStorageService from '../../../services/LocalStorageService'
import { GlobalContext } from '../../../App.js'

/**
 * `Sidebar` renders a Sidebar
 */
function Sidebar(props) {
  const { menuState } = useContext(GlobalContext)
  const location = useLocation()

  const [orgValue, setOrgValue] = useState(props.selected)
  // Load this string from localStorage
  const lastActiveIndexString = LocalStorageService.get('lastActiveIndex')
  const apiToken = LocalStorageService.get('apiToken')

  // Parse it to a number
  const lastActiveIndex = Number(lastActiveIndexString)

  const [activeIndex, setActiveIndex] = useState(lastActiveIndex || 0)

  const [organisations, setOrganisations] = useState(props.selected)
  useEffect(() => {
    if (props.select && props.select.length) {
      var newOrgs = props.select
      newOrgs.sort((a, b) => { return a.name.localeCompare(b.name) })
      setOrganisations(newOrgs)
    }
  }, [props.select])

  const [menuList, setMenuList] = useState([])
  const SidebarItem = styled(Button)`
    padding: 0px 5px;
    transition: all 0.25s ease-in-out;
    color: #051449;
    font-size: 24px;
    letter-spacing: -1px;
    font-weight: ${(props) => (props.active ? '500' : '300')};
    background: none;
    //margin: 4px 12px;
    //border-radius: 4px;
    text-align: left;

    p {
      color: blue;
      font-weight: bold;
      text-decoration: none;
      margin: 4px;
    }

    &:focus {
      outline: none
    }

    &:hover {
      cursor:pointer;
      // font-weight: 500;
    }

    //&:hover:not(:first-child) {
      //background: #c34a36;
    //}
  `

  const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;

    &:hover:not(:first-child) {
      // background: #c34a36;
    }
  `

  const selectChanged = (nextValue) => {
    setOrgValue(nextValue)
    props.selectActive(nextValue)
  }

  const SidebarHeader = () => (
    <>
      <Box margin={{ top: 'small', bottom: 'medium' }}>
        <Box height='100px' pad={{ horizontal: 'none', top: 'small', bottom: 'none' }}>
          <Image fit='contain' src={props.image} />
        </Box>
      </Box>
      <Divider color={props.dividerColor} margin={{ top: 'small', bottom: 'small' }} />

      {/* Only display the org selector if the user has more than one org */}
      {organisations && organisations.length > 1 &&
        <Select
          id='select'
          name='select'
          labelKey='name'
          placeholder='Select'
          value={orgValue}
          valueKey={{ key: 'id', reduce: true }}
          options={organisations}
          onChange={({ value: nextValue }) => selectChanged(nextValue)}
        // onChange={({ option }) => setValue(option)}
        />}
    </>
  )

  const SidebarFooter = () => (
    <Box pad={{ bottom: 'small' }}>
      <Divider color={props.dividerColor} margin={{ top: 'small', bottom: 'small' }} />
      <StyledLink key='logout' to='/logout'>
        <Text color='secondary' weight={600} margin={{ left: '10px', bottom: '20px' }}>Logout</Text>
      </StyledLink>
    </Box>
  )

  const MainNavigation = () => (
    <ResponsiveContext.Consumer>
      {responsive =>
        responsive === 'small' ? (
          // <Button icon={<MenuIcon />} hoverIndicator onClick={() => alert('comingSoon')} />
          <Box height='xxsmall'>
            <DropButton
              label={<MenuIcon />}
              dropAlign={{ top: 'bottom', right: 'right' }}
              dropContent={menuList}
            />
          </Box>
        ) : (
          <Nav gap='small' responsive={false}>
            {menuState.map((item, index) => {
              const isActive = index === activeIndex
              const icon = (
                <Box background={isActive ? 'secondary' : 'focus'} width='35px' height='35px' round='large' gap='none' align='center' pad={{ top: '6px' }}>
                  <FontAwesomeIcon icon={['fal', item.icon]} color='#fff' />
                </Box>
              )

              return (
                <StyledLink key={item.linkTitle} to={item.linkHref}>
                  <SidebarItem
                    alignSelf='start'
                    gap='small'
                    label={item.linkTitle}
                    plain
                    active={isActive}
                    icon={icon}
                    key={item.linkTitle}
                  />
                </StyledLink>
              )
            })}
          </Nav>
        )}
    </ResponsiveContext.Consumer>
  )

  const changeActiveIndex = (newIndex) => {
    LocalStorageService.set('lastActiveIndex', newIndex)
    setActiveIndex(newIndex)
    // setOrgValue(newIndex)
  }

  const getPath = (path) => {
    if (path.charAt(0) !== '/') {
      return '/' + path
    }
    return path
  }

  // Builds menu list for the
  // dropdown on small screens
  useEffect(() => {
    const menuItems = []
    menuState.map((item, index) => {
      const isActive = index === activeIndex
      const icon = (
        <Box background={isActive ? 'secondary' : 'focus'} width='35px' height='35px' round='large' gap='none' align='center' pad={{ top: '6px' }}>
          <FontAwesomeIcon icon={['fal', item.icon]} color='#fff' />
        </Box>
      )

      menuItems.push(
        <Box pad='medium' background='white'>
          <StyledLink key={item.linkTitle} to={item.linkHref}>
            <SidebarItem
              alignSelf='start'
              gap='small'
              label={item.linkTitle}
              plain
              active={isActive}
              icon={icon}
              key={item.linkTitle}
            />
          </StyledLink>
        </Box>
      )
    })

    setMenuList(menuItems)
  }, [menuState])

  useEffect(() => {
    const activeItem = menuState.findIndex(item => getPath(item.linkHref) === getPath(location.pathname))
    changeActiveIndex(activeItem)
  }, [location])

  // hide the sidebar on certain screens
  const pathsToHideSidebarOn = [
    '/user/activate',
    '/login'
  ]
  const sidebarVisible = !pathsToHideSidebarOn.includes(window.location.pathname)

  return apiToken && sidebarVisible ? (
    <GSidebar
      alignSelf='center'
      gap='medium'
      header={
        <SidebarHeader />
      }
      footer={
        <SidebarFooter />
      }
      {...props}
    >
      <MainNavigation />
    </GSidebar>) : null
}

Sidebar.propTypes = {
  /**
   * Either a color identifier to use for the background Boxcolor.
   * For example: 'neutral-1'. Or, a 'url()' for an image. Dark is not needed if color is provided.
   *
   * "neutral-1"
   * "url(//my.com/assets/img.png)"
   * {
   * &nbsp;"color": "neutral-1",
   * &nbsp;"dark": true,
   * &nbsp;"opacity": true,
   * &nbsp;"position": "bottom",
   * &nbsp;"repeat": "no-repeat",
   * &nbsp;"size": "cover",
   * &nbsp;"image": "url(//my.com/assets/img.png)"
   * }
   * {"dark": "light-2", "light": "dark-2"}
   *
   * where opacity could be:
   *  &nbsp;"weak"
   *  &nbsp;"medium"
   *  &nbsp;"strong"
   *  &nbsp;true
   *
   * where position could be:
   *  &nbsp;any CSS for background-position
   *
   * where repeat could be:
   *  &nbsp;"no-repeat"
   *  &nbsp;"repeat"
   *  &nbsp;"string"
   *
   * where size could be:
   *  &nbsp;"cover"
   *  &nbsp;"contain"
   *  &nbsp;"string"
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Array of data objects.
   *
   * [
   * &nbsp;{
   * &nbsp;&nbsp;linkHref: "#"
   * &nbsp;&nbsp;linkColor: "dark-1"
   * &nbsp;&nbsp;linkTitle: "My Link"
   * &nbsp;}
   * ]
   */
  data: PropTypes.array,
  /**
   * Color of the divider line
   */
  dividerColor: PropTypes.string,
  /**
   * Elevated height above the underlying context for the backgorund Box, indicated via a drop shadow.
   *
   * **"none"**
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * "any custom elevation name in the current theme"
   */
  elevation: PropTypes.string,
  /**
   * The image at the top of the Login screen
   */
  image: PropTypes.string
}

export default Sidebar
