import React, { useState } from 'react'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

import styled from 'styled-components'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DateInput from '../../components/simple/input/DateInput'
import DateTime from '../../components/compound/dateTime/DateTime'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import OptionButtons from '../../components/compound/optionButtons/OptionButtons'
import RadioButtonGroup from '../../components/simple/input/RadioButtonGroup'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Select from '../../components/simple/input/Select'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'

function IncidentReport (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)

  const TallTextArea = styled(TextArea)`
    min-height: 100%;
  `

  const submitForm = () => {
    props.submitForm()
  }

  const setDateTime = (date, time) => {
    props.setIncidentValues(incidentValues => ({
      ...incidentValues,
      dateOfIncident: date,
      timeOfIncident: time
    }))
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Incident Report</H2>
          <Button label={<FontAwesome color='brand' icon={['fas', 'question-circle']} />} plain tip='This is the first page of the Incident Report' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {props.studentValues && questions.length
          ? (
            <Form
              //   validate='blur'
              onChange={nextValue => {
                props.setIncidentValues(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={props.incidentValues}
            >
              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['auto', 'auto', 'auto', 'auto'],
                  large: ['auto', 'auto', 'auto', 'auto'],
                  xlarge: ['auto', 'auto', 'auto', 'auto']
                }}
                gap='small'
              >
                {/* Student Name */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  required
                >
                  <Select
                    // id='student'
                    disabled={props.userId !== 'new'}
                    name={questions[0].key}
                    labelKey='name'
                    placeholder='Student'
                    options={props.studentValues}
                    // valueKey={{ key: 'id', reduce: false }}
                    valueKey='id'
                  />
                </FormField>

                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                  required
                >
                  <DateTime
                    date={props.incidentValues.dateOfIncident}
                    time={props.incidentValues.timeOfIncident}
                    // name={questions[1].key}
                    setDateTime={setDateTime}
                  />
                </FormField>
                {/* Date of Incident */}
                {/* <FormField
                  label={questions[2].questionText}
                  name={questions[2].key}
                  required
                >
                  <TextInput
                    name={questions[2].key}
                    type='time'
                  />
                </FormField> */}
                {/* Time of Incident */}
                {/* <FormField
                  label={questions[2].questionText}
                  name={questions[2].key}
                  required
                >
                  <TextInput
                    name={questions[2].key}
                    type='time'
                  />
                </FormField> */}

                {/* Location */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                  required
                >
                  <TextInput
                    name={questions[3].key}
                    placeholder='Location'
                    type='text'
                  />
                </FormField>
              </ResponsiveGrid>

              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['auto', 'auto', 'auto', 'auto'],
                  large: ['auto', 'auto', 'auto', 'auto'],
                  xlarge: ['auto', 'auto', 'auto', 'auto']
                }}
                gap='small'
              >
                {/* Completed By */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                  required
                >
                  <Select
                    // id='student'
                    name={questions[4].key}
                    labelKey='name'
                    placeholder='Completed by'
                    // onChange={({ option }) => changeOrganisation(option)}
                    options={props.userValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Staff Involved */}
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                  required
                >
                  <Select
                    // id='student'
                    name={questions[5].key}
                    labelKey='name'
                    multiple
                    placeholder='Staff Involved'
                    // onChange={({ option }) => changeOrganisation(option)}
                    options={props.userValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Students Involved */}
                <FormField
                  label={questions[6].questionText}
                  name={questions[6].key}
                  required
                >
                  <Select
                    // id='student'
                    name={questions[6].key}
                    labelKey='name'
                    multiple
                    placeholder='Students involved'
                    // onChange={({ option }) => changeOrganisation(option)}
                    options={props.studentValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Witnesses */}
                <FormField
                  label={questions[7].questionText}
                  name={questions[7].key}
                  required
                >
                  <TextInput
                    name={questions[7].key}
                    placeholder='Witnesses'
                    type='text'
                  />
                </FormField>

                <Box direction='column'>
                  {/* Hurting Self */}
                  <FormField
                    label={questions[8].questionText}
                    name={questions[8].key}
                  >
                    <OptionButtons
                      id={questions[8].key}
                      name={questions[8].key}
                    />
                  </FormField>

                  {/* Hurting Others */}
                  <FormField
                    label={questions[9].questionText}
                    name={questions[9].key}
                  >
                    <OptionButtons
                      id={questions[9].key}
                      name={questions[9].key}
                    />
                  </FormField>

                  {/* Committing an offence */}
                  <FormField
                    label={questions[10].questionText}
                    name={questions[10].key}
                  >
                    <OptionButtons
                      id={questions[10].key}
                      name={questions[10].key}
                    />
                  </FormField>
                </Box>

                <Box direction='column'>
                  {/* Damage to property */}
                  <FormField
                    label={questions[11].questionText}
                    name={questions[11].key}
                  >
                    <OptionButtons
                      id={questions[11].key}
                      name={questions[11].key}
                    />
                  </FormField>

                  {/* Behaviour we are trying to support */}
                  <FormField
                    label={questions[12].questionText}
                    name={questions[12].key}
                  >
                    <OptionButtons
                      id={questions[12].key}
                      name={questions[12].key}
                    />
                  </FormField>
                </Box>

                <Box direction='column' fill='vertical'>
                  {/* Other */}
                  <FormField
                    label={questions[13].questionText}
                    name={questions[13].key}
                  >
                    <OptionButtons
                      id={questions[13].key}
                      name={questions[13].key}
                    />
                  </FormField>

                  {props.incidentValues.otherQuestion === 'Yes' &&
                    // Other - Please Elaborate
                    <FormField
                      label={questions[14].questionText}
                      name={questions[14].key}
                    >
                      <TallTextArea
                        name={questions[14].key}
                        fill
                      />
                    </FormField>}
                </Box>

                <Box direction='column'>
                  {/* Was your action in the best interest of the service user? */}
                  <FormField
                    label={questions[15].questionText}
                    name={questions[15].key}
                  >
                    <OptionButtons
                      id={questions[15].key}
                      name={questions[15].key}
                    />
                  </FormField>

                  {/* Why was this [not] in the best interest of the service user? */}
                  <FormField
                    label={`Why was this ${props.incidentValues.bestInterestQuestion === 'No' ? 'not' : ''}  in the best interest of the service user?`}
                    name={questions[16].key}
                  >
                    <TextArea
                      name={questions[16].key}
                    />
                  </FormField>
                </Box>
              </ResponsiveGrid>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default IncidentReport
