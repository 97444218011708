import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import { capitalize } from '../../utils/text'
import moment from 'moment'

import { constants } from '../../utils/constants'

import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationTagService from '../../services/OrganisationTagService'
import UserService from '../../services/UserService'
import UserGroupService from '../../services/UserGroupService'
import UserTagService from '../../services/UserTagService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import DateInput from '../../components/simple/input/DateInput'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import List from '../../components/simple/list/List.js'
import Select from '../../components/simple/input/Select'
import Tabs from '../../components/simple/tabs/Tabs.js'
import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function ServiceUserEdit (props) {
  const history = useHistory()

  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    reference: '',
    dob: '',
    ethnicity: '',
    gender: ''
  })

  const [groupDetails, setGroupDetails] = useState({
    groupName: '',
    academicYear: ''
  })
  const [currentGroups, setCurrentGroups] = useState([])

  const [mappedContacts, setMappedContacts] = useState([])
  const [contactColumns, setContactDataColumns] = useState()

  const [contactDetails, setContactDetails] = useState({
    firstName: '',
    lastName: '',
    role: '',
    email: ''
  })
  const [contacts, setContacts] = useState([])
  const [contactAddButton, setContactAddButton] = useState(false)
  const [allAcademicYears, setAllAcademicYears] = useState([])
  const [academicYearSelected, setAcademicYear] = useState()

  const [latestBehaviourPDF, setLatestBehaviourPDF] = useState('')

  // Disable tabs other than the first if creating new user
  // Enable after the first tab has been completed
  const [secondaryTabsDisabled, setSecondaryTabsDisabled] = useState(true)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const addContact = () => {
    const contact = {
      firstName: contactDetails.firstName,
      lastName: contactDetails.lastName,
      role: contactDetails.role,
      email: contactDetails.email
    }
    setContacts(contacts => [...contacts, contact])

    // Re-initialise input fields
    setContactDetails({
      firstName: '',
      lastName: '',
      role: '',
      email: ''
    })
    setContactAddButton(false)
  }

  const removeContact = (data) => {
    const newContacts = contacts.filter((item) => item.email !== data.email)

    setContacts(newContacts)
  }

  const params = useParams()

  let userId = params.userid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    if (nextIndex === 1) {
      getGroups()
    }
    setTabIndex(nextIndex)
  }

  const tabContent = [
    {
      name: `${capitalize(props?.terms?.serviceUser || 'Service User')} Details`,
      content: (
        <Form
          onChange={nextValue => {
            setUserDetails(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            if (nextValue.dob === '') {
              nextValue.dob = undefined
            }
            // debugger
            submitUserDetails(nextValue)
          }}
          value={userDetails}
        >
          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Box direction='row-responsive' gap='medium'>
            <FormField
              label='First Name'
              name='firstName'
              required
            >
              <TextInput
                name='firstName'
                type='text'
              />
            </FormField>

            <FormField
              label='Last Name'
              name='lastName'
              required
            >
              <TextInput
                name='lastName'
                type='text'
              />
            </FormField>
          </Box>

          <Box direction='row-responsive' gap='medium'>
            <FormField
              label='Learner Number (if applicable)'
              name='reference'
            >
              <TextInput
                name='reference'
                type='text'
              />
            </FormField>

            <FormField
              label='Date of Birth'
              name='dob'
            >
              <DateInput
                buttonProps={{ backgroundColor: 'white' }}
                // value={(new Date()).toISOString()}
                // onChange={(new Date()).toISOString()}
                format='dd/mm/yyyy'
                name='dob'
              />

            </FormField>
          </Box>

          <Box direction='row-responsive' gap='medium'>
            <FormField
              label='Ethnicity'
              name='ethnicity'
            >
              <Select
                name='ethnicity'
                labelKey='label'
                options={constants.ethnicity}
                valueKey={{ key: 'value', reduce: true }}
              />
              {/* <TextInput
                name='ethnicity'
                type='text'
              /> */}
            </FormField>

            <FormField
              label='Gender'
              name='gender'
            >
              <Select
                name='gender'
                labelKey='label'
                options={constants.gender}
                valueKey={{ key: 'value', reduce: true }}
              />
              {/* <TextInput
                name='gender'
                type='text'
              /> */}
            </FormField>
          </Box>
          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => goBack()} secondary />
            <Button type='submit' label='Save' primary />
          </Box>
        </Form>)
    },
    {
      name: 'Groups',
      content: (
        <Form
          onChange={nextValue => {
            setGroupDetails(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitGroup(nextValue)
          }}
          value={groupDetails}
        >
          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />
          <Text>Year Group</Text>
          <FormField
            label='Year Group'
            name='academicYear'
          >
            <Select
              name='academicYear'
              emptySearchMessage='No academic years configured in service setup'
              onChange={
                (event, option) => { setAcademicYear(event.value) }
              }
              options={allAcademicYears}
            />
          </FormField>

          <Divider color='#eee' margin={{ top: 'medium', bottom: 'medium' }} />

          <Text>Student Groups</Text>
          <Box direction='row' gap='medium'>
            <FormField
              label='Add to Group'
              name='groupName'
              required
            >
              <TextInput
                name='groupName'
                type='text'
              />
            </FormField>
            <Button type='submit' label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add</Text>} style={{ height: '45px', background: '#333' }} alignSelf='end' margin={{ bottom: 'small' }} />
          </Box>

          <Box direction='column' gap='small'>
            {currentGroups.length
              ? <List border={false} data={currentGroups} primaryKey='name' />
              : null}
          </Box>
          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => goBack()} secondary />
            <Button onClick={() => addAcademicYear(userId, academicYearSelected)} label='Save' primary />
          </Box>
        </Form>),
      disabled: secondaryTabsDisabled
    },
    {
      name: 'Key Contacts',
      content: (
        <Form
          onChange={nextValue => {
            // Enable Contact Add button if all fields have data
            setContactAddButton(nextValue.firstName !== '' && nextValue.lastName !== '' && nextValue.role !== '' && nextValue.email !== '')
            setContactDetails(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitContacts(nextValue)
          }}
          value={contactDetails}
        >
          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          {mappedContacts.length > 0 &&
            <Box direction='row-responsive' gap='medium'>
              <DataTable
                columns={contactColumns}
                data={mappedContacts}
                fill='horizontal'
                size='large'
              />
            </Box>}
          <Box direction='row-responsive' gap='medium'>
            <FormField
              label='First Name'
              name='firstName'
            >
              <TextInput
                name='firstName'
                type='text'
              />
            </FormField>

            <FormField
              label='Last Name'
              name='lastName'
            >
              <TextInput
                name='lastName'
                type='text'
              />
            </FormField>

            <FormField
              label='Role'
              name='role'
            >
              <TextInput
                name='role'
                type='text'
              />
            </FormField>

            <FormField
              label='Email'
              name='email'
            >
              <TextInput
                name='email'
                type='email'
              />
            </FormField>

            <Button disabled={!contactAddButton} label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /></Text>} color='primary' onClick={() => addContact()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
          </Box>

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => goBack()} secondary />
            <Button type='submit' label='Save' primary />
          </Box>
        </Form>),
      disabled: secondaryTabsDisabled
    }
  ]
  // const goNextTab = () => {
  //   setTabIndex(tabIndex + 1)
  // }

  // const goPreviousTab = () => {
  //   setTabIndex(tabIndex - 1)
  // }

  const getGroups = async () => {
    if (userId !== 'new') {
      const params = {
        fields: 'id,name,description,owner,organisation'
      }

      const where = {
        organisation: activeOrg.id,
        owner: userId
      }

      const groups = await UserGroupService.getGroups(apiToken, params, where)

      if (groups?.data) {
        const mappedGroups = groups.data.map(item => ({
          name: <Text>{item.name}</Text>
        }))

        setCurrentGroups(mappedGroups)
      }
    }
  }

  // Submit User Details
  const submitUserDetails = async (values) => {
    console.log('submitForm ', values)

    if (userId === 'new') {
      const data = {
        data: {
          reference: values.reference,
          dob: moment(values.dob).format('YYYY-MM-DD'),
          firstName: values.firstName,
          lastName: values.lastName,
          ethnicity: values.ethnicity,
          gender: values.gender,
          type: 'student'
        },
        organisation: activeOrg.id
      }
      var userAdded = await UserService.createUser(apiToken, data)
      if (userAdded.error) {
        NotificationService.error(userAdded.error)
      } else {
        NotificationService.success(capitalize(props?.terms?.serviceUser || 'Service User') + ' created. Please add Groups and Key Contacts.')
        setSecondaryTabsDisabled(false)

        userId = userAdded.data.id[0]
        // history.push('/serviceusers')
      }
    } else {
      const data = {
        id: values.id,
        reference: values.reference,
        dob: moment(values.dob).format('YYYY-MM-DD'),
        firstName: values.firstName,
        lastName: values.lastName,
        ethnicity: values.ethnicity,
        gender: values.gender
      }

      data.id = userId

      var userUpdated = await UserService.updateUser(apiToken, data, 'student')
      if (!userUpdated || !userUpdated.error) {
        // NotificationService.error(userUpdated.error)
      } else {
        NotificationService.success('Success')
      }
    }
  }

  // Add the academic year to user tags
  const addAcademicYear = async (userId, year) => {
    const data = [
      {
        key: 'academicYear',
        organisation: activeOrg.id,
        type: 'besmart',
        user: userId,
        value: year
      }
    ]

    const tagSet = await UserTagService.set(apiToken, data)
    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    } else {
      NotificationService.success('Year Group updated')
    }
  }

  // Submit Group Details
  const submitGroup = async (values) => {
    console.log('submitGroup ', values)

    const data = {
      data: {
        name: values.groupName,
        organisation: activeOrg.id
      }
    }
    var groupAdded = await UserGroupService.createGroup(apiToken, data)
    if (groupAdded.error) {
      NotificationService.error(groupAdded.error)
    } else {
      NotificationService.success('Group added')
      getGroups()

      // addAcademicYear(userId, values.academicYear)
    }
  }

  const getContacts = async () => {
    const params = {
      fields: 'user,type,key,organisation,value'
    }

    const where = {
      user: userId
    }

    let contacts = await UserTagService.get(apiToken, params, where)

    // Filter out the 'keycontacts'
    if (contacts) {
      contacts = contacts.filter((item) => item.key === 'keycontacts')

      console.log('saved contacts ', contacts)

      setContacts(JSON.parse(contacts[0].value))
    }
  }

  // Submit Contact Details
  const submitContacts = async (values) => {
    console.log('submitContact ', values)

    const data = [
      {
        key: 'keycontacts',
        organisation: activeOrg.id,
        type: 'keycontacts',
        user: userId,
        value: JSON.stringify(contacts)
      }
    ]

    console.log(data)

    const tagSet = await UserTagService.set(apiToken, data)
    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    } else {
      NotificationService.success('Key Contacts added')
      getContacts()
    }
  }

  const recordIncident = (userId) => {
    history.push(`/incident/${userId}/edit`)
  }

  const recordSmartPlan = (userId) => {
    history.push(`/behaviour/${userId}/edit`)
  }

  const goBack = () => {
    history.goBack()
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Check if adding a new User or editing an existing one
    const getUserDetails = async (userId) => {
      let params = {
        fields: 'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
        limit: 1,
        orgId: activeOrg.id,
        type: 'student'
      }

      const userDetails = await UserService.getUser(apiToken, params, userId)

      if (!unmounted) {
        setUserDetails(prevState => ({
          ...prevState,
          id: userDetails.data[0].id,
          reference: userDetails.data[0].reference,
          firstName: userDetails.data[0].firstName,
          lastName: userDetails.data[0].lastName,
          dob: moment(userDetails.data[0].dob).format('YYYY-MM-DD'),
          ethnicity: userDetails.data[0].ethnicity || '',
          gender: userDetails.data[0].gender
        }))

        // Now get the most recent Behaviour Plan PDF
        params = {
          fields: 'id,pdfUrl',
          limit: 1,
          orgId: activeOrg.id,
          sort: 'id DESC'
        }

        const where = {
          status: 'Published',
          type: 'incidentreport',
          user: userDetails.data[0].id
        }

        const submission = await FormService.getSubmissions(apiToken, params, where)

        if (submission?.data?.[0]?.pdfUrl) {
          setLatestBehaviourPDF(submission.data[0].pdfUrl)
        }
      }
    }

    // Get enabled academic year for organisation
    const getAcademicYears = async () => {
      const params = {
        fields: 'key,value',
        limit: 100
      }

      const where = {
        organisation: activeOrg.id,
        type: 'besmart'
      }

      const organisationTags = await OrganisationTagService.get(apiToken, params, where)
      if (organisationTags?.error) {
      // NotificationService.error(organisationTags.error)
      } else if (organisationTags?.length) {
        const academicYears = organisationTags.find(tag => tag.key === 'enabledAcademicYears')
        try {
          const parsedAcademicYears = JSON.parse(academicYears.value)
          parsedAcademicYears.push('Former Student')
          setAllAcademicYears(parsedAcademicYears)
        } catch (err) {
          console.error(err)
        }
      }
    }

    // Get selected academic year for this user
    const getAcademicYear = async () => {
      if (userId === 'new') {
        setGroupDetails(groupDetails => ({
          ...groupDetails,
          academicYear: 'Former Student'
        }))
      } else {
        const params = {
          fields: 'key,value'
        }

        const where = {
          organisation: activeOrg.id,
          type: 'besmart',
          user: userId
        }

        const userTags = await UserTagService.get(apiToken, params, where)

        if (userTags) {
          const academicYear = userTags.find(tag => tag.key === 'academicYear')
          setGroupDetails(groupDetails => ({
            ...groupDetails,
            academicYear: academicYear.value
          }))
        } else {
          setGroupDetails(groupDetails => ({
            ...groupDetails,
            academicYear: 'Former Student'
          }))
        }
      }
    }

    getAcademicYears()

    if (userId !== 'new') {
      getUserDetails(userId)
      getContacts()

      setSecondaryTabsDisabled(false)
    }

    getAcademicYear()

    return () => { unmounted = true }
  }, [])

  // Contacts have been altered
  // so rebuild the display list
  useEffect(() => {
    setContactDataColumns([
      {
        property: 'firstName',
        header: <Text pad='none' margin='none'>First Name</Text>
      },
      {
        property: 'lastName',
        header: <Text>Last Name</Text>
      },
      {
        property: 'role',
        header: <Text>Role</Text>
      },
      {
        property: 'email',
        header: <Text>Email</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeContact(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedContacts = contacts.map((contact, index) => ({
      reference: contact.reference,
      dob: contact.dob,
      firstName: contact.firstName,
      lastName: contact.lastName,
      role: contact.role,
      email: contact.email
    }))

    setMappedContacts(mappedContacts)
  }, [contacts])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        {capitalize(props?.terms?.serviceUser || 'Service User')}: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}
      </BreadcrumbBar>

      {userDetails.id &&
        <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge'>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row-responsive' gap='small'>
              <Button label={<Text><FontAwesome icon={['fal', 'plus-circle']} /> Record An Incident</Text>} margin={{ horizontal: 'xsmall' }} onClick={() => recordIncident(userDetails.id)} target='_self' />
              <Button label={<Text><FontAwesome icon={['fal', 'file-alt']} /> Update Smart Plan</Text>} margin={{ horizontal: 'xsmall' }} onClick={() => recordSmartPlan(userDetails.id)} target='_self' />
              {latestBehaviourPDF && <Button href={latestBehaviourPDF} label={<Text><FontAwesome icon={['fal', 'download']} /> Download Smart Plan</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />}
            </Box>
          </Box>
        </Box>}

      <Box background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='medium'
        >
          <Box direction='column' gap='medium'>
            <Tabs activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ServiceUserEdit
