// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box'
import Button from '../../simple/button/Button'
import Text from '../../simple/text/Text'

import { Calendar, DropButton, Keyboard, MaskedInput } from 'grommet'

import { Schedule } from 'grommet-icons'

import moment from 'moment'

/**
 * `DateTime` renders a Date and Time selector
 */

const DropContent = ({ date: initialDate, time: initialTime, onClose }) => {
  const [date, setDate] = useState()
  const [time, setTime] = useState()

  const close = () => onClose(date || initialDate, time || initialTime)

  return (
    <Box align='center' background='white'>
      <Calendar
        animate={false}
        date={date || initialDate}
        onSelect={setDate}
        showAdjacentDays={false}
        // size='small'
      />
      <Box flex={false} pad='medium' gap='medium'>
        <Keyboard
          onEnter={event => {
            event.preventDefault() // so drop doesn't re-open
            close()
          }}
        >
          <MaskedInput
            mask={[
              {
                length: [1, 2],
                options: [
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23'
                ],
                regexp: /^1[1-2]$|^[0-9]$/,
                placeholder: 'hh'
              },
              { fixed: ':' },
              {
                length: 2,
                options: ['00', '15', '30', '45'],
                regexp: /^[0-5][0-9]$|^[0-9]$/,
                placeholder: 'mm'
              },
              // { fixed: ' ' },
              // {
              //   length: 2,
              //   options: ['am', 'pm'],
              //   regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
              //   placeholder: 'am/pm'
              // }
            ]}
            value={time || initialTime}
            name='maskedInput'
            onChange={event => setTime(event.target.value)}
          />
        </Keyboard>
        <Box flex={false}>
          <Button label='Done' onClick={close} />
        </Box>
      </Box>
    </Box>
  )
}

const DateTime = (props) => {
  const [date, setDate] = useState()
  const [time, setTime] = useState('')
  const [open, setOpen] = useState()

  const onClose = (nextDate, nextTime) => {
    setDate(nextDate)
    setTime(nextTime)
    setOpen(false)
    setTimeout(() => setOpen(undefined), 1)
    props.setDateTime(moment(nextDate).format('YYYY-MM-DD'), nextTime)
  }

  return (
    <Box align='start' pad='0px'>
      <DropButton
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        dropContent={
          <DropContent date={props.date} time={props.time} onClose={onClose} />
        }
      >
        <Box border='all' direction='row' gap='medium' align='center' pad={{ horizontal: '11px', vertical: '5px' }}>
          <Text color={props.date ? '#444' : 'dark-5'}>
            {props.date
              ? `${new Date(props.date).toLocaleDateString()} ${props.time}`
              : 'Select date & time'}
          </Text>
          <Schedule />
        </Box>
      </DropButton>
    </Box>
  )
}

DateTime.propTypes = {
  /**
   * Position of the Layer
   *
   * "bottom"
   * "bottom-left"
   * "bottom-right"
   * **"center"**
   * "end"
   * "hidden"
   * "left"
   * "right"
   * "start"
   * "top"
   * "top-left"
   * "top-right"

   */
  position: PropTypes.string,
  /**
   * Notification status - determines the colour of the layer
   *
   * "error"
   * **"ok"**
   * "warning"
   */
  status: PropTypes.string
}

export default DateTime
