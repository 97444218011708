import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Grommet
// import { Grommet } from 'grommet'

// Appt
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box.js'
import Button from '../../components/simple/button/Button.js'
import CheckBox from '../../components/simple/input/CheckBox.js'
import Divider from '../../components/simple/divider/Divider.js'

import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H2 from '../../components/simple/heading/H2.js'
import H3 from '../../components/simple/heading/H3.js'
import Image from '../../components/simple/image/Image.js'
import Select from '../../components/simple/input/Select'
import Tabs from '../../components/simple/tabs/Tabs.js'
import TextInput from '../../components/simple/input/TextInput.js'
import Text from '../../components/simple/text/Text.js'

// Services
import ApiService from '../../services/ApiService'
import NotificationService from '../../services/NotificationService'
import OrganisationSubscriptionService from '../../services/OrganisationSubscriptionService'

// Stores
import { useStore } from 'react-context-hook'

// Stripe
import { loadStripe } from '@stripe/stripe-js'

import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'

import { faAward } from '@fortawesome/pro-regular-svg-icons'

// Theme
// import { beSmartTheme } from '../utils/beSmartTheme'

function UserSignup(props) {
  const history = useHistory()
  const [signupDetails, setSignupDetails] = useState({
    schoolName: '',
    addressLineOne: '',
    addressLineTwo: '',
    postcode: '',
    city: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userLicenses: {}
  })

  const [tandcSelected, setTandC] = useState(false)
  const [loading, setLoading] = useState(false)

  const [subscriptions, setSubscriptions] = useState([])
  const [intent, setIntent] = useState({})
  // const [payConfirm, setPayConfirm] = useState({})

  const stripe = useStripe()
  const elements = useElements()

  const selectLicense = async (license) => {
    const intent = await OrganisationSubscriptionService.intent(license.id)
    if (intent?.error) {
      NotificationService.error(intent.error)
    } else {
      setIntent(intent.data)
    }
  }

  const submitForm = async () => {
    console.log('UserSignup: SubmitForm ', signupDetails)

    const signupValues = {
      user: {
        firstName: signupDetails.firstName,
        email: signupDetails.email,
        lastName: signupDetails.lastName,
        password: signupDetails.password
      },
      intentId: intent.intentId,
      organisation: {
        name: signupDetails.schoolName,
        addressLineOne: signupDetails.addressLineOne,
        addressLineTwo: signupDetails.addressLineTwo,
        postcode: signupDetails.postcode,
        city: signupDetails.city
      }
    }

    const organisationRegistered = await ApiService.registerPublic(signupValues)
    if (organisationRegistered.error) {
      NotificationService.error(organisationRegistered.error)
    } else {
      NotificationService.info('Organisation created')

      props.handleLogin(organisationRegistered.data)

      // redirec to the service signup so that the can setup their account
      history.push('/servicesetup')
    }
  }

  const onTandCChange = () => {
    setTandC(!tandcSelected)
    return null
  }

  const goLogin = () => {
    history.push('/login')
  }

  const goBack = () => {
    history.goBack()
  }

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack()
    } else {
      const prevPageIndex = tabIndex - 1
      onActiveTab(prevPageIndex, true)
    }
  }

  const nextPage = () => {
    if (validateTab()) {
      if (tabIndex === tabContent.length - 1) {
        submitForm()
      } else {
        const nextPageIndex = tabIndex + 1
        onActiveTab(nextPageIndex)
      }
    }
  }

  const validateTab = () => {
    switch (tabIndex) {
      case 0:
        if (signupDetails.schoolName === '' ||
        signupDetails.addressLineOne === '' ||
        signupDetails.postcode === '' ||
        signupDetails.firstName === '' ||
        signupDetails.lastName === '' ||
        signupDetails.email === '' ||
        signupDetails.password === '' ||
        !signupDetails.tandc) {
          NotificationService.error('Please complete all details before continuing')
          return false
        }
        break
      case 1:
        if (Object.keys(signupDetails.userLicenses).length === 0) {
          NotificationService.error('Please select a license before continuing')
          return false
        }
        break
      case 2:
        break
    }

    return true
  }

  const handlePayment = async event => {
    event.preventDefault()

    if (!tandcSelected) {
      NotificationService.error('Please accept the Terms & Conditions')
      return
    }

    if (!signupDetails.schoolName) {
      NotificationService.error('Please enter a School Name')
      return
    }

    if (!signupDetails.email) {
      NotificationService.error('Please enter an email address')
      return
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    })

    if (payload.error) {
      NotificationService.error(payload.error.message)
    } else {
      console.log('[PaymentMethod]', payload)

      const confirmPayment = await stripe.confirmCardPayment(
        intent.clientSecret,
        {
          payment_method: payload.paymentMethod.id
        }
      )
      if (confirmPayment.error) {
        NotificationService.error(confirmPayment.error.message)
      } else {
        console.log('Confirm ', confirmPayment)
        // setPayConfirm(confirmPayment.paymentIntent.id)

        NotificationService.info('Thankyou for your payment')

        const completeIntent = await OrganisationSubscriptionService.completeIntent(confirmPayment.paymentIntent.id)

        console.log('completeIntent ', completeIntent)
        if (completeIntent.error) {
          NotificationService.error(completeIntent.error.message)
        } else {
          submitForm()
        }
      }
    }
  }

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex, skipValidation = false) => {
    // run the validation by default
    // unless the user passes through the skipValidation flag
    var canContinue = true
    if (!skipValidation) {
      canContinue = validateTab()
    }
    if (canContinue) {
      setTabIndex(nextIndex)
    }
  }

  const termsUrl = 'https://www.behavioursmart.co.uk/terms-conditions'
  const privacyUrl = 'https://www.behavioursmart.co.uk/privacy-policy'

  const tabContent = [
    {
      name: 'General Information',
      content: (
        <Box width='large' align='center'>
          <Box width='large'>
            <Divider margin={{ top: 'medium', bottom: 'none' }} />
            <Box>
              <H2 margin={{ bottom: 'small' }}>General Information</H2>
            </Box>

            <Form
              onChange={nextValue => {
                setSignupDetails(nextValue)
              }}
              // onSubmit={({ value: nextValue }) => {
              //   submitForm(nextValue)
              // }}
              value={signupDetails}
            >
              <Box direction='row' gap='large'>
                <Box direction='column' width='medium'>
                  <Text size='large' color='darkGrey' margin={{ bottom: 'small' }}>Service Information</Text>
                  <FormField
                    label='Service Name'
                    name='schoolName'
                    required
                  >
                    <TextInput
                      name='schoolName'
                      type='text'
                    />
                  </FormField>
                  <FormField
                    label='Address Line 1'
                    name='addressLineOne'
                    required
                  >
                    <TextInput
                      name='addressLineOne'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Address Line 2'
                    name='addressLineTwo'
                  >
                    <TextInput
                      name='addressLineTwo'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Postcode'
                    name='postcode'
                    required
                  >
                    <TextInput
                      name='postcode'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='City'
                    name='city'
                  >
                    <TextInput
                      name='city'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Box direction='column' width='medium'>
                  <Text size='large' color='darkGrey' margin={{ bottom: 'small' }}>Your Information</Text>

                  <FormField
                    label='First Name'
                    name='firstName'
                    required
                  >
                    <TextInput
                      name='firstName'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Last Name'
                    name='lastName'
                    required
                  >
                    <TextInput
                      name='lastName'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Email Address'
                    name='email'
                    required
                  >
                    <TextInput
                      name='email'
                      type='email'
                    />
                  </FormField>

                  <FormField
                    label='Password'
                    name='password'
                    required
                  >
                    <TextInput
                      name='password'
                      type='password'
                    />
                  </FormField>

                  <FormField
                    name='tandc'
                    required
                  >
                    <CheckBox label={<Text size='medium'>I accept the <Anchor onClick={() => window.open(termsUrl, '_blank')} size='medium' label='Terms & Conditions' /> and <Anchor onClick={() => window.open(privacyUrl, '_blank')} size='medium' label='Privacy Policy' /></Text>} name='tandc' onChange={onTandCChange} />
                  </FormField>
                </Box>
              </Box>
            </Form>

            {/* <Box direction='row' gap='medium' justify='between' margin={{ top: 'medium' }}>
              <Button label='Back' onClick={() => goLogin()} secondary />
              <Button label='Next' onClick={() => setTabIndex(1)} primary />

            </Box> */}
          </Box>
        </Box>
      )
    },
    {
      name: 'Select Licenses',
      content: (
        <Box width='large' align='center'>
          <Box width='large'>
            <Divider margin={{ top: 'medium', bottom: 'none' }} />

            <Box alignSelf='start'>
              <H2>Select Licenses</H2>
            </Box>

            <Form
              onChange={nextValue => {
                selectLicense(nextValue.userLicenses)
                setSignupDetails(nextValue)
              }}
              // onSubmit={({ value: nextValue }) => {
              //   submitForm(nextValue)
              // }}
              value={signupDetails}
            >
              <Box direction='column' gap='medium'>
                <FormField
                  label='How many users do you require?'
                  name='userLicenses'
                  required
                >
                  <Select
                    id='userLicenses'
                    labelKey='toDisplay'
                    name='userLicenses'
                    placeholder='User Licenses'
                    // eslint-disable-next-line react/jsx-key
                    // options={[<Text>Tier1: Up to <Text weight='bold'>100 Service Users</Text> £144/year</Text>, <Text>Tier2: 100 - <Text weight='bold'>200 Service Users</Text> £250/year</Text>]}
                    options={subscriptions}
                  />
                </FormField>
              </Box>
            </Form>
            {/* <Box direction='row' gap='medium' justify='between' margin={{ top: 'medium' }}>
              <Button label='Back' onClick={() => setTabIndex(0)} secondary />
              <Button label='Save' primary type='submit' />
            </Box> */}
          </Box>
        </Box>
      )
    },
    {
      name: 'Payment',
      content: (
        <Box width='large' align='center'>
          <Box width='large'>
            <Divider margin={{ top: 'medium', bottom: 'none' }} />

            <Box alignSelf='start'>
              <H2>Payment</H2>

              <Text>Selected Licence:</Text>
              <H3 margin={{top: 'none'}} color='secondary'>{signupDetails?.userLicenses?.toDisplay || 'No licence selected'}</H3>


              <Text>Enter your card details to continue.</Text>
            </Box>
            <Box direction='column' gap='medium'>
              <Form
                onSubmit={handlePayment}
              >
                <Box pad={{ horizontal: '14px', vertical: '10px' }} border='small'>
                  <CardElement />
                </Box>

                <Box align='end' margin={{top: 'small'}}>
                  <Button width='medium' primary label='Submit Payment' type='submit' disabled={!stripe || !signupDetails.userLicenses} />
                </Box>
              </Form>
            </Box>

            {/* <Box direction='row' gap='medium' justify='between' margin={{ top: 'medium' }}>
              <Button label='Back' onClick={() => setTabIndex(0)} secondary />
              <Button label='Save' primary type='submit' />
            </Box> */}
          </Box>
        </Box>
      )
    }
  ]

  useEffect(() => {
    let unmounted = false

    const getSubscriptions = async () => {
      const params = {
        fields: 'id,name,description,annualFee,numAllowedUsers',
        limit: 1000
      }

      setLoading(true)
      const subscriptions = await OrganisationSubscriptionService.available(params)
      if (subscriptions?.error) {
        NotificationService.error(subscriptions.error)
      } else {
        if (!unmounted) {

          // set a custom display label for the form
          var processedData = []
          for (const data of subscriptions.data) {
            const humanFee = parseFloat(data.annualFee / 100).toFixed()
            data.toDisplay = `${data.name} - £${humanFee}/year`
            processedData.push(data)
          }

          setSubscriptions(processedData)
          console.log(processedData)
        }
      }
    }

    getSubscriptions()

    setLoading(false)

    return () => { unmounted = true }
  }, [])

  // NOTE !!!
  //
  // Needs ResponsiveContext to layout form differently in mobile format
  return (
    <>
      <Box align='center' justify='center'>
        <Box margin={{ top: 'none', bottom: 'large' }} width='medium' height='xsmall'>
          <Image fit='contain' src='/besmart-logo-white.png' width='small' />
        </Box>
        {/* <Box align='center' alignSelf='center' animation='fadeIn' background='white' round='small' pad='medium' width='xlarge'> */}
        {/* <Form
            onChange={nextValue => {
              setSignupDetails(nextValue)
            }}
            onSubmit={({ value: nextValue }) => {
              submitForm(nextValue)
            }}
            value={signupDetails}
          > */}
        <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
          <Box
            gap='small'
            margin={{ horizontal: 'small' }}
            pad='small'
            round='small'
          >

            <Tabs activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} full />

            <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

            <Box direction='row' justify='around' margin={{ top: 'medium' }}>
              <Button width='medium' label='<&nbsp;Back' onClick={() => previousPage()} secondary />
              {tabIndex !== tabContent.length - 1 &&
                <Button label={tabIndex !== tabContent.length - 1 ? `Next Page` : 'Submit'} onClick={() => nextPage()} primary />}
            </Box>
          </Box>
          {/* </Form> */}
        </Box>
      </Box>
    </>
  )
}

export default UserSignup
